import React, { useState } from "react";
import { Link } from "react-scroll";
//import { linkLogin } from "../helpers/Helpers";

const Navbar = () => {

    const [abrir, setAbrir] = useState(false);

    return (
        <div className="w-full h-auto absolute bg-transparent top-0 left-0 z-40 flex justify-center">
            <div className="md:flex items-center justify-between py-4 w-[85vw] ">
                <div className="font-bold text-2xl cursor-pointer flex items-center  ">
                    <span className="text-3xl text-indigo-700 mr-1 mt-3 pt-2 md:mr-0 md:pt-0">
                        <div className="">
                            <a href="/"><img className="w-[200px] md:w-[295px] h-auto" src="logos_figaruz/logo-header.png" alt="logo_figaruz_header" /></a>
                        </div>
                    </span>
                </div>

                <div onClick={() => setAbrir(!abrir)} className="lg:hidden text-3xl absolute right-8 top-12 cursor-pointer ">
                    <ion-icon name={abrir ? 'close' : 'menu'}></ion-icon>
                </div>
                {/* Mobile */}
                <div className={`w-full block lg:hidden transition-all duration-500 ease-in pb-12 absolute z-[-1] left-0
                        ${abrir ? 'top-0 opacity-100 flex-col items-end pt-28 h-60 font-semibold bg-[#F27F0C]' : 'top-[-490px]'}`}>
                    <ul className="">
                        <li className="h-10 text-white hover:bg-[#F5993D] rounded-xl pr-6 mx-4">
                            <Link to='inicio' spy={true} smooth={true} offset={50} duration={500} className="w-full h-full flex cursor-pointer justify-end text-[24px]">Inicio</Link>
                        </li>
                        <li className="h-10 text-white hover:bg-[#F5993D] rounded-xl pr-6 mx-4">
                            <Link to='registro' spy={true} smooth={true} offset={-40} duration={500} className="w-full h-full flex cursor-pointer justify-end text-[24px]" >Registro</Link>
                        </li>
                        {/* <li className="h-10 text-white hover:bg-[#F5993D] rounded-xl pr-6 mx-4">
                            <div className="w-full h-full flex cursor-pointer justify-end text-[24px]">
                                {linkLogin()}
                            </div>
                        </li> */}
                    </ul>
                </div>
                {/* navbar */}
                <div className="hidden lg:block">
                    <ul className="flex flex-row items-center gap-4">
                        {/* <li className="BHLogin opacity-75 hover:opacity-90 transition duration-300 ease-out hover:ease-in">
                            {linkLogin()}
                        </li> */}
                        <li className="BHRegister hover:bg-[#173A73] transition duration-300 ease-out hover:ease-in">
                            <Link to='registro' spy={true} smooth={true} offset={-40} duration={500}>Registro</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    );
};

export default Navbar