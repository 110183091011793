import React from 'react'

const AboutSection1 = () => {
    return (
        <>
            <div className="flex justify-center -mt-20">
                <div className="grid md:grid-cols-1 lg:grid-cols-5 lg:gap-[33px] items-center w-[90vw]">
                    <img className="max-w-full h-auto col-span-3 pt-8" src="Landing_page/imgs_about/img_section1.png" alt="img_about" />
                    <p className='A-parrafo1 col-span-2 flex items-center mt-10 md:mt-4 text-center lg:text-left'>
                        Somos conscientes del sueldo promedio de la población en Latinoamérica, desafiándonos a mejorar nuestra
                        realidad actual llegamos a una idea la cual consistía en desarrollar una red social en la cual los usuarios
                        pudieran ofrecer y cobrar por sus asesorías relacionadas con sus conocimientos o profesión. Como participantes
                        activos de la transformación digital nos comprometemos con democratización de los estudios, es por esto que
                        ofrecemos un espacio online en donde expertos en sus áreas puedan compartir mediante cursos sus conocimientos
                        obtenidos a través de la experiencia.
                    </p>
                </div>
            </div>

            <div className="flex justify-center">
                <div className="flex justify-center my-20 sm:my-14  lg:my-16 w-[85vw]">
                    <h1 className="A-H1 xl:w-[1041px] xl:h-[70px]">
                        Ya seas un albañil, un contador o estés dedicado al área de salud, brindamos
                        las herramientas para atender online a tus clientes, pacientes o alumnos.
                    </h1>
                </div>
            </div>

            <div className="flex justify-center md:my-16 w-auto h-[300px] md:h-[440px]">
                <iframe
                    className="rounded-2xl shadow-xl h-auto md:h-[440px] w-auto md:w-3/5"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/PRYcWl6BIPA"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen>
                </iframe>
            </div>
        </>
    )
}

export default AboutSection1
