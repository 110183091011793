import React from 'react'
import { Link } from "react-scroll";

const AboutSection2 = () => {
    return (
        <div className="flex flex-col lg:flex-row lg:items-center px-8 mt-10 xl:my-5">
            <div>
                <div className="flex flex-col gap-3 mb-10 md:pr-[20px] xl:pl-10">
                    <h1 className="A-H1-Section2 text-center lg:text-left text-[35px] sm:text-[45px]">Llega a más personas</h1>
                    <h2 className="A-H2-Section2 pt-5 text-center lg:text-left lg:w-10/12 xl:py-7">Somos la plataforma que brinda una vitrina digital a tu negocio,
                        permitiéndote llegar a personas  con conexión a internet en
                        cualquier parte del mundo.
                    </h2>

                    <div className="flex flex-col md:flex-row lg:flex-col gap-10 my-6">
                        <div className="flex items-start gap-[26px]">
                            <img className='iconos_section2' src="Landing_page/iconos/icono_clientes.svg" alt='icono_clientes' />
                            <div className="space-y-[10px]">
                                <h3 className="A-H3-Section2">Conoce tus clientes</h3>
                                <h4 className="A-H4-Section2 ">Informes detallados de la segmentación de tus clientes.</h4>
                            </div>
                        </div>

                        <div className="flex items-start gap-[26px]">
                            <img className='iconos_section2' src="Landing_page/iconos/icono_pionero.svg" alt='icono_pionero' />
                            <div className="space-y-[10px]">
                                <h3 className="A-H3-Section2">Se uno de los primeros</h3>
                                <h4 className="A-H4-Section2">Ingresa a una comunidad que crece día a día. </h4>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center lg:justify-end">
                        <Link to='registro' spy={true} smooth={true} offset={-40} duration={500} className="A-BRegistrarme mr-[10px] cursor-pointer transition duration-300 ease-out hover:ease-in">Registrarme</Link>
                    </div>
                </div>
            </div>
            <img className='max-w-full invisible w-0 h-0 lg:w-5/12 lg:h-full lg:visible xl:w-6/12' src="Landing_page/imgs_about/img_section2.png" alt='img_section2' />
        </div>
    )
}

export default AboutSection2