import { People, PersonAdd, Star } from "react-bootstrap-icons"
// Gear, People, PeopleFill, PersonAdd, PersonFillAdd, Star, StarFill 
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useState } from "react";
import DialogPerfil from "./DialogPerfil";

const CardPerfil = () => {
    const [display, setDisplay] = useState(false);
    const [position, setPosition] = useState('center');

    const dialogFuncMap = {
        'display': setDisplay
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Guardar" icon="pi pi-check" onClick={() => onHide(name)} autoFocus className="p-button-text-save" />
            </div>
        );
    }
    return (
        <div className="flex justify-center">
            <div className="w-[75vw] relative ">
                <div className="w-full mix-blend-multiply bg-black h-[300px] sm:h-[200px] md:h-[335px]">
                    <img className="Rectangle w-full h-full " alt=" " />
                    <div className="flex justify-center ">
                        <div className="absolute -bottom-[495px] sm:-bottom-44 md:-bottom-36 lg:-bottom-28 xl:-bottom-20 left-2 sm:left-60 md:left-72">
                            <div className="flex">
                                <h1 className="sm:text-white text-black text-[24px] mr-6 md:text-[36px] font-medium">Nombre Nombre Apellido Apellido</h1>
                                <Button label="Editar Perfil" icon="pi pi-cog" onClick={() => onClick('display')} style={{ background: "#F27F0C", textColor: "#FFFFFF", borderColor: '#F27F0C' }} className='flex items-center justify-center' />
                                <Dialog header="Editar Perfil" visible={display} onHide={() => onHide('display')} breakpoints={{ '960px': '75vw' }} style={{ width: '1350px', maxHeight: '100%' }} footer={renderFooter('display')}>
                                    <DialogPerfil />
                                </Dialog>
                                {/* <button className="mx-4 flex items-center border rounded-md pr-4 text-white border-[#F27F0C] bg-[#F27F0C]">
                                <Gear className="mx-2"/>
                                Editar perfil
                            </button> */}
                            </div>
                            <h2 className="sm:text-white text-black text-[16px] md:text-[24px] font-medium">Profesión/Oficio/Cargo</h2>
                            <h2 className="mr-4 md:mr-40 pt-6 sm:pt-16 md:pt-12 lg:pt-6 font-semibold">
                                Realizo evaluaciones, diagnósticos y tratamiento en trastorno específico del lenguaje, voz y habla en niños adolescentes y adultos. Atención a niños con trastorno específico del lenguaje,trastorno del espectro autista, apoyo diferencial en lecto-escritura. Atención adultos o jóvenes con: Disfonía, Nódulos vocálicos Pólipos.
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="absolute 
                -bottom-24 sm:-bottom-20 md:-bottom-24 lg:-bottom-30
                left-[70px] sm:left-10 mb-4 sm:mb-0 ">
                    <div className="Avatar h-[180px] w-[180px] md:h-[200px] md:w-[200px] bg-black rounded-full">
                        <div className="flex flex-row justify-between pt-48 md:pt-56">
                            <div>
                                <div className="flex flex-row justify-center">
                                    <People size="24" />
                                    <span className="font-semibold">97</span>
                                </div>
                                <h1 className="text-[14px] font-semibold">Clientes</h1>
                            </div>
                            <div>
                                <div className="flex flex-row justify-center">
                                    <PersonAdd size="24" />
                                    <span className="font-semibold">30</span>
                                </div>
                                <h1 className="text-[14px] font-semibold">Contactos</h1>
                            </div>

                            <div className="">
                                <div className="flex flex-row justify-center font-semibold">
                                    <Star size="24" />

                                    <span className="font-semibold">4.5</span>
                                </div>
                                <h1 className="text-[14px] font-semibold">Rating</h1>
                            </div>
                        </div>
                        {/* Localidad */}
                        <div className="flex flex-row space-x-4 justify-center mt-2">
                            <h1 className="text-[18px] font-medium ">Ciudad,Región,País</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CardPerfil