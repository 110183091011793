import React from 'react'
import { SearchBar } from '../components/Busqueda/SearchBar';
import CardService from '../components/Inicio/CardService';
import Carousel from '../components/Planes/Carousel';

const Inicio = () => {
  return (
    <div className='flex flex-col justify-center w-full items-center'>
      <div className='flex-col justify-center items-center hidden lg:flex mt-4 w-full'>
        <SearchBar />
      </div>
      <div className='mt-10'>
        <CardService />
      </div>
      <div className=' lg:mb-0'>
        <Carousel title="Nuestra Comunidad" />
      </div>
    </div>
  )
}

export default Inicio