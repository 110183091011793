
import CardPerfil from '../components/Perfil/CardPerfil'
import Carousel from '../components/Planes/Carousel'
import Planes from '../components/Planes/Planes'

const Perfil = () => {
    return(
        <div>
            <CardPerfil/>
            <div className=''>
                <Planes/>   
            </div>
            <div className='mb-12 lg:mb-4'> 
              <Carousel title="Top 10"/>
            </div>
        </div>
    )
}
export default Perfil