import React from 'react'

const DataTableCustomers = () => {

    const customerList = [
        { name: 'Rodrigo Rodriguez', description: 'Psicología Infantil', registrationDate: '30/11/2022', attentionDate: '10/09/2022', value: '5.000' },
        { name: 'Jose Rodriguez', description: 'Psicología Infantil', registrationDate: '30/11/2022', attentionDate: '10/09/2022', value: '10.000' },
        { name: 'Ana Rodriguez', description: 'Psicología Infantil', registrationDate: '30/11/2022', attentionDate: '10/09/2022', value: '5.000' },
        { name: 'Nicolas Rodriguez', description: 'Psicología Infantil', registrationDate: '30/11/2022', attentionDate: '10/09/2022', value: '9.000' },
        { name: 'Franco Rodriguez', description: 'Psicología Infantil', registrationDate: '30/11/2022', attentionDate: '10/09/2022', value: '2.000' }
    ]

    return (
        <div>
            <div className="m-4 bg-[#CAC4D0] pb-3 px-2 rounded-lg ">
                <table className="w-full ">
                    <thead className=" ">
                        <tr className="">
                            <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Cliente</th>
                            <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Descripción</th>
                            <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Fecha Ingreso</th>
                            <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Fecha Atención</th>
                            <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Valor</th>
                        </tr>
                    </thead>

                    <tbody className="">
                        {customerList.map((customer, index) => (
                            <tr className="border-y-8 border-[#CAC4D0] bg-white" key={index}>
                                <td className="p-3 text-[16px] font-semibold text-[#0B2152] rounded-l-lg">{customer.name}</td>
                                <td className="p-3 text-[16px] font-semibold text-[#0B2152] ">{customer.description}</td>
                                <td className="p-3 text-[16px] font-semibold text-[#0B2152] ">{customer.registrationDate}</td>
                                <td className="p-3 text-[16px] font-semibold text-[#0B2152] ">{customer.attentionDate}</td>
                                <td className="p-3 text-[16px] font-semibold text-[#0B2152] rounded-r-lg">$ {customer.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default DataTableCustomers