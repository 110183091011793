import React, { useState, useEffect } from 'react'
import { administracion, arquitectura, arte, derecho, educacion, informatica, salud } from './DataCategories';

const Categories = ({ showCategories, setShowCategories }) => {

    //Manejo de opción columna derecha.
    const [option, setOption] = useState('');

    //Evalua si se esconde el menú de categorías para limpiar el estado de option.
    useEffect(() => {
        !showCategories && setOption('')
    }, [showCategories])

    //Evalua la opción y devuelve las subcategorías correspondientes.
    const evaluarOpcion = () => {

        let opcion = option;

        switch (opcion) {
            case 1:
                return administracion.map((e, index) => <div className='hover:text-[#F27F0C] ease-in-out duration-100' key={index} >{e}</div>)
            case 2:
                return arquitectura.map((e, index) => <div className='hover:text-[#F27F0C] ease-in-out duration-100' key={index}>{e}</div>)
            case 3:
                return arte.map((e, index) => <div className='hover:text-[#F27F0C] ease-in-out duration-100' key={index}>{e}</div>)
            case 4:
                return derecho.map((e, index) => <div className='hover:text-[#F27F0C] ease-in-out duration-100' key={index}>{e}</div>)
            case 5:
                return educacion.map((e, index) => <div className='hover:text-[#F27F0C] ease-in-out duration-100' key={index}>{e}</div>)
            case 6:
                return informatica.map((e, index) => <div className='hover:text-[#F27F0C] ease-in-out duration-100' key={index}>{e}</div>)
            case 7:
                return salud.map((e, index) => <div className='hover:text-[#F27F0C] ease-in-out duration-100' key={index}>{e}</div>)
            default:
                break;
        }
    }

    // color = D0E5F8(celeste)
    return (
        <div className='grid grid-cols-2 w-[488px]' onClick={() => setShowCategories(true)}>
            <div className={`w-[244px] text-[16px] text-white leading-[24px] font-medium tracking-[0.15px] flex flex-col bg-[#173A73] cursor-pointer ${option !== '' ? 'rounded-l-[12px]' : 'rounded-[12px]'}`}>
                <div className={`hover:bg-[#F27F0C] hover:text-white h-[54px] pl-4 flex items-center ease-in-out duration-100 ${option === 1 && 'bg-[#F27F0C] text-whiteB2152] rounded-tl-[12px]'}`} onMouseOver={() => setOption(1)} >Administración y Finanzas</div>
                <div className={`hover:bg-[#F27F0C] hover:text-white h-[54px] pl-4 flex items-center ease-in-out duration-100 ${option === 2 && 'bg-[#F27F0C] text-white'}`} onMouseOver={() => setOption(2)}>Arquitectura y Construcción</div>
                <div className={`hover:bg-[#F27F0C] hover:text-white h-[54px] pl-4 flex items-center ease-in-out duration-100 ${option === 3 && 'bg-[#F27F0C] text-white'}`} onMouseOver={() => setOption(3)}>Arte y Diseño</div>
                <div className={`hover:bg-[#F27F0C] hover:text-white h-[54px] pl-4 flex items-center ease-in-out duration-100 ${option === 4 && 'bg-[#F27F0C] text-white'}`} onMouseOver={() => setOption(4)}>Derecho y Leyes</div>
                <div className={`hover:bg-[#F27F0C] hover:text-white h-[54px] pl-4 flex items-center ease-in-out duration-100 ${option === 5 && 'bg-[#F27F0C] text-white'}`} onMouseOver={() => setOption(5)}>Educación</div>
                <div className={`hover:bg-[#F27F0C] hover:text-white h-[54px] pl-4 flex items-center ease-in-out duration-100 ${option === 6 && 'bg-[#F27F0C] text-white'}`} onMouseOver={() => setOption(6)}>Informática y Tecnología</div>
                <div className={`hover:bg-[#F27F0C] hover:text-white h-[54px] pl-4 flex items-center ease-in-out duration-100 ${option === 7 && 'bg-[#F27F0C] text-white rounded-bl-[12px]'}`} onMouseOver={() => setOption(7)}>Salud</div>
            </div>

            <div className={`bg-[#D0E5F8] rounded-r-[12px] text-[#0B2152] px-[20px] py-[10px] ${option === '' ? 'hidden' : 'block'}`}>
                <div className='flex flex-col gap-[13px] font-medium leading-[24px] tracking-[0.15px] cursor-pointer'>{evaluarOpcion()}</div>
            </div>
        </div>
    )
}

export default Categories