import React from 'react'
import PieChart from '../Graficos/PieCharts'
import TheBarChart from '../Graficos/BarChart'


const Graficos = () => {

    const labels = ['Hrs Agendadas', 'Hrs Habilitadas']

    const servicios = [
        { name: 'Hrs Agendadas', value: 16 },
        { name: 'Hrs Habilitadas', value: 45 }
    ]

    const colors = ['#0B2152', '#A3CAF0']

    // data bar chart
    const serviciosAtendidos = [
        {
            name: 'Septiembre',
            servicio1: 10,
            servicio2: 0
        },
        {
            name: 'Octubre',
            servicio1: 15,
            servicio2: 5
        },
        {
            name: 'Noviembre',
            servicio1: 0,
            servicio2: 0
        },
    ];

    const dataBar = [
        { name: 'Servicio 1', key: 'servicio1', color: '#0B2152' },
        { name: 'Servicio 2', key: 'servicio2', color: '#D06208' }
        
    ]


    return (
        
        <div className='flex justify-around w-full '>
            <div className="graficoCircular flex flex-col gap-8 p-2 items-center bg-white w-[20vw]">
                <PieChart
                    title="Servicios Agendados"
                    data={servicios}
                    labels={labels}
                    colors={colors}
                />
            </div>
            <div className="graficoCircular flex flex-col w-[40vw] items-center justify-center bg-white">
                {/* <h5 className='font-[500] text-[22px] leading-[28px] pt-3'>Trimestre</h5> */}
                <div className='p-2 w-full'>
                    <TheBarChart
                        title="Trimestre"
                        data={serviciosAtendidos}
                        dataBar={dataBar}
                    />
                </div>
            </div>
        </div>
    )
}

export default Graficos