//Administración y finanzas
export const administracion = [
    'Administración de empresas',
    'Comercio electrónico',
    'Recursos humanos',
    'Análisis de datos',
    'Contabilidad',
    'Auditoría',
    'Logística'
]
//Arquitectura y construcción
export const arquitectura = [
    'Electricidad y electrónica',
    'Arquitectura digital',
    'Diseño de interiores',
    'Dibujo técnico',
    'Construcción',
]
//Arte y diseño
export const arte = [
    'Diseño de productos',
    'Diseño de mobiliario',
    'Diseño de vestuario',
    'Diseño gráfico',
    'Ilustración',
    'Actuación',
    'Música',
    'Danza',
    'Arte'
]
//Derecho y leyes
export const derecho = [
    'Derecho civil y familia',
    'Derecho internacional',
    'Derecho empresarial',
    'Derecho procesal',
    'Derecho general',
    'Derecho penal',
]
//Educacion
export const educacion = [
    'Lenguaje y comunicación',
    'Matemáticas',
    'Historia',
    'Inglés',
]
//Informática y tecnología
export const informatica = [
    'Programación y análisis',
    'Gestión de sistemas',
    'Conectividad y redes',
    'Ciberseguridad',
]
//Salud
export const salud = [
    'Nutrición y dietética',
    'Terapia ocupacional',
    'Bienestar y cuidado',
    'Medicina general',
    'Fonoaudiología',
    'Kinesiología',
    'Enfermería',
    'Psicología',
    'Psiquiatría'
]