import CardSaldos from "./CardSaldo"
import Movimientos from "./Movimientos"
import SearchBar from "../Busqueda/SearchBar"
import Filter from "../Utiles/Filter"

const Saldo = () => {
    return (
        <div className="flex justify-center">
            <div className="w-[75vw]">
                <div className='mt-8 text-[32px] font-semibold'>
                    Saldos
                </div>
                <div className="flex justify-center">
                    <CardSaldos />
                </div>
                <div className='mt-9 text-[32px] font-semibold'>
                    Ultimos Movimientos
                </div>
                <div className="flex justify-end ">
                    <SearchBar />
                </div>
                <div>
                    <Filter filterList={['Fecha']} />
                </div>
                <div>
                    <Movimientos />
                </div>
            </div>
        </div>
    )
}
export default Saldo