export const dataAyudaLeft = [
    {
        pregunta: '¿Qué es Figaruz?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Cómo puedo buscar un servicio?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Cuánto cuesta un servicio?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Cuánto tiempo dura un servicio?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Puedo reagendar o cancelar un servicio?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Cómo obtengo un reembolso?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Obtendré boletas por cada servicio adquirido?',
        respuesta: 'Respuesta'
    },
]

export const dataAyudaRight = [
    {
        pregunta: '¿Cómo es el pago de la membresía?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Cuántos servicios puedo registrar?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Debo emitir boletas por mis servicios?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Qué sucede si debo cancelar mi cita?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Cómo recibo el pago de mis servicios?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Cuánto tiempo queda retenido el saldo?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Cómo puedo editar mis datos bancarios?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Cómo puedo eliminar mi cuenta?',
        respuesta: 'Respuesta'
    },
    {
        pregunta: '¿Qué tan seguros son el chat y videollamada?',
        respuesta: 'Respuesta'
    },
]
