import "../../styles/loginStyle.css"

export const LoginBG = () => {
    //Este componente es creado aparte ya que a futuro se pretende mejorar este diseño
    return (
        <>
            {/*Pantallas pequeñas hasta sm*/}
            <div className="flex sm:hidden gap-[20px] px-[20px] bg-[#F27F0C]">
                <div className="slider1 w-1/3"></div>
            </div>

            {/*Pantallas medianas hasta lg*/}
            <div className="hidden sm:flex lg:hidden gap-[20px] px-[20px] bg-[#F27F0C]">
                <div className="slider1 w-1/3"></div>
                <div className="slider2 w-1/3"></div>
            </div>

            {/*Pantallas grandes desde lg*/}
            <div className="hidden lg:flex gap-[20px] px-[20px] bg-[#F27F0C]">
                <div className="slider1 w-1/3"></div>
                <div className="slider2 w-1/3"></div>
                <div className="slider3 w-1/3"></div>
            </div>
        </>
    )
}

