import React, { useState } from "react";

import { useFormik } from 'formik';
import jwt_decode from "jwt-decode";

//Validaciones
const validate = (values) => {
    const errors = {}

    if (!values.subject) {
        errors.subject = 'El asunto es requerido'
    }
    if (!values.message) {
        errors.message = 'El mensaje es requerido'
    }
    return errors
}

const FormAyuda = () => {

    const [file, setFile] = useState([]); //Guarda el archivo opcional.

    let token = localStorage.getItem('token')
    const decoded = jwt_decode(token); //Decodificación de token.

    const formContact = useFormik({
        initialValues: {
            username: '',
            subject: '',
            email: '',
            message: '',
            file: '',
        },
        validate,
        onSubmit: (values) => {
            // setSend(true);

            // values.username = decodeToken().first_name + ' ' + decodeToken().second_name + ' ' + decodeToken().surname + ' ' + decodeToken().second_surname;
            // const formDataContact = new FormData();

            // formDataContact.append('name', values.username);
            // formDataContact.append('subject', values.subject.label);
            // formDataContact.append('email', decodeToken().email);
            // formDataContact.append('message', values.message);
            // formDataContact.append('optional_file', file[0]);

            // //console.log(values.file);

            // fetch("http://192.168.100.2:7000/figaruz/contact", {
            //     method: "POST",
            //     body: formDataContact
            // }).then(x => {
            //     async function mensaje(x) {
            //         try {
            //             const result = await x.json();
            //             if (x.ok && x.status === 200) {
            //                 mostrarAlertaSuccess(result);
            //                 formContact.resetForm();
            //                 setFile([]);
            //                 setSend(false);
            //             } else {
            //                 mostrarAlertaError(result);
            //             };
            //         } catch (error) {
            //             console.log(error);
            //             setSend(false);
            //         }
            //     }
            //     mensaje(x);
            //     setSend(false);
            // })
        }
    })

    return (
        <div className="w-[360px]">
            <h2 className="text-[#173A73] mb-[30px]">
                Si necesitas contactarnos para hacer alguna consulta o simplemente
                dejar un comentario, envianos un mensaje directo.
            </h2>

            <form className="text-[#173A73] flex flex-col gap-3">

                <div className='flex flex-col'>
                    <label className="font-medium">Asunto</label>
                    <select className="rounded-[6px] placeholder-[#173A73] placeholder-opacity-40 focus:ring-[#173A73] focus:ring-1 focus:border-[#173A73]">
                        <option>Pago y suscripción</option>
                        <option>Problemas técnicos</option>
                        <option>Validaciones de profesión</option>
                        <option>Reclamos</option>
                        <option>Otros</option>
                    </select>
                </div>

                <div className='flex flex-col'>
                    <label className="font-medium">Mensaje</label>
                    <textarea
                        className="rounded-[6px] placeholder-[#173A73] placeholder-opacity-40 focus:ring-[#173A73] focus:ring-1 focus:border-[#173A73]"
                        id='textArea'
                        placeholder="500 caracteres como máximo"
                        maxLength={500}
                        rows={4}
                    />
                </div>

                <div>
                    <div className="flex flex-col items-start w-full gap-1">

                        <label className="underline text-[#173A73] font-medium underline-offset-2 text-sm cursor-pointer">
                            Cargar archivo (opcional)
                            <input type='file' onChange={e => setFile(e.target.files)} accept="image/png, image/jpeg" />
                        </label>

                        <h2 className="text-xs mb-4">{file.length >= 1 ? file[0].name : null}</h2>
                    </div>
                </div>

                <button className="w-full bg-[#F27F0C] px-[76px] py-[12px] rounded-[15px] text-white font-medium text-[30px] leading-[35px]">Enviar solicitud</button>
            </form>
        </div>
    )
}

export default FormAyuda