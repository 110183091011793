import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Navigate, luxonLocalizer } from 'react-big-calendar'
import { format } from 'date-fns'
import { DateTime } from "luxon";
import { ChevronLeft, ChevronRight, PlusCircle } from 'react-bootstrap-icons';

//Localizer a través de luxon en idioma español.
const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 1 });

//Cambio de idioma de botones en header de agenda.
const messages = {
    next: "Siguiente",
    previous: "Anterior",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día"
};

function ViewNamesGroup({ views: viewNames, view, messages, onView }) {
    return viewNames.map((name) => (
        <button
            type="button"
            key={name}
            className={clsx({ 'rbc-active': view === name })}
            onClick={() => onView(name)}
        >
            {messages[name]}
        </button>
    ))
}
ViewNamesGroup.propTypes = {
    messages: messages,
    onView: PropTypes.func,
    view: PropTypes.string,
    views: PropTypes.array,
}

export default function CustomToolbar({
    // date, // available, but not used here
    label,
    localizer: { messages },
    onNavigate,
    onView,
    view,
    views,
}) {


    return (
        <div className="grid grid-cols-3 w-full items-center justify-items-center py-2">
            <div className='place-self-start flex gap-4 justify-center items-center'>
                <div className='labelsToolbar cursor-pointer'>Semana</div>
                <div className='labelsToolbar text-center py-[10px] px-[5px] rounded-[17px] w-[80px] cursor-pointer bg-white'>Mes</div>
            </div>
            <div className='flex items-center'>
                <button
                    className='text-[#0B2152]'
                    type="button"
                    onClick={() => onNavigate(Navigate.PREVIOUS)}
                    aria-label={messages.previous}
                >
                    <ChevronLeft size={20} />
                </button>

                <span className="rbc-toolbar-label">Diciembre</span>

                <button
                    className='text-[#0B2152]'
                    type="button"
                    onClick={() => onNavigate(Navigate.NEXT)}
                    aria-label={messages.next}
                >
                    <ChevronRight size={20} />
                </button>
            </div>
            <div className='place-self-end flex items-center justify-center gap-[10px] py-[10px] px-[5px] rounded-[17px] w-[80px] cursor-pointer bg-white'>
                <h5 className='labelsToolbar'>add</h5>
                <PlusCircle size={21} />
            </div>

        </div>
    )
}
