import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// useNavigate
import { AutoComplete } from 'primereact/autocomplete';
import Avatar from 'react-avatar';
import { ArrowRepeat, Search } from 'react-bootstrap-icons';

//Elimina espacios en blanco y tíldes del String pero conserva la letra ñ.
// const customNormalize = (e) => {
//     return e.replace(" ", "").normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
// }

export const SearchBar = () => {

    // const { users } = UseSearch();
    // const navigate = useNavigate();

    const [selectedUser, setSelectedUser] = useState(null); 
    //const [filteredUsers, setFilteredUsers] = useState(null);

    const [optionButton, setOptionButton] = useState(true);
    const [option, setOption] = useState(0)

    const evaluateOption = () => {
        if (optionButton) {
            setOption(1)
        } else {
            setOption(4)
        }
    }

    //Crea un array con los usuarios filtrados.
    // const usersFilter = (e) => {

    //     const searchWord = e.query;

    //     const filter = users.filter(e => {
    //         const fullName = e.first_name.toLowerCase() + e.surname.toLowerCase();

    //         return e.first_name.toLowerCase().startsWith(searchWord.toLowerCase()) ||
    //             e.surname.toLowerCase().startsWith(searchWord.toLowerCase()) ||
    //             customNormalize(fullName).includes(customNormalize(searchWord.toLowerCase()))
    //     });
    //     setFilteredUsers(filter)
    // }

    //Template de lista de usuarios.
    const itemTemplate = (e) => { 
        return (
            <div className='flex gap-2 items-center'>
                <div>{<Avatar size="35" facebook-id="invalidfacebookusername" src={"data:image/*;base64," + e.img} round={true} />}</div>
                <div>{e.first_name}</div>
                <div>{e.surname}</div>
            </div>
        )
    }

    //Template de vista en barra de búsqueda.
    // const selectedIteTemplate = () => {
    //     return selectedUser.first_name + " " + selectedUser.surname;
    // }

    //Redirige hacia el perfil de profesional seleccionado.
    // const showProfile = () => {
    //     const sel = selectedUser.id;
    //     cookies.set('userIdProfile', sel, { path: '/' });
    //     navigate('/mostrarPerfil');
    //     window.location.reload();
    // }

    return (
        <div className='flex items-center justify-center w-auto sm:w-[480px] lg:w-4/12'>
            <ArrowRepeat size='32px'
                className=' text-[#173A73] mr-1  cursor-pointer ease-in-out duration-100'
                onClick={() => setOptionButton(!optionButton)}
            />
            <div className="p-inputgroup">
                <AutoComplete
                    forceSelection
                    value={selectedUser}
                    onChange={(e) => { setSelectedUser(e.value); evaluateOption() }}
                    itemTemplate={itemTemplate}
                    //selectedItemTemplate={selectedIteTemplate}
                    //suggestions={filteredUsers}
                    //completeMethod={usersFilter}
                    placeholder={optionButton ? 'Buscar Profesión' : 'Buscar Profesional'}
                />
                <Link
                    to={`/search/${option}`}
                    className='flex items-center justify-center w-[50px] bg-[#173A73] text-white rounded-r-lg hover:bg-[#173A73] ease-in-out  duration-200'
                    onClick={() => evaluateOption()}
                >
                    <Search size='24px' />
                </Link>
            </div>
        </div>

    )
}
export default SearchBar