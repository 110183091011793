import React, { useEffect } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import Categories from '../Categorias/Categories';
import "../../styles/sidebar.css"
import {
  BoxArrowRight,
  CalendarWeek,
  CardChecklist,
  Coin,
  House,
  List,
  PeopleFill,
  PersonCircle,
  PersonVcard,
  QuestionCircle
} from 'react-bootstrap-icons';


const Sidebar = ({ openSidebarLeft, setOpenSidebarLeft, openSidebarRight, setComponente, showCategories, setShowCategories }) => {

  const navigate = useNavigate();

  const cerrarSesion = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('session');

    navigate("/iniciar-sesion");
  }

  //Escucha los cambios del otro sidebar
  useEffect(() => {
    openSidebarRight ? setOpenSidebarLeft(false) : setOpenSidebarLeft(true)

    //Si el sidebar se cierra, cierra también las categorías
    !openSidebarLeft && setShowCategories(false)
  }, [openSidebarRight])

  const sidebar = [
    {
      path: "/inicio",
      name: "inicio",
      icon: <House size={32} />,
      value: "false"
    },
    {
      path: "/admEmpresa",
      name: "Categorias",
      icon: <CardChecklist size={32} />,
      value: "!showCategories"
    },
    {
      path: "/agenda",
      name: "Agenda",
      icon: <CalendarWeek size={32} />,
      value: "false"
    },
    {
      path: "/servicios",
      name: "Servicios",
      icon: <PersonVcard size={32} />,
      value: "false"
    },
    {
      path: "/clientes",
      name: "Clientes",
      icon: <PeopleFill size={32} />,
      value: "false"
    },
    {
      path: "/saldos",
      name: "Saldos",
      icon: <Coin size={32} />,
      value: "false"
    },
    {
      path: "/perfil",
      name: "Perfil",
      icon: <PersonCircle size={32} />,
      value: "false"
    }
  ]

  const ayuda = [
    {
      path: "/ayuda",
      name: "Ayuda",
      icon: <QuestionCircle size={32} />
    }
  ]

  return (
    <div className="lg:flex h-screen shadow-md bg-[#173A73]">

      <div className={` ${openSidebarLeft ? "h-26 lg:w-56" : "h-20  lg:w-24"} lg:h-full w-screen lg:flex lg:relative duration-300`}>

        <svg xmlns="http://www.w3.org/2000/svg" className={`lg:absolute lg:cursor-pointer h-1 sm:h-1 sm:w-1 md:h-1 md:w-1 hidden lg:flex lg:h-8 lg:w-8 lg:-right-4 lg:top-20 w-7 lg:border-dark-purple 
          lg:border-2 lg:rounded-full z-50 lg:bg-white ${!openSidebarLeft && "rotate-180"}`}
          onClick={() => { setOpenSidebarLeft(!openSidebarLeft); setShowCategories(false) }} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z" />
        </svg>
        <List size='30px' className={`absolute text-[#FFFFFF] duration-300 z-auto top-5 right-2 cursor-pointer lg:hidden flex  ${openSidebarLeft && "rotate-180 duration-300"}`}
          onClick={() => { setOpenSidebarLeft(!openSidebarLeft); setShowCategories(false); setComponente('') }} />

        <div className="flex items-center ml-6 absolute mt-6 lg:gap-x-4 lg:items-center lg:ml-6">
          <img
            src="/logos_figaruz/Fi.png" alt="logo_figaruz_header"
            className={`cursor-pointer hidden lg:block duration-300 w-12 z-0  ${openSidebarLeft && "hidden lg:hidden duration-300"}`} />
          <img
            src="/logos_figaruz/logo-header.png" alt="logo_figaruz_header"
            className={`text-black origin-left w-40 mt-4 lg:mt-2 ml-4 lg:ml-1  duration-300  ${!openSidebarLeft && "scale-4 lg:scale-0 duration-300"}`} />
        </div>

        <div className="flex flex-col w-full h-screen justify-between">
          <ul className={`${!openSidebarLeft && "hidden lg:block"} w-full mr-4 lg:mr-0 items-center pt-20 duration-100 lg:mt-10 lg:pt-15`}>

            {sidebar.map((item, index) => (
              item.name === "Categorias" ?
                <NavLink className={`${showCategories ? 'desactive link' : 'hover linkTwo'}`} activeclassName="active"
                  key={index} onClick={() => setShowCategories(!showCategories)} onBlur={() => setShowCategories(false)} >

                  <div className={`${openSidebarLeft ? "ml-8" : "flex justify-center"} flex flex-row justify-start space-x-4 items-center py-4`}>
                    <div>{item.icon}</div>
                    <span className={`${!openSidebarLeft && "hidden"} text-[16px] font-medium duration-100`}>{item.name}</span>
                  </div>
                </NavLink>
                :
                <NavLink className=" link" activeclassName="active" key={index} to={item.path} onClick={() => { setShowCategories(false); setComponente(item.name) }}>

                  <div className={`${openSidebarLeft ? "ml-8" : "flex justify-center"} flex flex-row justify-start space-x-4 items-center py-4`}>
                    <div>{item.icon}</div>
                    <span className={`${!openSidebarLeft && "hidden"} text-[16px] font-medium duration-100`}>{item.name}</span>
                  </div>
                </NavLink>
            ))}

            <div className={`absolute -mt-96 z-50 hover:text-white ${openSidebarLeft ? 'ml-[230px]' : 'ml-[110px] '} ${showCategories ? 'block' : 'hidden'}`}>
              <Categories
                showCategories={showCategories}
                setShowCategories={setShowCategories}
              />
            </div>
          </ul>
          <ul className={`${!openSidebarLeft && "hidden lg:block"} w-full mr-4 lg:mr-0 items-center  duration-100 lg:mt-4 mb-5`}>
            {ayuda.map((item, index) => (

              <NavLink className=" link" activeclassName="active" key={index} to={item.path} onClick={() => { setShowCategories(false); setComponente('') }}>

                <div className={`${openSidebarLeft ? "ml-8" : "flex justify-center"} flex flex-row justify-start space-x-4 items-center py-4`}>
                  <div >{item.icon}</div>
                  <span className={`${!openSidebarLeft && "hidden"} text-[16px] font-medium duration-100`}>{item.name}</span>
                </div>
              </NavLink >
            ))}
            <li className="link" onClick={() => { setShowCategories(false); setComponente('inicio') }}>
              <div onClick={() => cerrarSesion()} className="cursor-pointer">
                <div className={`${openSidebarLeft ? "ml-8" : "flex justify-center"} flex flex-row justify-start space-x-4 items-center py-4`}>
                  <BoxArrowRight size={32} />
                  <span className={`${!openSidebarLeft && "hidden"}  text-[16px] font-medium duration-100`}>Cerrar Sesión</span>
                </div>
              </div>
            </li>
          </ul >
        </div >
      </div >
    </div >
  );
};

export default Sidebar