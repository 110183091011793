import React from 'react'
import { PieChart, ResponsiveContainer, Pie, Tooltip, Cell } from 'recharts'

const SimplePieCharts = ({ title, data, labels, colors}) => {
    return (
        <div>
            <h1 className="text-center text-[22px] font-semibold">{title}</h1>
            <div>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <PieChart>
                            <Pie
                                label={labels}
                                dataKey="value"
                                data={data}
                                innerRadius={50}
                                outerRadius={95}
                                fill="#82ca9d"
                            >
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>

                    </ResponsiveContainer>
                </div>
            </div>
        </div>

    )
}

export default SimplePieCharts