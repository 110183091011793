import React, { Fragment, useState, useMemo, useEffect } from 'react';
// import { retornar_minutos, retornar_hora, tiempo_a_minutos, minutos_a_tiempo } from '../../helpers/Helpers';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../styles/reactBigCalendar.css"
// import TimeGrid from 'react-big-calendar/lib/TimeGrid'
// import PropTypes from 'prop-types'
// import * as dates from './dates';

import { Calendar, Views, luxonLocalizer } from 'react-big-calendar';
// Navigate
import { getDay, formatISO9075, getDate, isSameMonth } from 'date-fns';
//  addDays, startOfWeek,
import { DateTime } from "luxon";
import { set, format } from 'date-fns';
import CustomToolbar from './CustomViews';

//Localizer a través de luxon en idioma español.
const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 1 });

//Cambio de idioma de botones en header de agenda.
const messages = {
  next: "Siguiente",
  previous: "Anterior",
  today: "Hoy",
  month: "Mes",
  week: "Semana",
  day: "Día"
};

const Diary = ({ appointments }) => {

  // const [arrayMonth, setArrayMonth] = useState([]); //Array vista mensual.
  const [arrayWeek, setArrayWeek] = useState([]); //Array vista semanal y diaria.
  const [isOutMonth, setIsOutMonth] = useState(false); //Evalua si la vista excede un mes desde la fecha actual

  let rep = { representation: 'time' }; //Setting de formatISO para comparar horas.
  const actualDate = DateTime.now(); //Se ocupa como referencia al día actual.

  const [arrEventos, setArrEventos] = useState([]);
  // const [arrEvSet, setArrEvSet] = useState([]);

  const eventosMaqueta = [
    {
      id: 0,
      title: 'X servicios',
      start: new Date(2022, 11, 3, 13, 0, 0),
      end: new Date(2022, 11, 3, 15, 0, 0),
    },
    {
      id: 1,
      title: 'X servicios',
      start: set(new Date(), { hours: 9, minutes: 0 }),
      end: set(new Date(), { hours: 16, minutes: 0 }),
    },
    {
      id: 2,
      title: 'X servicios',
      start: new Date(2022, 11, 15, 13, 0, 0),
      end: new Date(2022, 11, 15, 15, 0, 0),
    },
  ]

  useEffect(() => {
    setArrEventos(appointments);
  }, [])

  //   useEffect(() => {
  //     weekRange();
  //     monthRange();
  //   }, [data])

  //Configuración de selects en header de vista agenda.
  // const { views } = useMemo(
  //   () => ({
  //     views: {
  //       week: true,
  //       month: true,
  //     },
  //   }),
  //   [])

  const { views, components } = useMemo(
    () => ({
      components: {
        toolbar: CustomToolbar,
        month: {
          dateHeader: ({ date, label }) => {
            let highlightDate = eventos.find(
              event =>
                format(date, 'dd-MM-yyyy') === format(event.start, 'dd-MM-yyy'),
            ) != undefined;
            return (
              <h1 style={highlightDate ? { color: "white" } : null}>{label}</h1>
            )
          }
        }
      }
    }),
    []
  )

  /* Crea un array que contiene los rangos de tiempo desde y hasta 
  que hora el profesional otorga servicios en una semana completa.
  Sirve como referencia para pintar vista semanal de agenda. */
  // const weekRange = () => {

  //   const actualDate = Date.now() //Obtengo la fecha actual.
  //   const firstDayWeek = startOfWeek(actualDate, { weekStartsOn: 1 }); // Busca el primer día de la semana en base a la fecha actual.
  //   const firstDayISO = firstDayWeek.toISOString().slice(0, 11); // Transforma a Date. Se ocupa como referencia.
  //   const arrayDaysWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']; // Array de días de la semana.
  //   const arrayWeek = [];
  //   const setToZero = DateTime.now().toISO().slice(24) + ':00'; //Para setear los días inactivos a 00:00:00, debe ser de otra forma.

  //   data.map(e => {
  //     if (e.is_active === 0) {
  //       if (e.day_of_week !== 0) {
  //         let subDay = e.day_of_week - 1;
  //         const range = {
  //           id: e.id,
  //           title: arrayDaysWeek[subDay],
  //           start: addDays(new Date(firstDayISO + setToZero + 'Z'), subDay),
  //           end: addDays(new Date(firstDayISO + setToZero + 'Z'), subDay),
  //           weekDay: e.day_of_week,
  //         }
  //         arrayWeek.push(range);
  //       } else {
  //         let subDay = 6;
  //         const range = {
  //           id: e.id,
  //           title: arrayDaysWeek[6],
  //           start: addDays(new Date(firstDayISO + setToZero + 'Z'), subDay),
  //           end: addDays(new Date(firstDayISO + setToZero + 'Z'), subDay),
  //           weekDay: e.day_of_week,
  //         }
  //         arrayWeek.push(range);
  //       }
  //     }
  //     else {
  //       let subDay = e.day_of_week - 1;
  //       const range = {
  //         id: e.id,
  //         title: arrayDaysWeek[subDay],
  //         start: addDays(new Date(firstDayISO + e.from_hour + 'Z'), subDay),
  //         end: addDays(new Date(firstDayISO + e.until_hour + 'Z'), subDay),
  //         weekDay: e.day_of_week,
  //       }
  //       arrayWeek.push(range);
  //     }
  //   })
  //   setArrayWeek(arrayWeek);
  // }

  /*A partir de la fecha actual genera un array con todos los rangos disponibles
  dentro de un mes en los que el profesional presta servicios.
  Se ocupará para mostrar las citas agendadas a futuro.*/
  // const monthRange = () => {

  //   const arrayMonth = [];

  //   for (let i = 0; i < 31; i++) {

  //     const dt = DateTime.now().plus({ days: i }); // Agrega días según índice
  //     const day_number = dt.toJSDate().getDay();
  //     const offset = dt.offset;

  //     for (let y = 0; y < data.length; y++) {
  //       if (day_number === data[y].day_of_week && data[y].is_active === 1) {

  //         const from_hour = (tiempo_a_minutos(data[y].from_hour) + offset);
  //         const until_hour = (tiempo_a_minutos(data[y].until_hour) + offset);

  //         const content = {
  //           id: Math.random(),
  //           title: "Horas tomadas",
  //           start: new Date(dt.year, dt.month - 1, dt.day, retornar_hora(minutos_a_tiempo(from_hour)), retornar_minutos(minutos_a_tiempo(from_hour)), 0),
  //           end: new Date(dt.year, dt.month - 1, dt.day, retornar_hora(minutos_a_tiempo(until_hour)), retornar_minutos(minutos_a_tiempo(until_hour)), 0),
  //         };
  //         arrayMonth.push(content);
  //       }
  //     }
  //   }
  //   setArrayMonth(arrayMonth)
  // };

  //console.log(new Date())

  //Pintado de vista mensual Agenda.
  const datePropGetter = (date) => {
    for (let i = 0; i < eventosMaqueta.length; i++) {
      if (getDate(date) === getDate(eventosMaqueta[i].start) && isSameMonth(date, eventosMaqueta[i].start)) {
        return { style: { backgroundColor: '#0B2152' } }
      }
    }
  }

  //Pintado de vista semanal en agenda.
  const customDayPropGetter = (date) => {
    for (let i = 0; i < arrayWeek.length; i++) {
      if (date >= actualDate.toJSDate() || getDate(date) === getDate(actualDate.toJSDate())) {
        if (getDay(date) === i) {
          if (getDay(date) === 0) {
            const day = 6
            if (formatISO9075(date, rep) < formatISO9075(arrayWeek[day].start, rep)) {
              return { style: { backgroundColor: '#CECACA', } }
            }
            if (formatISO9075(date, rep) >= formatISO9075(arrayWeek[day].start, rep) && formatISO9075(date, rep) < formatISO9075(arrayWeek[day].end, rep)) {
              return { style: { backgroundColor: '#D5F5E3', } }
            }
            if (formatISO9075(date, rep) >= formatISO9075(arrayWeek[day].end, rep)) {
              return { style: { backgroundColor: '#CECACA', } }
            }
          } else {
            const day = i - 1
            if (formatISO9075(date, rep) < formatISO9075(arrayWeek[day].start, rep)) {
              return { style: { backgroundColor: '#CECACA', } }
            }
            if (formatISO9075(date, rep) >= formatISO9075(arrayWeek[day].start, rep) && formatISO9075(date, rep) < formatISO9075(arrayWeek[day].end, rep)) {
              return { style: { backgroundColor: '#D5F5E3', } }
            }
            if (formatISO9075(date, rep) >= formatISO9075(arrayWeek[day].end, rep)) {
              return { style: { backgroundColor: '#CECACA', } }
            }
          }
        }
      } else if (date < actualDate.toJSDate()) {
        return { style: { backgroundColor: '#CECACA', } }
      }
    }
  }

  //Pinta todos los días fuera de los rangos establecidos en vista semanal.
  const emptyDayPropGetter = () => {
    return { style: { backgroundColor: '#CECACA', } }
  }

  /*Evalua la fecha en la que esta situado el usuario desde la agenda.
  Si la fecha que ve corresponde a más de un mes desde la fecha actual
  lo pinta como no disponible, si no, respeta la configuración previa.*/
  const onNavigate = (date) => {
    const calendarDate = date;
    const maxDate = DateTime.now().plus({ days: 31 })

    if (calendarDate > maxDate.toJSDate()) {
      setIsOutMonth(true)
    } else {
      setIsOutMonth(false)
    }
  }

  //Asigna las citas tomadas para mostrarlas en la agenda
  const eventos = eventosMaqueta

  return (
    <Fragment>
      <div className='w-[75vw] flex justify-center'>
        <div className='h-[70vh] w-[140vh] my-24 bg-[#CAC4D0] p-5 rounded-lg'>
          <Calendar
            culture='es'
            localizer={localizer}
            messages={messages}
            defaultView={Views.MONTH}
            defaultDate={new Date()}
            slotPropGetter={customDayPropGetter}
            dayPropGetter={datePropGetter}
            views={views}
            events={eventos}
            components={components}
            startAccessor="start"
            endAccessor="end"
            timeslots={1}
            step={15}
            onNavigate={onNavigate}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default Diary