import React from "react";
import '../../../styles/planesStyle.css'
import PieChart from '../../Graficos/PieCharts'
import TheBarChart from '../../Graficos/BarChart'
import '../../../styles/planesStyle.css'
import StarRating from "./starRating";



const SummaryServices = () => {
    // data pie chart
    const labels = ['Sevicios', 'Servicios por atender']

    const servicios = [
        { name: 'Servicios por Atender', value: 16 },
        { name: 'Servicios', value: 45 }
    ]

    const colors = ['#0B2152', '#A3CAF0']

    // data bar chart
    const serviciosAtendidos = [
        {
            name: 'Septiembre',
            servicio1: 30,
            servicio2: 0,
            servicio3: 0,
        },
        {
            name: 'Octubre',
            servicio1: 15,
            servicio2: 15,
            servicio3: 20,
        },
        {
            name: 'Noviembre',
            servicio1: 0,
            servicio2: 0,
            servicio3: 0,
        },
    ];

    const dataBar = [
        { name: 'Servicio 1', key: 'servicio1', color: '#A3CAF0' },
        { name: 'Servicio 2', key: 'servicio2', color: '#0B2152' },
        { name: 'Servicio 3', key: 'servicio3', color: '#D06208' }
    ]



    // estrellas
    

    const valoration = 4.8;

    return (
        <div className="flex justify-center" >
            <div className="w-[75vw]">
                <div className="pt-5">
                    <h1 className="mb-5 text-[32px] font-semibold">Resumen Servicios</h1>

                    {/* Grafico */}
                    <div className="grid grid-cols-8 gap-4">
                        <div className="h-auto rounded-lg shadow-xl col-span-2 bg-white">
                            <div className="flex justify-center items-center w-full h-full ">
                                <PieChart
                                    title="Servicios Agendados"
                                    data={servicios}
                                    labels={labels}
                                    colors={colors}
                                />
                            </div>
                        </div>
                        <div className="h-auto rounded-lg shadow-xl bg-white col-span-4">
                            <div className="">
                                <div className="mt-7">
                                    <TheBarChart
                                        title="Historial de Servicios"
                                        data={serviciosAtendidos}
                                        dataBar={dataBar}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="h-full  rounded-lg shadow-xl bg-white col-span-2">
                            <div className="flex justify-center px-5 py-7">
                                <div className="Avatar h-auto w-full md:h-[219px] md:w-[219px] bg-black rounded-full">

                                </div>
                            </div>
                            <div className="flex justify-center py-6">
                                <div className="flex justify-center flex-col text-center">
                                    
                                    {/* opcion 3 */}
                                    
                                    <div className="flex space-x-2 items-center justify-center">
                                        <h1 className="text-[32px] font-semibold">02</h1>
                                        <h2 className="text-[16px] font-medium">Servicios registrados</h2>
                                    </div>
                                    <div className="flex flex-col justify-center">
                                        <div className="flex flex-row items-center justify-around">
                                            <h1 className="text-[32px] font-semibold">{valoration}</h1>
                                            <StarRating stars={valoration}  />
                                        </div>
                                        <div>
                                            <h2 className="text-[16px] font-medium">Valoración promedio</h2>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SummaryServices