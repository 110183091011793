import React, {useState} from 'react'
import CardCustomers from './CardCustomers'
import Filter from '../../Utiles/Filter'
import Pagination from '../../Utiles/Pagination'
import DataTableCustomers from './DataTableCustomers'

const Customers = () => {
    const [card, setCard] = useState(false);

    return (
        <div className="flex justify-center">
            <div className="w-[75vw]">
                <Filter setView={(value) => setCard(value)} filterList={['Fecha','Cantidad de citas']} activeView={true}/>
                {card ? <DataTableCustomers /> : < CardCustomers />}
                <Pagination />
            </div>
        </div>
    )
}

export default Customers