import React, { useState } from 'react'
import "../../styles/ayudaStyle.css"
import { dataAyudaLeft, dataAyudaRight } from './DataAyuda'

import { CaretRight } from 'react-bootstrap-icons'

const AcordeonAyuda = () => {

    const [selectedLeft, setSelectedLeft] = useState(null);
    const [selectedRight, setSelectedRight] = useState(null);

    //Controla la aprtura o cierre de las preguntas.
    //TODO: controlar ambas columnas en una sola función.
    const toggleLeft = (i) => {
        if (selectedLeft === i) {
            return setSelectedLeft(null)
        }
        setSelectedRight(null)
        setSelectedLeft(i)
    }

    const toggleRight = (i) => {
        if (selectedRight === i) {
            return setSelectedRight(null)
        }
        setSelectedLeft(null)
        setSelectedRight(i)
    }

    return (
        <div className='wrapper'>
            <div className='acordeon'>
                {dataAyudaLeft.map((item, index) => (
                    <div className='items' key={index}>
                        <div className='titulo' onClick={() => toggleLeft(index)}>
                            <h2>{item.pregunta}</h2>
                            <span><CaretRight className={selectedLeft === index ? 'rotate-90 transition-all' : 'rotate-0 transition-all'} /></span>
                        </div>
                        <div className={selectedLeft === index ? 'contenido show' : 'contenido'}>{item.respuesta}</div>
                    </div>
                ))}
            </div>
            <div className='acordeon'>
                {dataAyudaRight.map((item, index) => (
                    <div className='items' key={index}>
                        <div className='titulo' onClick={() => toggleRight(index)}>
                            <h2>{item.pregunta}</h2>
                            <span><CaretRight className={selectedRight === index ? 'rotate-90 transition-all' : 'rotate-0 transition-all'} /></span>
                        </div>
                        <div className={selectedRight === index ? 'contenido show' : 'contenido'}>{item.respuesta}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AcordeonAyuda