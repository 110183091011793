import React, { useEffect } from 'react'

import { List, PeopleFill, PersonFillAdd, StarFill } from 'react-bootstrap-icons'
import { format, addDays } from 'date-fns'
import { es } from 'date-fns/locale';
// CalendarWeek

const SidebarPerfil = ({ openSidebarRight, setOpenSidebarRight, openSidebarLeft }) => {

  //Formatea la fecha a español
  let esp = { locale: es };
  const actualDate = new Date();

  //Escucha los cambios del otro sidebar
  useEffect(() => {
    openSidebarLeft ? setOpenSidebarRight(false) : setOpenSidebarRight(true)
  }, [openSidebarLeft])

  return (
    <div className="lg:flex h-screen shadowSidebarTwo">

      <div className={` ${openSidebarRight ? "w-[320px]" : "w-24"} lg:h-screen lg:flex lg:flex-col lg:pt-8 duration-300`}>

        {/* Icono abrir cerrar */}
        <List
          size='30px'
          className={`lg:absolute lg:cursor-pointer duration-300 text-[#F27F0C] md:h-1 md:w-1 hidden lg:flex lg:h-8 lg:w-8 lg:right-8 lg:top-6 w-7 z-0 ${!openSidebarRight && "rotate-180"}`}
          onClick={() => setOpenSidebarRight(!openSidebarRight)}
        />

        <div className="flex flex-col  pb-10 lg:gap-x-4 mt-8 mx-3">
          <div className='flex items-center justify-center'>
            <img src='/inicio/perfil.jpg' alt='foto perfil' className='rounded-full w-[150px]'></img>
          </div>

          <div className={`${!openSidebarRight && "hidden"} flex items-center justify-center flex-row mt-2`}>
            <div className='flex flex-col items-center font-bold mx-2'>
              <div className='flex'>
                <PeopleFill size={24} />
                2
              </div>
              <p>Clientes</p>
            </div>
            <div className='flex flex-col items-center font-bold mx-2'>
              <div className='flex'>
                <PersonFillAdd size={24} />
                0
              </div>
              <p>Contactos</p>
            </div>
            <div className='flex flex-col items-center font-bold mx-2'>
              <div className='flex'>
                <StarFill size={24} />
                0
              </div>
              <p>Rating</p>
            </div>

          </div>
          <div className={`${!openSidebarRight && "hidden"} flex flex-col h-full mt-4 `}>
            <h1 className='flex text-start text-[#F27F0C] font-semibold mb-4 justify-start pl-8 text-[24px] capitalize'>
              {format(actualDate, "MMMM ' ' yyyy", esp)}
            </h1>
          </div>
          <div className='flex w-full justify-center'>
            <div className={`${!openSidebarRight && " mt-4"} text-center font-semibold text-white p-2 border rounded-xl bg-[#F27F0C] border-[#F27F0C]`}>
              <h1>
                {format(actualDate, "EEE", esp)}.
              </h1>
              <h2>
                {format(actualDate, "d", esp)}
              </h2>
            </div>
            <div className={`${!openSidebarRight && "hidden"} flex`}>
              <div className='text-center font-semibold text-black p-2'>
                <h1>
                  {format(addDays(actualDate, 1), "EEE", esp)}.
                </h1>
                <h2>
                  {format(addDays(actualDate, 1), "d", esp)}
                </h2>
              </div>
              <div className='text-center font-semibold text-black p-2'>
              <h1>
                  {format(addDays(actualDate, 2), "EEE", esp)}.
                </h1>
                <h2>
                  {format(addDays(actualDate, 2), "d", esp)}
                </h2>
              </div>
              <div className='text-center font-semibold text-black p-2'>
              <h1>
                  {format(addDays(actualDate, 3), "EEE", esp)}.
                </h1>
                <h2>
                  {format(addDays(actualDate, 3), "d", esp)}
                </h2>
              </div>
              <div className='text-center font-semibold text-black p-2'>
              <h1>
                  {format(addDays(actualDate, 4), "EEE", esp)}.
                </h1>
                <h2>
                  {format(addDays(actualDate, 4), "d", esp)}
                </h2>
              </div>
            </div>

          </div>
          <h1 className={`${!openSidebarRight && "hidden"} text-[22px] my-4 text-[#173A73] font-semibold`}>
            Eventos del día
          </h1>
          <div className={`${!openSidebarRight && "hidden"}`}>
            <div className='bg-white horasSidebar rounded-md flex items-center my-4 w-[299px] cursor-pointer'>
              <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-[#F27F0C]'>
                <div>08:00</div>
                <div>Hrs</div>

              </div>
              <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#F27F0C]'>
                <div className='text-[22px] leading-[28px] font-medium'>Hora disponible</div>
                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Nombre servicio</div>
              </div>
            </div>
            <div className='bg-[#D0E5F8] flex rounded-md items-center mb-4 w-[299px] cursor-pointer'>
              <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-16 rounded-md w-[60px] text-center text-white bg-[#173A73]'>
                <div>10:00</div>
                <div>Hrs</div>
              </div>
              <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#173A73]'>
                <div className='text-[22px] leading-[28px] font-medium'>Hector Sagredo</div>
                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Fonoaudiología Adulto</div>
              </div>
            </div>

            <div className='bg-white horasSidebar rounded-md flex items-center mb-4 w-[299px] cursor-pointer'>
              <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-[#F27F0C]'>
                <div>11:00</div>
                <div>Hrs</div>

              </div>
              <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#F27F0C]'>
                <div className='text-[22px] leading-[28px] font-medium'>Hora disponible</div>
                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Nombre servicio</div>
              </div>
            </div>

            <div className='bg-white horasSidebar rounded-md flex items-center mb-4 w-[299px] cursor-pointer'>
              <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-[#F27F0C]'>
                <div>11:00</div>
                <div>Hrs</div>

              </div>
              <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#F27F0C]'>
                <div className='text-[22px] leading-[28px] font-medium'>Hora disponible</div>
                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Nombre servicio</div>
              </div>
            </div>
            <button className={`${!openSidebarRight && "hidden"} flex justify-center mt-4 w-[299px] border rounded-md bg-[#173A73] text-white py-1`}>
              Ver Más
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SidebarPerfil