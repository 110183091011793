import React from 'react'
import PieChart from '../../Graficos/PieCharts'
import TheBarChart from '../../Graficos/BarChart'

const Customersummary = () => {

  // data pie chart
  const labels = ['Clientes', 'Nuevos Clientes']

  const colors = ['#0B2152', '#A3CAF0']

  const clientesTotales = [
    { name: 'Clientes', value: 15 },
    { name: 'Nuevos Clientes', value: 60 }
  ]

  // data bar chart
  const clientes = [

    {
      name: 'Septiembre',
      servicio1: 30,
      agregarServicio: 15,
    },
    {
      name: 'Octubre',
      servicio1: 15,
      agregarServicio: 15,
    },
    {
      name: 'Noviembre',
      servicio1: 0,
      agregarServicio: 20,
    },
  ];

  const dataBar = [
    { name: 'Servicio 1', key: 'servicio1', color: '#0B2152' },
    { name: 'Agregar Servicio', key: 'agregarServicio', color: '#D06208' }

  ]


  return (
    <div className="flex justify-center">
      <div className="w-[75vw]">
        <div className="pt-5">

          <h1 className="mb-5 text-[32px] font-semibold">Resumen Clientes</h1>

          <div className="mx-40">
            <div className="grid grid-cols-8 gap-7 mt-16">
              <div className="col-span-3">
                <div className="h-auto  rounded-lg shadow-xl col-span-2 bg-white">

                  {/* <ChartPieCustomers /> */}
                  <PieChart
                    title="Clientes Totales"
                    data={clientesTotales}
                    labels={labels}
                    colors={colors}
                  />

                </div>
              </div>
              <div className="col-span-5">
                <div className="h-auto  rounded-lg shadow-xl col-span-2 bg-white">

                  <TheBarChart
                    title="Historial de Clientes"
                    data={clientes}
                    dataBar={dataBar}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customersummary
