import Avatar from "react-avatar"
import { PencilSquare, PersonFillGear } from "react-bootstrap-icons"
import "../../styles/inicioStyle.css";

const CardService = () => {
    return (
        <div className="flex flex-col items-center">
            <div className="cardmin-1 flex sm:hidden  h-[450px] w-[80vw] rounded-lg">
                <div className=" grid grid-rows-2 mx-10 gap-4 mt-4 mb-14 rounded-lg">
                    <div className="flex flex-col mt-8  text-[22px] text-[#FFFFFF] font-semibold">
                        Agenda horas y  atiende a tus pacientes, brinda asesorías o enseña a tus alumnos, en un solo sitio.
                    </div>
                    <button className="flex backdrop-blur-sm pointer flex-col rounded-lg border-4 border-[#F27F0C] items-center justify-center h-[209px] text-[24px] text-[#F27F0C] font-semibold">
                        <PersonFillGear size={50} className='mb-8' />
                        <div>
                            Registrar Servicio
                        </div>
                    </button>
                </div>
            </div>
            <div className="cardsmmd-1 hidden sm:flex lg:hidden  h-[250px] w-[80vw] rounded-lg">
                <div className=" grid sm:grid-cols-2 md:grid-cols-3 mx-10 gap-8 my-4 rounded-lg">
                    <div className="flex flex-col mt-8 sm:mt-4 md:w-48  text-[22px] text-[#FFFFFF] font-semibold">
                        Agenda horas y  atiende a tus pacientes, brinda asesorías o enseña a tus alumnos, en un solo sitio.
                    </div>
                    <button className="flex backdrop-blur-sm pointer flex-col rounded-lg border-4 border-[#F27F0C] items-center justify-center h-[209px] text-[24px] text-[#F27F0C] font-semibold">
                        <PersonFillGear size={50} className='mb-8' />
                        <div>
                            Registrar Servicio
                        </div>
                    </button>
                    <button className="hidden md:flex backdrop-blur-sm pointer flex-col rounded-lg border-4 border-[#F27F0C] items-center justify-center h-[209px] text-[24px] text-[#F27F0C] font-semibold">
                        <PersonFillGear size={50} className='mb-8' />
                        <div>
                            Registrar Servicio
                        </div>
                    </button>
                </div>
            </div>
            <div className="cardlg-1 hidden lg:flex  h-[250px] w-[75vw] rounded-lg">
                <div className=" grid grid-cols-3 mx-3 gap-4 my-4 rounded-lg">
                    <div className="flex  flex-col mt-8 text-[22px] text-[#FFFFFF] font-semibold text-left mr-32">
                        Agenda horas, atiende a tus paciente, brinda asesorías o enseña a tus alumnos, en un solo sitio.
                    </div>
                    <button className="flex backdrop-blur-sm pointer flex-col rounded-lg border-4 border-[#0B2152] items-center justify-center h-[209px] text-[24px] text-[#0B2152] font-semibold bg-white/50 hover:bg-[#0B2152]/50 hover:text-white hover:border-white">
                        <PersonFillGear size={50} className='mb-8' />
                        <div>
                            Registrar Servicio
                        </div>
                    </button>
                    <button className="flex backdrop-blur-sm pointer flex-col rounded-lg border-4 border-[#0B2152] items-center justify-center h-[209px] text-[24px] text-[#0B2152] font-semibold bg-white/50 hover:bg-[#0B2152]/25 hover:text-white hover:border-white">
                        <PersonFillGear size={50} className='mb-8' />
                        <div>
                            Actualiza tu Plan
                        </div>
                    </button>
                </div>
            </div>
            <div className="card-2 mt-4 flex cardmd-1 h-[450px] w-[80vw] sm:h-[250px] lg:w-[75vw] rounded-lg">
                <div className=' flex flex-col justify-center  items-center sm:flex-row ml-20 mx-6 my-5 pr-5'>
                    <img src="/inicio/Fi.png" className="w-24 h-28 my-10 sm:mt-14" />
                    <h1 className="flex text-white text-2xl mt-6 ml-8 items-center font-bold">
                        Cuando tengas horas agendadas, se visualizarán aquí.
                    </h1>
                </div>
            </div>
        </div>
    )
}
export default CardService