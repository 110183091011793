import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";

import Main from '../public/Main';
import About from "../public/About/About";
import Register from "../public/Register";
import Terms from "../public/Terms"
import Login from "../public/Login/Login";
import ResetPassword from "../public/ResetPassword";
import ChangePassword from "../public/ChangePassword";
import HomeRouter from "./HomeRouter";

//router principal
export const AppRouter = () => {
  return (
    <>
      <BrowserRouter>
        <React.Fragment>
          <Routes>
            <Route path="/" element={
              <PublicRoute>
                <Main />
              </PublicRoute>
            } />
            <Route path="/acerca" element={
              <PublicRoute>
                <About />
              </PublicRoute>
            } />
            <Route exact path="/terminosycondiciones" element={
              <PublicRoute>
                <Terms />
              </PublicRoute>
            } />
            <Route path="/registro" element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            } />
            <Route path="/iniciar-sesion" element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            } />
            <Route path="/reestablecer_contrasenia" element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            } />
            <Route path="/cambioContrasenia" element={
              <PublicRoute>
                <ChangePassword />
              </PublicRoute>
            } />
            <Route path="/*" element={
              <PrivateRoute>
                <HomeRouter />
              </PrivateRoute>
            } />
          </Routes>
        </React.Fragment>
      </BrowserRouter>
    </>
  )
}