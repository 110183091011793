import { useState, useEffect } from 'react';

const UsePaises = () => {

    const [paises, setPaises] = useState([])
    const baseURL = process.env.REACT_APP_API_BASE_URL; //URL base
    const endpoint = `${baseURL}/ms-loginusuario/profesiones`;

    const showData = async () => {
        const response = await fetch(endpoint)
        const data = await response.json()
        setPaises(data)
    };

    useEffect(() => {
        showData()
    }, []);

    return { paises };
}

export default UsePaises 