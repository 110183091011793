import React, { useState } from 'react';
import { BsArrowUpCircle } from "react-icons/bs";

const ScrollButton = () => {

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <div className='flex justify-end mr-10'>
      <BsArrowUpCircle onClick={scrollToTop}
        style={{ display: visible ? 'inline' : 'none' }}
        className='ButtonScroll fill-[#D06208] animate-bounce mb-20 ' />
    </div>
  );
}

export default ScrollButton;