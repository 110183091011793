import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { PersonAdd, People, StarFill } from 'react-bootstrap-icons';

const Caurosel = ({title}) => {

    // const [img, setImg] = useState(url())

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2
        },
        mobile2: {
            breakpoint: { max: 768, min: 640 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const carousel = [
        { image: '/planes/img_planes/Fonoaudiologia_A.png', name: 'Fonoaudiología', totalCustomer: '23', newCustomer: '14', valoration: '5.0' },
        { image: '/planes/img_planes/Informatico_B.png', name: 'Informática', totalCustomer: '12', newCustomer: '8', valoration: '4.3' },
        { image: '/planes/img_planes/Medicina.png', name: 'Medicina', totalCustomer: '29', newCustomer: '1', valoration: '4.0' },
        { image: '/planes/img_planes/Profesor_A.png', name: 'Educación', totalCustomer: '28', newCustomer: '1', valoration: '3.0' },
        { image: '/planes/img_planes/Psicologo_A.png', name: 'Psicología', totalCustomer: '25', newCustomer: '10', valoration: '4.7' },
        { image: '/planes/img_planes/Psicopedagogia.png', name: 'Psicopedagogía', totalCustomer: '25', newCustomer: '10', valoration: '4.7' },
        { image: '/planes/img_planes/Psiquiatra_B.png', name: 'Psiquiatría', totalCustomer: '25', newCustomer: '10', valoration: '4.7' },
        { image: '/planes/img_planes/Terapia_ocupacional_A.png', name: 'Terapia Ocupacional', totalCustomer: '25', newCustomer: '10', valoration: '4.7' }
    ]

    const carouselMovile = [
        { image: '/planes/img_planes/Fonoaudiologia_A.png', name: 'Fonoaudiología', totalCustomer: '23', newCustomer: '14', valoration: '5.0' },
        { image: '/planes/img_planes/Informatico_B.png', name: 'Informática', totalCustomer: '12', newCustomer: '8', valoration: '4.3' },
        { image: '/planes/img_planes/Medicina.png', name: 'Medicina', totalCustomer: '29', newCustomer: '1', valoration: '4.0' },
        { image: '/planes/img_planes/Profesor_A.png', name: 'Educación', totalCustomer: '28', newCustomer: '1', valoration: '3.0' },
        { image: '/planes/img_planes/Psicologo_A.png', name: 'Psicología', totalCustomer: '25', newCustomer: '10', valoration: '4.7' },
        { image: '/planes/img_planes/Psicopedagogia.png', name: 'Psicopedagogía', totalCustomer: '25', newCustomer: '10', valoration: '4.7' },
        { image: '/planes/img_planes/Psiquiatra_B.png', name: 'Psiquiatría', totalCustomer: '25', newCustomer: '10', valoration: '4.7' },
        { image: '/planes/img_planes/Terapia_ocupacional_A.png', name: 'Terapia Ocupacional', totalCustomer: '25', newCustomer: '10', valoration: '4.7' }
    ]


    return (
        <div className="flex justify-center">
            <div className="w-[75vw]">
                <div className="mt-6">
                    <h1 className="ml-5 mb-6 text-[36px] font-semibold">{title}</h1>
                </div>
                <div className="hidden sm:block mb-16" style={{
                    paddingBottom: '30px',
                    position: 'relative'
                }}>
                    <Carousel
                        responsive={responsive}
                        arrows={false}
                        renderButtonGroupOutside={false}
                        showDots={true}
                        slidesToSlide={2}
                        renderDotsOutside
                        autoPlay
                        autoPlaySpeed={6000}
                        infinite
                    >
                        {carousel.map((card, index) =>
                            <div className="h-[200px] md:h-[250px] w-auto rounded-lg flex flex-col justify-around bg-cover mx-2"
                                style={{ backgroundImage: `url(${card.image})` }}
                                key={index} >

                                <div className="flex justify-end">
                                    <div className="bg-[#F27F0C] rounded-l-lg ">
                                        <div className="w-[129px] h-[54px] flex items-center justify-center">
                                            <h1 className="text-white text-[16px] text-center px-[10px]">{card.name}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-center">
                                    <div className="flex flex-row gap-[43px] px-0 md:px-4 lg:px-0">
                                        <div className="">
                                            <div className="flex flex-row gap-1 justify-center items-center">
                                                <People fill="white" size="24" />

                                                <span className="text-white font-semibold text-[20px]">{card.totalCustomer}</span>
                                            </div>
                                            <h2 className="text-white font-semibold text-[12px] text-center">Total Clientes</h2>
                                        </div>

                                        <div className="">
                                            <div className="flex flex-row gap-1 justify-center items-center">
                                                <PersonAdd fill="white" size="24" />

                                                <span className="text-white font-semibold text-[20px]">{card.newCustomer}</span>
                                            </div>
                                            <h2 className="text-white font-semibold text-[12px] text-center">Nuevos Clientes</h2>
                                        </div>
                                        <div className="">
                                            <div className="flex flex-row gap-1 justify-center items-center">
                                                <StarFill fill="white" size="24" />

                                                <span className="text-white font-semibold text-[20px]">{card.valoration}</span>
                                            </div>
                                            <h2 className="text-white font-semibold text-[12px] text-center">Valoración</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}



                    </Carousel>
                </div>
                {/* Mobile */}

                <div className="block sm:hidden space-y-6">
                    {carouselMovile.map((cardMovile, index) =>
                        <div className="img-1 h-[200px] xl:h-[250px] w-full rounded-lg flex flex-col justify-around"
                            style={{ backgroundImage: `url(${cardMovile.image})` }}
                            key={index} >
                            {/* <div className=" h-[200px] ">
                            <img className="w-full h-full rounded-lg" src="img_home/psicologiaInfantil.jpg" alt="psicologiaInfantil" />
                        </div> */}
                            <div className="flex justify-end">
                                <div className="bg-[#F27F0C] rounded-l-lg ">
                                    <div className="w-[129px] h-[54px] flex items-center justify-center">
                                        <h1 className="text-white text-[16px] text-center px-[10px]">{cardMovile.name}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center">
                                <div className="flex flex-row gap-8 ">
                                    <div className="">
                                        <div className="flex flex-row gap-1 justify-center items-center">
                                            <People fill="white" size="16" />

                                            <span className="text-white font-semibold text-[20px]">{cardMovile.totalCustomer}</span>
                                        </div>
                                        <h2 className="text-white font-semibold text-[12px] text-center">Total Clientes</h2>
                                    </div>

                                    <div className="">
                                        <div className="flex flex-row gap-1 justify-center items-center">
                                            <PersonAdd fill="white" size="16" />

                                            <span className="text-white font-semibold text-[20px]">{cardMovile.newCustomer}</span>
                                        </div>
                                        <h2 className="text-white font-semibold text-[12px] text-center">Nuevos Clientes</h2>
                                    </div>
                                    <div className="">
                                        <div className="flex flex-row gap-1 justify-center items-center">
                                            <StarFill fill="white" size="16" />

                                            <span className="text-white font-semibold text-[20px]">{cardMovile.valoration}</span>
                                        </div>
                                        <h2 className="text-white font-semibold text-[12px] text-center">Valoración</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div >
    )
}

export default Caurosel