import React from 'react'
import { Grid, List } from 'react-bootstrap-icons';

const Filter = ({ setView, title, filterList, activeView }) => {

    return (
        <div>
            <div className="flex justify-center" >
                <div className="w-[75vw]">
                    <div className="pt-8">
                        <h1 className="mb-5 text-[32px] font-semibold">{title}</h1>

                        <div className="flex justify-between w-full h-14 bg-[#D0E5F8] rounded-lg px-8">
                            <div className="flex items-center gap-6 text-[#0B2152] ">
                                <h1 className="text-[22px] font-medium">Ordenar por:</h1>
                                {filterList.map((filter) => (
                                    <h2 className="text-[16px] font-medium cursor-pointer">{filter}</h2>
                                ))}
                            </div>
                            {activeView &&
                                <div className="flex items-center gap-6">
                                    <h1 className="text-[22px] font-medium">Ver:</h1>
                                    <Grid onClick={() => setView(false)} size="24" className="cursor-pointer" />
                                    <List onClick={() => setView(true)} size="24" className="cursor-pointer" />
                                </div>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Filter