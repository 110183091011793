import React from 'react'
// import '../styles/home.css';



const Planes = () => {
    return (
        <div>
            <div className=" flex justify-center pt-[520px] sm:pt-52 md:pt-64 lg:pt-0 mt-52">
                <div className="w-[75vw] mt-10">
                    <div className="grid grid-rows-1 gap-8 lg:grid-cols-3 lg:gap-8 ">
                        {/* Plan free */}
                        <div className="cursor-pointer ">
                            <div className="grow h-[700px] md:h-[950px] shadow-2xl rounded-lg">
                                <div className="bg-[#137722] rounded-t-lg h-[30px]">
                                    {/* <h1 className="text">a</h1> */}
                                </div>
                                <div className="flex flex-col justify-between h-[620px] md:h-[900px]">
                                    <div className="">
                                        <h1 className="text-[22px] lg:text-[32px] font-semibold ml-4 my-6">Plan Free</h1>

                                        <ul className="ml-8 space-y-6 lg:space-y-4 font-medium">
                                            <li type="disc">
                                                <h2 className="text-[16px]  md:text-[22px] text-left">Registra tu profesión</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Ofrece 1 servicio en nuestra plataforma.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Gestióna tu agenda  y reservas  online 24/7.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Recordatorio de citas</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Soporte técnico.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Servicio de video llamadas.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Citas precobradas y confirmadas.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">3 Reagendamiento citas por mes.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left"> Cobro 25 % comisión por servicio</h2>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex justify-center">
                                        <button className="text-[#173A73] mt-4 sm:mt-0 py-[5px] px-[20px] md:py-[10px] md:px-[90px] lg:py-[10px] lg:px-[60px] xl:py-[10px] xl:px-[90px] border-4 border-[#173A73] rounded-md text-[16px] md:text-[22px] hover:text-white hover:bg-[#173A73]">
                                            Obtener Plan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* plan pro */}
                        <div className="cursor-pointer">
                            <div className="grow h-[700px] md:h-[950px] bg-[#173A73] shadow-2xl rounded-lg">
                                <div className="bg-[#FBC16B] rounded-t-lg h-[30px]">
                                    {/* <h1 className="">a</h1> */}
                                </div>
                                <div className="flex flex-col text-white justify-between h-[620px] md:h-[900px]">
                                    <div className="">
                                        <h1 className="text-[22px] lg:text-[32px] font-semibold ml-4 my-6">Plan Pro</h1>

                                        <ul className="ml-8 space-y-2 sm:space-y-6 lg:space-y-4 font-medium mb-8 sm:mb-0">
                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">Registra tu profesión</h1>
                                            </li>

                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">Ofrece 3 servicios en nuestra plataforma.</h1>
                                            </li>

                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">Gestióna tu agenda  y reservas  online 24/7.</h1>
                                            </li>

                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">Chatea con tus clientes.</h1>
                                            </li>

                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">Recordatorio de citas.</h1>
                                            </li>

                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">Soporte técnico.</h1>
                                            </li>

                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">Servicio de video llamadas.</h1>
                                            </li>

                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">Citas precobradas y confirmadas.</h1>
                                            </li>

                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">9 Reagendamiento citas por mes.</h1>
                                            </li>

                                            <li type="disc">
                                                <h1 className="text-[16px] md:text-[22px] text-left">Cobro 20 % comisión por servicio</h1>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="flex justify-center">
                                        <button className="text-white mt-4 sm:mt-0 py-[5px] px-[20px] md:py-[10px] md:px-[90px] lg:py-[10px] lg:px-[60px] xl:py-[10px] xl:px-[90px] border-4 border-white rounded-md text-[16px] md:text-[22px]">
                                            Plan Actual
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> 

                        {/* plan ilimitado */}
                        <div className="cursor-pointer ">
                            <div className="grow h-[700px] md:h-[950px] shadow-2xl rounded-lg">
                                <div className="bg-[#F27F0C] rounded-t-lg h-[30px]">
                                    {/* <h1 className="text">a</h1> */}
                                </div>
                                <div className="flex flex-col justify-between h-[620px] md:h-[900px]">
                                    <div className="">
                                        <h1 className="text-[22px] lg:text-[32px] font-semibold ml-4 my-6">Plan Ilimitado</h1>

                                        <ul className="ml-8 space-y-6 lg:space-y-4 font-medium">
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Registra tu profesión</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Ofrece servicios ilimitados.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Gestióna tu agenda  y reservas  online 24/7.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Chatea con tus clientes.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Recordatorio de citas</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Soporte técnico.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Servicio de video llamadas.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">Citas precobradas y confirmadas.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left">9 Reagendamiento citas por mes.</h2>
                                            </li>
                                            <li type="disc">
                                                <h2 className="text-[16px] md:text-[22px] text-left"> Cobro 20 % comisión por servicio</h2>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex justify-center ">
                                        <button className="text-[#173A73] mt-4 sm:mt-0 py-[5px] px-[20px] md:py-[10px] md:px-[90px] lg:py-[10px] lg:px-[60px] xl:py-[10px] xl:px-[90px] border-4 border-[#173A73] rounded-md text-[16px] md:text-[22px] hover:text-white hover:bg-[#173A73]">
                                            Obtener Plan
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Planes