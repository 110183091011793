import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { LoginBG } from './Login/LoginBG';
import { mostrarAlertaError, mostrarAlertaRP } from '../helpers/FetchResponse';

import { Button } from 'primereact/button';
import { ArrowLeftCircle } from 'react-bootstrap-icons';
import { useFormik } from 'formik';

const validate = (values) => {
    const errors = {}

    if (!values.email) {
        errors.email = 'El email es requerido'
    }
    return errors
}

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    //URL base
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const formReset = useFormik({
        initialValues: {
            email: '',
        },
        validate,
        onSubmit: values => {
            setLoading(true);

            fetch(`${baseURL}/ms-loginusuario/password/forgot`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    correo_electronico: values.email,
                }),
            }).then(x => x.json()).then(x => {
                if (x.success) {
                    mostrarAlertaRP(x.data.message)
                    setLoading(false)

                } else {
                    mostrarAlertaError()
                    setLoading(false)
                }
            });
        }
    })

    return (
        <>
            {/* background de reestablecer contraseña */}
            <LoginBG />

            <div className="w-screen h-screen flex flex-col justify-center lg:flex-row lg:justify-evenly items-center absolute top-0">

                <img src="logos_figaruz/logoFigaruzBlanco.png" className='w-0 h-0 lg:w-[300px] lg:h-[300px] xl:w-[350px] xl:h-[350px]' alt='logo_figaruz' />

                <form className="w-11/12 sm:w-8/12 md:w-7/12 lg:w-5/12 xl:w-[36vw] xl:h-[58vh] backdrop-blur-md bg-white/30 rounded-xl" onSubmit={formReset.handleSubmit} >

                    <div className='flex flex-col h-full w-full p-6 gap-8 xl:px-6'>
                        <div className='self-end my-2 text-[#0B2152]' onClick={() => navigate(-1)}>
                            <ArrowLeftCircle size={38} className="stroke-0" />
                        </div>

                        <h1 className='font-bold text-[#0B2152] text-4xl xl:text-5xl text-center'>Recuperar contraseña</h1>
                        <h2 className='text-center xl:px-16 text-[#0B2152]'>Sigue los pasos enviados a tu correo de registro para recuperar tu contraseña</h2>

                        <div className='flex flex-col mt-10 xl:px-16'>
                            <label className="font-bold text-lg text-[#0B2152]">Ingresa tu correo electrónico</label>
                            <input
                                placeholder="correo@dominio.com"
                                type="email"
                                name="email"
                                onChange={formReset.handleChange}
                                value={formReset.values.email}
                                className="bg-transparent border-2 border-[#0B2152] placeholder-[#0B2152] rounded-md focus:ring-[#0B2152] focus:border-[#0B2152] focus:bg-transparent active:bg-transparent"
                            />

                            {formReset.touched.email && formReset.errors.email ? <div className=' text-red-700 text-left font-semibold text-sm'>{formReset.errors.email}</div> : null}

                            <Button type='submit' loading={loading} label='Enviar' className='RP-BReset w-full transition duration-300 ease-out hover:ease-in' />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}

export default ResetPassword