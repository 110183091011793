import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const TheBarChart = ({ title, data, dataBar }) => {


    return (
        <div className="">
            <h1 className="text-center text-[22px] font-semibold">{title}</h1>

            <div style={{ width: '100%', height: 300 }}>

                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {dataBar.map((bar, index) => (
                            <Bar key={index} name={bar.name} dataKey={bar.key} stackId="a" fill={bar.color} />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default TheBarChart
