import React from 'react'
import {
    PencilSquare,
    People,
    PersonAdd,
    StarFill
} from 'react-bootstrap-icons';
// import { Background } from 'react-parallax';

const CardServices = () => {

    const servicesList = [
        {image: './planes/img_planes/Fonoaudiologia_A.png', name: 'Fonoaudiologia', totalCustomer: '30', newCustomer: '12', valoration: '4.8'},
        {image: './planes/img_planes/Fonoaudiologia_A.png', name: 'Fonoaudiologia', totalCustomer: '34', newCustomer: '7', valoration: '3.8'},
        {image: './planes/img_planes/Fonoaudiologia_A.png', name: 'Fonoaudiologia', totalCustomer: '12', newCustomer: '6', valoration: '4.6'},
        {image: './planes/img_planes/Fonoaudiologia_A.png', name: 'Fonoaudiologia', totalCustomer: '16', newCustomer: '4', valoration: '4.0'},
        {image: './planes/img_planes/Fonoaudiologia_A.png', name: 'Fonoaudiologia', totalCustomer: '9', newCustomer: '1', valoration: '2.8'}
    ]

    return (
        <div className="grid grid-cols-3 gap-5 my-10 ">
            <div className="rounded-lg shadow-xl bg-white w-full h-[200px] flex justify-center items-center flex-col  border-4 border-[#173A73] cursor-pointer">
                <PencilSquare size="24" color="#173A73" />
                <h2 className="text-[#173A73] font-medium text-[24px] text-center m-4">Registrar Servicio</h2>
            </div>
            {/* card-1 */}

            {servicesList.map((services, index) => 
            <div className=" rounded-lg shadow-xl bg-white w-full h-[200px] flex flex-col justify-around bg-cover" 
                style={{backgroundImage: `url(${services.image})`}}
                key={index} >
                <div className="flex justify-end">
                    <div className="bg-[#F27F0C] rounded-l-lg ">
                        <div className="w-[129px] h-[54px] flex items-center justify-center">
                            <h1 className="text-white text-[16px] text-center px-[10px]">{services.name}</h1>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div className="flex flex-row gap-[43px] px-0 md:px-4 lg:px-0">
                        <div className="">
                            <div className="flex flex-row gap-1 justify-center items-center">
                                <People fill="white" size="24" />

                                <span className="text-white font-semibold text-[20px]">{services.totalCustomer}</span>
                            </div>
                            <h2 className="text-white font-semibold text-[12px] text-center">Total Clientes</h2>
                        </div>

                        <div className="">
                            <div className="flex flex-row gap-1 justify-center items-center">
                                <PersonAdd fill="white" size="24" />

                                <span className="text-white font-semibold text-[20px]">{services.newCustomer}</span>
                            </div>
                            <h2 className="text-white font-semibold text-[12px] text-center">Nuevos Clientes</h2>
                        </div>
                        <div className="">
                            <div className="flex flex-row gap-1 justify-center items-center">
                                <StarFill fill="white" size="24" />

                                <span className="text-white font-semibold text-[20px]">{services.valoration}</span>
                            </div>
                            <h2 className="text-white font-semibold text-[12px] text-center">Valoración</h2>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    )
}

export default CardServices