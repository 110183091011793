import React from 'react'
import {
    ArrowLeftCircle,
    ArrowRightCircle
} from 'react-bootstrap-icons';

const Pagination = () => {
    return (
        <div>
            <div className="flex justify-between w-full h-[74px] bg-[#D0E5F8] rounded-lg px-20 mb-14">
                <div className="flex items-center justify-between text-[#0B2152] w-full">
                    <button className="px-6 py-3 bg-[#0B2152] rounded-lg text-white flex justify-center items-center">
                        <div className="flex flex-row items-center gap-2 text-[16px]">
                            <ArrowLeftCircle size="24" />
                            Anterior
                        </div>
                    </button>
                    {/* Páginas */}
                    <div>
                        <ul className="flex gap-5 text-[16px]">
                            <li className="cursor-pointer">1</li>
                            <li className="cursor-pointer">2</li>
                            <li className="cursor-pointer">3</li>
                            <li className="cursor-pointer">4</li>
                            <li className="cursor-pointer">5</li>
                        </ul>
                    </div>

                    <button className="px-6 py-3 bg-[#0B2152] rounded-lg text-white flex justify-center items-center">
                        <div className="flex flex-row items-center gap-2 text-[16px]">
                            Siguiente
                            <ArrowRightCircle size="24" />

                        </div>
                    </button>

                    <div className="Flex flex-row">
                        <div className="">
                            X Páginas
                        </div>
                    </div>

                    <div>
                        <h2>
                            Ir a la Página
                        </h2>
                    </div>
                    <div className="flex gap-6">
                        {/* ReactDOM.render(<Validate />, document.body); */}

                        <input type="number" maxLength="2"
                            className="w-32 h-11 border-[1px] border-[#0B2152] rounded-lg bg-[#D0E5F8] text-center">
                        </input>
                        <button className="px-6 py-3 bg-[#0B2152] text-white rounded-lg">
                            Ir
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pagination