import React from 'react'
import { Link } from "react-scroll";
import { Parallax } from 'react-parallax';

const Home = () => {
    return (
        <div className='flex flex-col items-center'>
            <div>
                {/* pantalla lg  */}
                <Parallax className='z-0 w-screen hidden lg:block' bgImage="Landing_page/bg_imagenes/bg-main.jpeg" bgImageAlt="home" strength={200}>
                    <div className="flex flex-col items-center" id='home'>

                        <div className="M-bg-main mx-4 flex items-center sm:items-start h-[90vh] sm:h-[75vh] w-screen">
                            <div className=" w-[97vw] sm:w-[85vw] mt-20 sm:mt-28 md:mt-28 mb-16 sm:mb-20 md:mb-20">
                                <div className="M-AreaTexto sm:ml-16 lg:ml-24 xl:ml-36 flex justify-center mx-4">
                                    <div className="sm:pt-5 md:pt-0 leading-[56px] font-bold text-white text-[50px] mb-6">La plataforma del trabajo digital</div>
                                    <h2 className="leading-[28px] text-white font-bold -mt-12 text-[25px] w-5/12">Publica tus servicios, gestiona tu agenda, atiende a tus clientes en un solo sitio.</h2>
                                </div>
                            </div>
                            <Link to='registro' spy={true} smooth={true} offset={-40} duration={500} 
                            className="M-BRegistrarme cursor-pointer transition duration-300 ease-out hover:ease-in sm:ml-16 lg:ml-24 xl:ml-36 mb-32 mt-8 sm:-mt-10">Registrarme</Link>
                        </div>
                    </div>
                </Parallax>
                {/* pantalla sm - md */}
                <Parallax className='z-0 w-screen hidden sm:block lg:hidden' bgImage="Landing_page/bg_imagenes/bg-main3.jpeg" bgImageAlt="home" strength={200}>
                    <div className="flex flex-col items-center" id='home'>

                        <div className="M-bg-main mx-4 flex items-center sm:items-start w-screen">
                            <div className=" w-[97vw] sm:w-[85vw] mt-20 sm:mt-28 md:mt-28 mb-16 sm:mb-20 md:mb-20">
                                <div className="M-AreaTexto sm:ml-16 lg:ml-24 xl:ml-36 flex justify-center mx-4">
                                    <h1 className="sm:pt-5 md:pt-0 leading-[56px] font-bold text-white text-[30px]">La plataforma del trabajo digital</h1>
                                    <h2 className="MH2 -mt-12 text-[20px]">Publica tus servicios, gestiona tu agenda, atiende a tus clientes en un solo sitio.</h2>
                                </div>
                            </div>
                            <Link to='registro' spy={true} smooth={true} offset={-40} duration={500} 
                            className="M-BRegistrarme cursor-pointer transition duration-300 ease-out hover:ease-in sm:ml-16 lg:ml-24 xl:ml-36 mb-36 mt-8 sm:-mt-10">Registrarme</Link>
                        </div>
                    </div>
                </Parallax>
                {/* pantalla antes de sm */}
                <Parallax className='z-0 w-screen block sm:hidden' bgImage="Landing_page/bg_imagenes/bg-main2.jpeg" bgImageAlt="home" strength={200}>
                    <div className="flex flex-col items-center" id='home'>

                        <div className="M-bg-main flex items-center sm:items-start h-[90vh] sm:h-[75vh] w-full">
                            <div className=" flex justify-center w-[97vw] sm:w-[85vw] mt-20 sm:mt-28 md:mt-28 mb-16 sm:mb-20 md:mb-20">
                                <div className="M-AreaTexto mt-10 md:mt-0 ml-2 sm:ml-16 lg:ml-24 xl:ml-36 flex justify-center  ">
                                    <h1 className="sm:pt-5 md:pt-0 leading-[56px] font-bold text-white text-[35px]">La plataforma del trabajo digital</h1>
                                    <h2 className="MH2 -mt-12 text-[18px]">Publica tus servicios, gestiona tu agenda, atiende a tus clientes en un solo sitio.</h2>
                                </div>
                            </div>
                            <Link to='registro' spy={true} smooth={true} offset={-40} duration={500}
                                className="M-BRegistrarme cursor-pointer transition duration-300 ease-out hover:ease-in sm:ml-16 lg:ml-24 xl:ml-36 md:mb-14 mb-[150px]  sm:-mt-10 px-[150px]">Registrarme</Link>
                        </div>
                    </div>
                </Parallax>
            </div>

            <div className="M-containerServicios z-10 flex justify-around w-[85vw] -mt-24 sm:-mt-32  px-[70px] py-[55px] gap-3 bg-white">
                <div className="grid sm:grid-cols-3 gap-5 md:grid-cols-3 md:gap-12 2xl:gap-40">
                    <div className="flex flex-col gap-5 justify-between items-center">
                        <img src="Landing_page/iconos/icono_asesoramiento.svg" alt='icono_asesoramiento' />
                        <h3 className="M-H3">Ofrece asesorías y gana dinero desde cualquier parte del mundo.</h3>
                    </div>
                    <div className="flex flex-col gap-5 justify-between items-center">
                        <img src="Landing_page/iconos/icono_gestion.svg" alt='icono_gestion' />
                        <h3 className="M-H3">Gestión de tareas, <br /> clientes y citas en un mismo lugar.</h3>
                    </div>
                    <div className="flex flex-col gap-5 justify-between items-center">
                        <img src="Landing_page/iconos/icono_chat.svg" alt='icono_chat' />
                        <h3 className="M-H3">Chatea en línea con profesionales de forma privada y segura.</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home