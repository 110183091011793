import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { LoginBG } from '../public/Login/LoginBG'
import { mostrarAlertaError } from "../helpers/FetchResponse"

import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Eye, EyeSlash, BoxArrowInRight, CheckCircleFill } from 'react-bootstrap-icons';
import { Dialog } from 'primereact/dialog';

const validate = (values) => {
    const errors = {}
    if (values.Password !== values.Pass2) {
        errors.Password = 'Ambas contraseñas deben ser iguales'
    }
    return errors
}
const ChangePassword = () => {
    const [loading, setLoading] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [show, setShow] = useState(false);

    //URL base
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    const resetId = queryParams.get('reset_id')

    const formChangePass = useFormik({
        initialValues: {
            Password: '',
            Pass2: '',
            tokenURL: token,
            reset_id: resetId
        },
        validate,
        onSubmit: values => {
            setLoading(true);

            fetch(`${baseURL}/ms-loginusuario/password/forgot`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: values.tokenURL,
                    contrasenia: values.Password,
                    reset_id: values.reset_id
                }),
            }).then(x => x.json()).then(x => {
                if (x.success) {
                    setLoading(false);
                    setShow(true)
                } else {
                    mostrarAlertaError(x.data.message)
                    setLoading(false);
                    console.log(x)
                }
            });
        }
    })

    return (
        <>
            {/* background de cambio de contraseña */}
            <LoginBG />

            <div className="w-screen h-screen flex flex-col justify-center lg:flex-row lg:justify-evenly items-center absolute top-0">

                <form className="w-11/12 sm:w-8/12 md:w-7/12 lg:w-5/12 xl:w-[36vw] backdrop-blur-md bg-white/30 rounded-xl" onSubmit={formChangePass.handleSubmit}>

                    <div className='flex flex-col h-full w-full p-6 gap-16 xl:px-6 xl:py-16'>
                        <div>
                            <h1 className="font-bold text-4xl xl:text-5xl text-center mb-1 text-[#0B2152]">Restablece tu contraseña</h1>
                            <p className="text-center font-semibold xl:px-16 text-[#0B2152]">Ingresa tu nueva contraseña</p>
                        </div>

                        <div className='flex flex-col gap-4'>
                            <div className='flex flex-col xl:px-16'>
                                <p className="font-bold text-[#0B2152]">8 caracteres como mínimo y 16 como máximo.</p>

                                <input
                                    type={showPass ? 'text' : 'password'}
                                    placeholder='Nueva contraseña'
                                    className='w-full bg-transparent border-2 border-[#0B2152] placeholder-[#0B2152] rounded-md focus:ring-[#0B2152] focus:border-[#0B2152] focus:bg-transparent active:bg-transparent'
                                    name='Password'
                                    value={formChangePass.values.Password}
                                    onChange={formChangePass.handleChange}
                                    maxLength={16}
                                    minLength={8}
                                />
                                {showPass ? <Eye size={20} className="mt-1 ml-1 text-[#0B2152]" onClick={() => setShowPass(!showPass)} /> : <EyeSlash size={20} className="mt-1 ml-1 text-[#0B2152]" onClick={() => setShowPass(!showPass)} />}
                                {formChangePass.touched.Password && formChangePass.errors.Password ? <div className='text-red-700 text-left font-semibold text-sm'>{formChangePass.errors.Password}</div> : null}
                            </div>

                            <div className='flex flex-col xl:px-16'>
                                <input
                                    type={showPass ? 'text' : 'password'}
                                    placeholder='Vuelve a escribir la contraseña'
                                    className='w-full bg-transparent border-2 border-[#0B2152] placeholder-[#0B2152] rounded-md focus:ring-[#0B2152] focus:border-[#0B2152] focus:bg-transparent active:bg-transparent'
                                    name='Pass2'
                                    value={formChangePass.values.Pass2}
                                    onChange={formChangePass.handleChange}
                                    maxLength={16}
                                    minLength={8}
                                />
                                {showPass ? <Eye size={20} className="mt-1 ml-1 text-[#0B2152]" onClick={() => setShowPass(!showPass)} /> : <EyeSlash size={20} className="mt-1 ml-1 text-[#0B2152]" onClick={() => setShowPass(!showPass)} />}
                                {formChangePass.touched.Password && formChangePass.errors.Password ? <div className='text-red-700 text-left font-semibold text-sm'>{formChangePass.errors.Password}</div> : null}
                            </div>

                            <div className='flex flex-col xl:px-16'>
                                <Button type='submit' loading={loading} label='Cambiar contraseña' className='RP-BReset w-full transition duration-300 ease-out hover:ease-in' />
                                <p className='text-sm text-center'>¿Quieres inciar sesión?</p>
                                <Link to='/iniciar-sesion' className="w-auto text-[#173A73] text-sm px-5 font-bold text-center hover:text-[#223f6e] transition duration-300 ease-in-out">inicia sesión acá</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <Dialog visible={show} className='w-10/12 md:w-7/12 lg:w-5/12 xl:w-5/12' onHide={() => setShow(false)}>
                <div className='flex flex-col items-center justify-center gap-10'>
                    <CheckCircleFill className="fill-[#173A73]" size={100} />

                    <p className='text-center text-2xl lg:text-xl'>
                        Se cambió la contraseña correctamente
                    </p>
                    <div className='bg-[#173A73] p-3 rounded-md text-white'>
                        <Link className='text-xl flex items-center justify-center gap-3' to='/iniciar-sesion'>Iniciar sesión <BoxArrowInRight size={30} /> </Link>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default ChangePassword