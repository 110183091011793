
import { InputNumber } from 'primereact/inputnumber';
import { Card } from 'primereact/card';
import { useState } from 'react';
 
const CardBank = () => {
    const [value9, setValue9] = useState(58151);
    return(
        <div className='flex flex-col'>
            <button className='border w-[360px] ml-8 py-2 rounded-lg mb-2 bg-[#173A73] text-white font-semibold'>
                Añadir Nueva Cuenta
            </button>
            <div className='flex flex-col mb-8 w-[360px] ml-8'>
                <h1>
                    Monto
                </h1>
            <InputNumber inputId="currency-cl" value={value9} onValueChange={(e) => setValue9(e.value)} mode="currency" currency="CLP" locale="es-CL" />
            </div>
            <div className='flex justify-center'>
              <Card style={{backgroundColor: '#173A73', width: "360px", height: "215px", }}>
                <img src='/Saldos/chip.png' alt='chip' className='w-14'></img>
                <h1 className='text-[24px] text-white font-semibold'>
                    Banco Falabella
                </h1>
                <h2 className='text-[22px] text-white ml-4 mt-4'>
                    Numero de Cuenta
                </h2>
            </Card>  
            </div>
        </div>
    )
}
export default CardBank