import React from 'react'

const AboutSection5 = () => {
    return (
        <div className='px-3 lg:px-[100px] lg:w-10/12'>
            <h1 className='text-[#173A73] font-bold text-[25px] sm:text-[38px] md:text-[42px] xl:text-[54px] leading-[63px]'>Nuestros partners tecnológicos:</h1>

            <div className="flex flex-col gap-10 md:flex-row md:justify-between items-center mt-10">
                <a href='https://atx.mx/' target="_blank" rel="noopener noreferrer">
                    <img className="w-52" src="logos_partners/logo_atx.png" alt="logo_atx" />
                </a>
                <a href='https://azure.microsoft.com/' target="_blank" rel="noopener noreferrer">
                    <img className="w-48" src="logos_partners/azure.png" alt="logo_microsoft_azure" />
                </a>
                <a href='https://www.microsoft.com/' target="_blank" rel="noopener noreferrer"> 
                    <img className="w-56" src="logos_partners/Microsoft_logo_(2012)_svg.png" alt="logo_microsoft" />
                </a>
            </div>

            <div className="flex flex-col gap-10 mt-10 md:flex-row md:justify-around items-center mb-10">
                <a href='https://www.sercotec.cl/' target="_blank" rel="noopener noreferrer">
                    <img className="w-40" src="logos_partners/Logo-Sercotec.png" alt="logo_sercotec" />
                </a>
                <a href='https://www.fondoesperanza.cl/' target="_blank" rel="noopener noreferrer">
                    <img className="w-36" src="logos_partners/fe_movil.png" alt="logo_fondo_esperanza" />
                </a>
            </div>
        </div>
    )
}

export default AboutSection5