import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { LoginBG } from './LoginBG';
import "../../styles/loginStyle.css"

import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { XCircle } from 'react-bootstrap-icons';
import { mostrarAlertaError } from '../../helpers/FetchResponse';

//Validaciones
const validate = (values) => {
    const errors = {}
    if (!values.email) {
        errors.email = 'El correo electrónico es requerido'
    }

    if (!values.password) {
        errors.password = 'La contraseña es requerida'
    }
    return errors
}

//Inicio de sesión
const Login = () => {
    const navigate = useNavigate();
    //URL base
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    const [loading, setLoading] = useState(false);


    const formLogin = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: false,
        },
        validate,
        onSubmit: values => { 
            setLoading(true)

            fetch(`${baseURL}/ms-loginusuario/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    correo_electronico: values.email,
                    contrasenia: values.password,
                    recordar: values.remember,
                }),
            }).then(x => x.json())
                .then(x => {
                    if (x.success) {
                        //Guardo el token y sesión en LS
                        window.localStorage.setItem('token', x.data.token)
                        window.localStorage.setItem('session', x.data.session)
                        setLoading(false)
                        navigate('/inicio', { replace: true })
                    } else {
                        setLoading(false)
                        mostrarAlertaError(x.data.message)
                    }
                });
        }
    })

    return (
        <>
            {/* background de inicio de sesión */}
            <LoginBG />

            <div className="w-screen h-screen flex flex-col justify-center lg:flex-row lg:justify-evenly items-center absolute top-0">

                <img src="logos_figaruz/logoFigaruzBlanco.png" className='w-0 h-0 lg:w-[300px] lg:h-[300px] xl:w-[350px] xl:h-[350px]' alt='logo_figaruz' />

                <form className="w-11/12 sm:w-8/12 md:w-7/12 lg:w-5/12 xl:w-[29vw] xl:h-[60vh] backdrop-blur-md bg-white/30 rounded-xl" onSubmit={formLogin.handleSubmit}>

                    <div className='flex flex-col h-full w-full p-6 justify-evenly xl:px-6'>

                        <Link className='self-end my-2 text-[#0B2152]' to='/'>
                            <XCircle size={34} />
                        </Link>

                        <h1 className='font-bold text-[#0B2152] text-4xl xl:text-5xl text-center'>Inicia sesión</h1>
                        <div className='flex flex-col mt-10'>
                            <label className="font-bold text-[#0B2152]">Ingresa tu correo electrónico</label>
                            <input
                                placeholder="correo@dominio.com"
                                type="text"
                                name="email"
                                onChange={formLogin.handleChange}
                                value={formLogin.values.email}
                                id="username"
                                className="bg-transparent border-2 border-[#0B2152] placeholder-[#0B2152] rounded-md focus:ring-[#0B2152] focus:border-[#0B2152] focus:bg-transparent active:bg-transparent"
                            />
                            {formLogin.touched.email && formLogin.errors.email ? <div className=" text-red-700 text-sm font-semibold">{formLogin.errors.email}</div> : null}
                        </div>

                        <div className='flex flex-col'>
                            <label className="font-bold text-[#0B2152]">Ingresa tu contraseña</label>
                            <input
                                placeholder="********"
                                type="password"
                                name="password"
                                onChange={formLogin.handleChange}
                                value={formLogin.values.password}
                                id="password"
                                className="bg-transparent border-2 border-[#0B2152] placeholder-[#0B2152] rounded-md focus:ring-[#0B2152] focus:border-[#0B2152] focus:bg-transparent active:bg-transparent"
                            />
                            {formLogin.touched.password && formLogin.errors.password ? <div className=" text-red-700 text-sm font-semibold">{formLogin.errors.password}</div> : null}

                            <div className="flex items-center my-2">
                                <input
                                    value={formLogin.values.remember}
                                    onChange={formLogin.handleChange}
                                    className="appearance-none h-4 w-4 border-2 border-[#0B2152] rounded-sm bg-transparent focus:outline-none transition duration-200 align-top float-left mr-2 cursor-pointer"
                                    type="checkbox"
                                />
                                <label className="text-sm text-[#0B2152]">
                                    Recordarme
                                </label>
                            </div>
                        </div>

                        <div className='flex flex-col'>
                            <Button type='submit' loading={loading} label='Iniciar sesión' className='L-BLogin w-full transition duration-300 ease-out hover:ease-in' />
                            <p className='text-sm text-center'>¿Olvidaste tu contraseña?</p>
                            <Link to='/reestablecer_contrasenia' className="w-auto text-[#173A73] text-sm px-5 font-bold text-center hover:text-[#223f6e] transition duration-300 ease-in-out">Restablecela acá</Link>
                        </div>
                    </div>
                </form>
            </div >

        </>

    )
}

export default Login
