import { PencilSquare, People, PersonAdd, Star, XCircle } from "react-bootstrap-icons"
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useState } from "react";
 

const DialogPerfil = () => {
    const [value, setValue] = useState('');
    return(
        <div className="flex">
            <div className="flex flex-col w-[700px]">
                <div className="flex flex-col items-center justify-center">
                    <img src='/inicio/perfil.jpg' alt='foto perfil' className='rounded-full w-[150px]'></img>
                    <h1 className="text-[36px] text-[#173A73] font-semibold">
                        Catalina Milla
                    </h1>
                    <div className="flex flex-row text-black">
                        <div className="mx-2">
                            <div className="flex flex-row justify-center ">
                                <People size="24" />
                                <span className="font-semibold">97</span>
                            </div>
                            <h1 className="text-[14px] font-semibold">Clientes</h1>
                        </div>
                        <div className="mx-2">
                            <div className="flex flex-row justify-center">
                                <PersonAdd size="24" />
                                <span className="font-semibold">30</span>
                            </div>
                            <h1 className="text-[14px] font-semibold">Contactos</h1>
                        </div>

                        <div className="mx-2">
                            <div className="flex flex-row justify-center font-semibold">
                                <Star size="24" />

                                <span className="font-semibold">4.5</span>
                            </div>
                            <h1 className="text-[14px] font-semibold">Rating</h1>
                        </div>
                    </div>
                </div>
                <h1 className="text-[28px] text-[#173A73] font-semibold mt-[78px]">
                    Datos Personales
                </h1>
                <div className="flex flex-col mr-20 mt-4">
                    <div className="field flex col-12">
                        <span className="p-float-label mr-4 mt-6 text-[#173A73]">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className='w-60 border text-[#173A73] border-[#173A73]'/>
                            <label htmlFor="inputtext" className="input1">Nombre</label>
                        </span>
                        <span className="p-float-label mt-6">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className='w-96' />
                            <label htmlFor="inputtext" className="input1">Apellido</label>
                        </span>
                    </div>
                    <div className="mt-6">
                        <span className="p-float-label">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className='w-[640px]' />
                            <label htmlFor="inputtext" className="input1">Correo Electrónico</label>
                        </span>
                    </div>
                    <div className="flex mt-6">
                        <span className="p-float-label mr-4">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className='w-60' />
                            <label htmlFor="inputtext" className="input1">Selecciona tu País</label>
                        </span>
                        <div className="flex">
                            <span className="p-float-label mr-4">
                                <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className='w-20' disabled />
                                <label htmlFor="inputtext" className="input1">+56</label>
                            </span>
                            <span className="p-float-label">
                                <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className=' w-72 ' />
                                <label htmlFor="inputtext" className="input1">Telefono</label>
                            </span>
                        </div>
                    </div>
                    <div className="flex mt-6">
                        <span className="p-float-label mr-4">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className=' w-60 ' />
                            <label htmlFor="inputtext" className="input1">Selecciona tu región</label>
                        </span>
                        <span className="p-float-label">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className=' w-96 ' />
                            <label htmlFor="inputtext" className="input1">Selecciona tu ciudad</label>
                        </span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col">
                <div className="-mt-2">
                    <h1 className="text-[28px] font-semibold text-[#173A73]">
                        Biografía
                    </h1>
                    <h2 className="text-black text-[16px] font-semibold flex justify-center">
                        Realizo evaluaciones, diagnósticos y tratamiento en trastorno específico del lenguaje, voz y habla en niños adolescentes y adultos. Atención a niños con trastorno específico del lenguaje, trastorno del espectro autista, apoyo diferencial en lecto-escritura. Atención adultos o jóvenes con: Disfonía, Nódulos vocálicos Pólipos.
                    </h2>
                </div>
                <div className="mt-">
                    <h1 className="text-[28px] text-[#173A73] font-semibold ">
                        Membresía
                    </h1>
                    <h2 className="text-black font-semibold text-[22px]">
                        Plan Pro  $00.000 Mensual
                    </h2>
                </div>
                <div>
                    <Button style={{background: "#FDDB9D", marginTop: "16px",marginRight: "18px", color: "#AE4906", width: "222px", borderColor: '#FDDB9D'}} className='justify-center'>
                        <XCircle className="text-[20px] mx-2"/>
                        Eliminar Cuenta
                    </Button>
                    <Button style={{background: "#F27F0C", marginTop: "16px", color: "#FFFFFF", width: "222px", borderColor: '#F27F0C'}} className='text-[22px] justify-center'>
                        <PencilSquare className="mx-2 text-[18px]"/>
                        Actualizar Plan
                    </Button>
                </div>
                <h1 className="text-[#173A73] text-[28px] font-semibold mt-4">
                    Datos Profesionales
                </h1>
                <div className="flex flex-col mt-2">
                    <div className="flex mt-6">
                        <span className="p-float-label mr-4">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className=' w-60 ' />
                            <label htmlFor="inputtext" className="input1">Profesion 1</label>
                        </span>
                        <span className="p-float-label">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className=' w-60' />
                            <label htmlFor="inputtext" className="input1">Especialidad</label>
                        </span>
                    </div>
                    <div className="flex flex-col mt-6">
                        <span className="p-float-label mr-4">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className='w-[498px]' />
                            <label htmlFor="inputtext" className="input1">Servicio 1</label>
                        </span>
                        <span className="p-float-label mr-4 mt-6">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className='w-[498px]' />
                            <label htmlFor="inputtext" className="input1">Servicio 2</label>
                        </span>
                        <span className="p-float-label mr-4 mt-6">
                            <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} className='w-[498px]' />
                            <label htmlFor="inputtext" className="input1">Servicio 3</label>
                        </span>
                    </div>

                </div>   
            </div>
           

        </div>
    )
}
export default DialogPerfil