import React from 'react'
import "../styles/agendaStyle.css"
import Diary from '../components/Agenda/Diary';
import Graficos from '../components/Agenda/Graficos';

const Agenda = () => {
  return (
    <div className='flex flex-col items-center h-full w-full mt-10'>
      <Graficos/>
      <Diary />
    </div>
  );
}

export default Agenda