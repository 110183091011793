import React from 'react'

const AboutSection4 = () => {
    return (
        <div className='flex flex-col justify-around items-center my-10 px-2 md:flex-row md:justify-center xl:mx-10'>
            <div className='flex flex-col lg:items-center md:w-7/12 gap-[30px]'>
                <h1 className='AS4-H1 text-[35px] text-center font-bold md:text-[45px] lg:w-7/12 md:text-left xl:text-[54px] xl:leading-tight xl:w-6/12 text-[#173A73]'>Una plataforma de fácil uso</h1>
                <p className='leading-[23px] text-center sm:px-5 font-light text-[20px] md:text-left lg:w-7/12 md:px-0 xl:w-6/12'>
                    Un diseño simple y limpio, que otorgara una
                    interacción fluida por parte de tus clientes.
                    Permitiéndoles centrarse en la entrega de
                    tus servicios.
                </p>
            </div>
            <img className='w-8/12 sm:w-5/12 sm:h-full' src='Landing_page/imgs_about/img_section4.png' alt='img_section4' />
        </div>
    )
}

export default AboutSection4