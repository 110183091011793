import React, { useEffect } from 'react'

import { CalendarWeek, List, PeopleFill, PersonFillAdd, StarFill } from 'react-bootstrap-icons'

const SidebarPerfil = ({ openSidebarRight, setOpenSidebarRight, openSidebarLeft }) => {

  //Escucha los cambios del otro sidebar
  useEffect(() => {
    openSidebarLeft ? setOpenSidebarRight(false) : setOpenSidebarRight(true)
  }, [openSidebarLeft])

  return ( 
    <div className="lg:flex h-screen shadowSidebarTwo">

      <div className={` ${openSidebarRight ? "w-72" : "w-24"} lg:h-screen lg:flex lg:flex-col lg:pt-8 duration-300`}>

        {/* Icono abrir cerrar */}
        <List
          size='30px'
          className={`lg:absolute lg:cursor-pointer duration-300 text-[#F27F0C] md:h-1 md:w-1 hidden lg:flex lg:h-8 lg:w-8 lg:right-8 lg:top-6 w-7 z-0 ${!openSidebarRight && "rotate-180"}`}
          onClick={() => setOpenSidebarRight(!openSidebarRight)}
        />

        <div className="flex flex-col  pb-10 lg:gap-x-4 mt-8 mx-3">
          <div className='flex items-center justify-center'>
          <img src='/inicio/perfil.jpg' alt='foto perfil' className='rounded-full w-[150px]'></img>
          </div>

          <div className={`${!openSidebarRight && "hidden"} flex flex-row mt-1`}>
            <div className='flex flex-col items-center font-bold mx-2'>
              <div className='flex'>
                <PeopleFill size={24} />
                0
              </div>
              <p>Clientes</p>
            </div>
            <div className='flex flex-col items-center font-bold mx-2'>
              <div className='flex'>
                <PersonFillAdd size={24} />
                0
              </div>
              <p>Contactos</p>
            </div>
            <div className='flex flex-col items-center font-bold mx-2'>
              <div className='flex'>
                <StarFill size={24} />
                0
              </div>
              <p>Rating</p>
            </div>

          </div>

          <div>
            <div className={`${!openSidebarRight && "hidden"} items-center duration-100 pt-4 px-2 text-[14px] font-medium text-center border-b pb-4 border-[#F27F0C]`}>
              Realizo evaluaciones, diagnósticos y tratamiento en trastorno específico del lenguaje, voz y habla en niños adolescentes y adultos.
              Atención a niños con trastorno específico del lenguaje,trastorno del espectro autista, apoyo diferencial en lecto-escritura.
              Atención adultos o jóvenes con: Disfonía, Nódulos vocálicos Pólipos.
            </div>
          </div>

          <div className={`${!openSidebarRight && "hidden"} flex flex-col h-full text-[#173A73] border-[#173A73] mt-4 items-center text-center justify-center mx-4 border-2 py-16 px-4 rounded-md`}>
            <CalendarWeek size={50} />
            <p className='mt-4'>
              Cuando tengas horas agendadas, se visualizarán aquí.
            </p>
          </div>
          </div>
        </div>
    </div>
  );
};

export default SidebarPerfil