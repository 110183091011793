const Terms = () => {
    return (
        <div className="flex flex-col items-center justify-center bg-white">
            <h1 className="text-[40px] mb-6 text-[#173A73] font-bold text-center">Términos y condiciones</h1>

            <div className="text-justify font-semibold text-slate-600 w-11/12">
                <h1 className="italic my-1 ">Última actualización: 27 de Agosto de 2022</h1>
                <h2>LEA ESTAS CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR LOS SERVICIOS.</h2>

                <div>
                    <p className="my-2">
                        Mediante su acceso y uso de los Servicios usted acuerda vincularse
                        jurídicamente por estas Condiciones, que establecen una relación
                        contractual entre usted y Figaruz. Si usted no acepta estas Condiciones,
                        no podrá acceder o usar los Servicios. Estas Condiciones sustituyen
                        expresamente los acuerdos o compromisos previos con usted. Figaruz
                        podrá poner fin de inmediato a estas Condiciones o cualquiera de los
                        Servicios respecto de usted o, en general, dejar de ofrecer o denegar el
                        acceso a los Servicios o cualquier parte de ellos, en cualquier momento
                        y por cualquier motivo.
                    </p>
                    <p className="my-2">
                        Se podrán aplicar condiciones suplementarias a determinados Servicios,
                        como políticas para un evento, una actividad o una promoción
                        particular, y dichas condiciones suplementarias se le comunicarán en
                        relación con los Servicios aplicables. Las condiciones suplementarias se
                        pueden establecer además de las Condiciones, y se considerarán una
                        parte de estas, para los fines de los Servicios aplicables. Las condiciones
                        suplementarias prevalecerán sobre las Condiciones en el caso de
                        conflicto con respecto a los Servicios aplicables.
                    </p>
                    <p className="my-2">
                        Figaruz podrá modificar las Condiciones relativas a los Servicios cuando
                        lo considere oportuno. Las modificaciones serán efectivas después de la
                        publicación por parte de Figaruz de dichas Condiciones actualizadas en
                        esta ubicación o las políticas modificadas o condiciones suplementarias
                        sobre el Servicio aplicable. Su acceso o uso continuado de los Servicios
                        después de dicha publicación constituye su consentimiento a vincularse
                        por las Condiciones y sus modificaciones.
                    </p>
                    <p className="my-2">
                        La recopilación y el uso que hacemos de la información personal en
                        relación con los Servicios es conforme se dispone en la Política de
                        privacidad de Figaruz, disponible en
                        <a className="underline underline-offset-2 text-blue-700 ml-1"
                            href="https://www.figaruz.com/" target="_blank"
                            rel="noreferrer">
                            https://www.figaruz.com/</a>.
                    </p>
                </div>

                <h1 className="my-3 text-2xl font-bold">Términos y condiciones</h1>

                <p className="my-2 font-bold">1. Relación contractual</p>
                <p>
                    Las presentes Condiciones de uso (“Condiciones”) regulan el acceso o
                    uso que usted haga, como persona, desde cualquier país del mundo
                    (excluidos los Estados Unidos y sus territorios y posesiones, así como
                    China Continental) de aplicaciones, páginas web, contenido, productos y
                    servicios (los “Servicios”) puestos a disposición por Figaruz una sociedad
                    por acciones constituida en Santiago, Chile.
                </p>

                <p className="my-3 font-bold">2. Los Servicios</p>
                <p className="my-2">Los Servicios constituyen una plataforma de tecnología que permite a
                    los usuarios de aplicaciones móviles de Figaruz o páginas web proporcionadas como parte de los Servicios
                    (cada una, una “Aplicación”) organizar, planear, agendar y prestar servicios profesionales con terceros
                    proveedores independientes de dichos servicios, incluidos terceros prestadores particulares independientes de
                    servicios profesionales conforme a un acuerdo con Figaruz o algunos
                    afiliados de Figaruz (“Terceros proveedores”). A no ser que Figaruz lo
                    acepte mediante un contrato separado por escrito con usted, los
                    Servicios se ponen a disposición solo para su uso personal, no
                    comercial. USTED RECONOCE QUE FIGARUZ NO PRESTA SERVICIOS
                    PROFESIONALES DE NINGÚN TIPO Y QUE DICHOS SERVICIOS DE
                    PROFESIONALES SE PRESTAN POR TERCEROS PRESTADORES
                    PARTICULARES INDEPENDIENTES, QUE NO ESTÁN EMPLEADOS POR
                    FIGARUZ NI POR NINGUNO DE SUS AFILIADOS.
                </p>

                <p className="my-3 font-bold">3. Licencia.</p>

                <p className="my-2">Sujeto al cumplimiento de estas Condiciones, Figaruz le otorga una
                    licencia limitada, no exclusiva, no sub licenciable, revocable, no
                    transferible para: (i) el acceso y uso de las Aplicaciones en su dispositivo
                    personal solo en relación con su uso de los Servicios; y (ii) el acceso y
                    uso de cualquier contenido, información y material relacionado que
                    pueda ponerse a disposición a través de los Servicios, en cada caso solo
                    para su uso personal, no comercial. Figaruz y sus licenciantes se
                    reservan cualquier derecho que no haya sido expresamente otorgado
                    por el presente.
                </p>

                <p className="my-3 font-bold">4. Restricciones.</p>

                <p className="my-2">Usted no podrá: (i) retirar cualquier nota de derechos de autor, marca
                    registrada u otra nota de propiedad de cualquier parte de los Servicios;
                    (ii) reproducir, modificar, preparar obras derivadas sobre los Servicios,
                    distribuir, licenciar, arrendar, revender, transferir, exhibir públicamente,
                    presentar públicamente, transmitir, retransmitir o explotar de otra
                    forma los Servicios, excepto como se permita expresamente por
                    Figaruz; (iii) descompilar, realizar ingeniería inversa o desmontar los
                    Servicios, excepto como se permita por la ley aplicable; (iv) enlazar,
                    reflejar o enmarcar cualquier parte de los Servicios; (v) causar o lanzar
                    cualquier programa o script con el objeto de extraer, indexar, analizar o
                    de otro modo realizar prospección de datos de cualquier parte de los
                    Servicios o sobrecargar o bloquear indebidamente la operación y/o
                    funcionalidad de cualquier aspecto de los Servicios; o (vi) intentar
                    obtener un acceso no autorizado o dañar cualquier aspecto de los
                    Servicios o sus sistemas o redes relacionados.
                </p>

                <p className="my-3 font-bold">Prestación de los Servicios.</p>
                <p className="my-2">e. Usted reconoce que partes de los Servicios podrán ponerse a
                    disposición bajo varias marcas u opciones de pedidos de Figaruz.
                    Asimismo, usted reconoce que los Servicios podrán ponerse a
                    disposición bajo dichas marcas u opciones de pedidos por o en relación
                    con: (i) ciertas subsidiarias o afiliados de Figaruz o (ii) Terceros
                    proveedores independientes.
                </p>

                <p className="my-3 font-bold">6. Servicios y contenido de Terceros.</p>

                <p className="my-2">Los Servicios podrán ponerse a disposición o ser accesible en relación
                    con servicios y contenido de terceros (incluida la publicidad) que Figaruz
                    no controle. Usted reconoce que podrán ser de aplicación diferentes
                    condiciones y políticas de privacidad al uso que haga de dichos servicios
                    y contenido de terceros. Figaruz no respalda dichos servicios y
                    contenido de terceros y en ningún caso Figaruz será responsable de
                    cualquier producto o servicio de dichos terceros proveedores.
                    Adicionalmente, Apple Inc., Google, Inc., Microsoft Corporation o
                    BlackBerry Limited y/o sus correspondientes subsidiarias o afiliados
                    internacionales serán terceros beneficiarios en este contrato si usted
                    accede a los Servicios utilizando Aplicaciones desarrolladas para
                    dispositivos móviles con sistema iOS, Android, Microsoft Windows,
                    respectivamente. Estos terceros beneficiarios no son parte de este
                    contrato y no son responsables de la prestación o apoyo de los Servicios
                    de ninguna manera. Su acceso a los Servicios utilizando estos
                    dispositivos se sujeta a las condiciones establecidas en las condiciones
                    de servicio de terceros beneficiarios aplicables.
                </p>

                <p className="my-3 font-bold">7. Titularidad.</p>

                <p className="my-2">Los Servicios y todos los derechos relativos a estos son y permanecerán
                    de la propiedad de Figaruz o de sus licenciantes. Ninguna de estas
                    Condiciones ni su uso de los Servicios le transfieren u otorgan ningún
                    derecho: (i) sobre o en relación con los Servicios, excepto en cuanto a la
                    licencia limitada otorgada anteriormente; o bien (ii) a utilizar o
                    mencionar en cualquier modo a los nombres de empresa, logotipos,
                    nombres de producto y servicio, marcas comerciales o marcas de
                    servicio de Figaruz o de sus licenciantes.
                </p>

                <p className="my-2 font-bold">8. Cuentas de usuario.</p>

                <p className="my-2">
                    Con el fin de usar la mayor parte de los aspectos de los Servicios, usted
                    debe registrarse y mantener activa una cuenta personal de usuario de
                    los Servicios (“Cuenta”). Para obtener una Cuenta debe tener como
                    mínimo 18 años, o tener la mayoría de edad legal en su jurisdicción (si
                    es distinta a los 18 años). El registro de la cuenta le requiere que
                    comunique a Figaruz determinada información personal, como su
                    nombre, dirección, número de teléfono móvil, así como por lo menos
                    un método de pago válido (bien una tarjeta de crédito o bien un socio
                    de pago aceptado). Usted se compromete a mantener la información en
                    su Cuenta de forma exacta, completa y actualizada. Si no mantiene la
                    información de Cuenta de forma exacta, completa y actualizada, incluso
                    el tener un método de pago inválido o que haya vencido, podrá resultar
                    en su imposibilidad para acceder y utilizar los Servicios o en la
                    resolución por parte de Figaruz de esta Condiciones. Usted es responsable de toda
                    la actividad que ocurre en su Cuenta y se
                    compromete a mantener en todo momento de forma segura y secreta
                    el nombre de usuario y la contraseña de su Cuenta. A menos que
                    Figaruz permita otra cosa por escrito, usted solo puede poseer una
                    Cuenta.
                </p>

                <p className="my-3 font-bold">9. Requisitos y conducta del usuario.</p>

                <p className="my-2">
                    El Servicio no está disponible para el uso de personas menores de 18
                    años. Usted no podrá autorizar a terceros a utilizar su Cuenta, asimismo
                    no podrá permitir a personas menores de 18 años que reciban servicios
                    a menos que aquellos sean acompañados por usted. No podrá ceder o
                    transferir de otro modo su Cuenta a cualquier otra persona o entidad.
                    Usted acuerda cumplir con todas las leyes aplicables al utilizar los
                    Servicios y solo podrá utilizar los Servicios con fines legítimos. En el uso
                    de los Servicios, usted no causará estorbos, molestias, incomodidades o
                    daños a usuarios de nuestra comunidad, al Tercero proveedor como a
                    cualquier otra parte. En algunos casos, se le podrá requerir que facilite
                    un documento de identidad u otro elemento de verificación de
                    identidad para el acceso o uso de los Servicios, y usted acepta que se le
                    podrá denegar el acceso o uso de los Servicios si se niega a facilitar el
                    documento de identidad o el elemento de verificación de identidad.
                </p>

                <p className="my-3 font-bold">10. Mensajes de texto y correos electrónicos.</p>

                <p className="my-2">
                    Al crear una Cuenta, usted acepta que los Servicios le puedan enviar
                    mensajes de texto informativos (SMS) o correos electrónicos como
                    parte de la actividad comercial normal de su uso de los Servicios. Usted
                    podrá solicitar la no recepción de estos mensajes informativos de
                    Figaruz en cualquier momento enviando un correo electrónico a
                    <a className="underline underline-offset-2 text-blue-700 ml-1"
                        href="mailto:contacto@figaruz.com" target="_blank" rel="noreferrer">
                        contacto@figaruz.com</a> indicando que no desea recibir más dichos mensajes,
                    junto con el número de teléfono del dispositivo móvil que
                    recibe los mensajes. Usted reconoce que solicitar la no recepción de
                    mensajes de texto informativos podrá afectar al uso que usted haga de
                    los Servicios.
                </p>

                <p className="my-3 font-bold">11. Códigos promocionales.</p>

                <p className="my-2">
                    Figaruz podrá, a su sola discreción, crear códigos promocionales que
                    podrán ser canjeados por crédito de Cuenta u otros elementos o
                    beneficios relacionados con los Servicios y/o los servicios de un Tercero
                    proveedor, con sujeción a cualquier condición adicional que Figaruz
                    establezca sobre la base de cada código promocional (“Códigos
                    promocionales”). Usted acuerda que los Códigos promocionales: (i)
                    deben usarse para la audiencia y el propósito deseado, y de manera
                    lícita; (ii) no podrán duplicarse, venderse o transferirse de ninguna
                    manera, o ponerse a disposición del público general (tanto si se
                    publican en un foro público como de otra forma), a menos que sea con
                    el permiso de Figaruz; (iii) podrán ser invalidados por Figaruz en
                    cualquier momento por cualquier motivo sin responsabilidad para
                    Figaruz; (iv) podrán usarse solo conforme a las condiciones específicas
                    que Figaruz establezca para dicho Código promocional; (v) no son
                    válidos como efectivo; y (vi) podrán caducar antes de que usted los
                    utilice. Figaruz se reserva el derecho de retener o deducir el crédito u
                    otros elementos o beneficios obtenidos a través de la utilización de los
                    Códigos promocionales por usted o cualquier otro usuario en el caso de
                    que Figaruz determine o crea que el uso o el canje de los Códigos
                    promocionales fue de modo erróneo, fraudulento, ilegal o infringiendo
                    las condiciones del Código promocional o las presentes Condiciones.
                </p>

                <p className="my-3 font-bold">12. Contenido proporcionado por el Usuario.</p>

                <p className="my-2">

                    Figaruz podrá, a su sola discreción, permitirle cuando considere
                    oportuno, que envíe, cargue, publique o de otro modo ponga a
                    disposición de Figaruz a través de los Servicios contenido e información
                    de texto, audio y/o visual, incluidos comentarios y opiniones relativos a
                    los Servicios, iniciación de peticiones de apoyo, así como presentación
                    de admisiones para competiciones y promociones (“Contenido de
                    usuario”). Todo Contenido de usuario facilitado por usted seguirá
                    siendo de su propiedad. No obstante, al proporcionar Contenido de
                    usuario a Figaruz usted otorga una licencia mundial, perpetua,
                    irrevocable, transferible, libre de regalías, con derecho a sublicenciar,
                    usar, copiar, modificar, crear obras derivadas, distribuir, exhibir
                    públicamente, presentar públicamente o de otro modo explotar de
                    cualquier manera dicho Contenido de usuario en todos los formatos y
                    canales de distribución, conocidos ahora o ideados en un futuro
                    (incluidos en relación con los Servicios y el negocio de Figaruz y en sitios
                    y servicios de terceros), sin más aviso o consentimiento de usted y sin
                    requerirse el pago a usted o a cualquier otra persona o entidad.
                </p>
                <p>

                    Usted declara y garantiza que: (i) es el único y exclusivo propietario de
                    todo el Contenido de usuario o que tiene todos los derechos, licencias,
                    consentimientos y permisos necesarios para otorgar a Figaruz la licencia
                    al Contenido de usuario como establecido anteriormente; y (ii) ni el
                    Contenido de usuario ni su presentación, carga, publicación o puesta a
                    disposición de otro modo de dicho Contenido de usuario, ni el uso por
                    parte de Figaruz del Contenido de usuario como está aquí permitido,
                    infringirán, malversarán o violarán la propiedad intelectual o los
                    derechos de propiedad de un tercero o los derechos de publicidad o
                    privacidad o resultarán en la violación de cualquier ley o reglamento
                    aplicable. Usted acuerda no proporcionar Contenido de usuario que sea
                    difamatorio, calumnioso, odioso, violento, obsceno, pornográfico, ilícito
                    o de otro modo ofensivo, como determine Figaruz, a su sola discreción,
                    tanto si dicho material pueda estar protegido o no por la ley. Figaruz
                    podrá, a su sola discreción y en cualquier momento y por cualquier
                    motivo, sin avisarle previamente, revisar, controlar o eliminar
                    Contenido de usuario, pero sin estar obligada a ello.
                </p>

                <p className="my-3 font-bold">13. Acceso a la red y dispositivos.</p>

                <p className="my-2">
                    Usted es responsable de obtener el acceso a la red de datos necesario
                    para utilizar los Servicios. Podrán aplicarse las tarifas y tasas de datos y
                    mensajes de su red móvil si usted accede o utiliza los Servicios desde un
                    dispositivo inalámbrico y usted será responsable de dichas tarifas y
                    tasas. Usted es responsable de adquirir y actualizar el hardware
                    compatible o los dispositivos necesarios para acceder y utilizar los
                    Servicios y Aplicaciones y cualquier actualización de estos. Figaruz no
                    garantiza que los Servicios, o cualquier parte de estos, funcionen en
                    cualquier hardware o dispositivo particular. Además, los Servicios
                    podrán ser objeto de disfunciones o retrasos inherentes al uso de
                    Internet y de las comunicaciones electrónicas.
                </p>

                <p className="my-3 font-bold">14. Pagos.</p>

                <p className="my-2">
                    Usted entiende que el uso de los Servicios puede derivar en cargos por
                    los servicios o bienes que reciba de un Tercer proveedor (“Cargos”).
                    Después de que haya recibido los servicios u obtenido los bienes
                    mediante el uso de los Servicios, Figaruz facilitará su pago de los Cargos
                    aplicables en nombre del Tercero proveedor como agente de cobro
                    limitado del Tercero proveedor. El pago de los Cargos de dicha manera
                    se considerará como el pago efectuado directamente por usted al
                    Tercero proveedor. Los Cargos incluirán los impuestos aplicables cuando
                    se requiera por ley. Los Cargos pagados por usted son definitivos y no
                    reembolsables, a menos que Figaruz determine lo contrario. Usted
                    conserva el derecho de solicitar Cargos más bajos de un Tercero
                    proveedor para los servicios o bienes recibidos por usted de dicho
                    Tercero proveedor en el momento en que reciba dichos servicios o
                    bienes. Figaruz responderá en consecuencia a cualquier solicitud de un
                    Tercero proveedor para modificar los Cargos por un servicio o bien en
                    particular. Todos los Cargos son pagaderos inmediatamente al
                    momento de reservar o contratar un servicio del Tercero, y el pago a
                    este se facilitará por Figaruz utilizando al método de pago preferido
                    indicado en su Cuenta, después de ello Figaruz le enviará un recibo por
                    correo electrónico. Si se determina que el método de pago de su Cuenta
                    principal ha caducado, es inválido o de otro modo no sirve para
                    cobrarle, usted acepta que Figaruz, como agente de cobro limitado del
                    Tercero proveedor, utilice un método de pago secundario en su Cuenta,
                    si estuviera disponible.
                </p>

                <p className="my-2">
                    Figaruz, en cualquier momento y a su sola discreción, se reserva el
                    derecho de establecer, eliminar y/o revisar los Cargos para alguno o
                    todos los servicios o bienes obtenidos a través del uso de los Servicios.
                    Figaruz podrá, cuando lo considere oportuno, proporcionar a
                    determinados usuarios ofertas promocionales y descuentos que pueden
                    resultar en el cobro de diferentes importes por estos o similares
                    servicios o bienes obtenidos a través del uso de los Servicios, y usted
                    acepta que dichas ofertas promocionales y descuentos, a menos que
                    también se pongan a su disposición, no se tendrán en cuenta en el uso
                    por su parte de los Servicios o los Cargos aplicados a usted. Usted podrá
                    optar por cancelar su solicitud para los servicios o bienes de un Tercero
                    proveedor en cualquier momento antes de la prestación del servicio, en
                    cuyo caso se le podrá cobrar una tasa de cancelación de hasta un 50%
                    de la prestación del servicio acordado, además y en consecuencia de lo
                    anterior, si un Tercero cancela su servicio ya agendado por usted
                    Figaruz le podrá cobrar una tasa de cancelación de hasta un 50% de la
                    prestación del servicio acordado, para así costear los costos de comisión
                    de pasarelas de pago y de esta forma proceder a la devolución de su
                    dinero.
                </p>

                <p>
                    Esta estructura de pago está destinada para compensar en una parte al
                    Tercero proveedor por los servicios o bienes proporcionados, una
                    segunda parte las pasarelas de pago y a una tercera parte al costo y
                    mantención de la plataforma. Cualquier manifestación por parte de
                    Figaruz (en la página web de Figaruz o en los materiales de marketing de
                    Figaruz) en el sentido de que dar una propina es “voluntario”, “no
                    requerido”, y/o “incluido” en los pagos que realiza para los servicios o
                    bienes proporcionados no pretende sugerir que Figaruz proporciona
                    importes adicionales, aparte de los descritos anteriormente, al Tercero
                    proveedor. Usted entiende y acepta que, mientras es libre de
                    proporcionar un pago adicional como gratificación a cualquier Tercero
                    proveedor que le proporcione servicios o bienes obtenidos mediante el
                    Servicios, no tiene obligación de ello. Las gratificaciones son voluntarias.
                    Después de que haya recibido los bienes o servicios obtenidos a través
                    del Servicio, tendrá la oportunidad de calificar su experiencia y dejar
                    comentarios adicionales sobre el Tercero proveedor.
                </p>

                <p className="my-3 font-bold">15. Costos y penalizaciones por cancelación de servicios ya agendados.</p>

                <p className="my-2">
                    Si usted como usuario cancela un servicio ya agendado y pagado a el
                    Tercero en el mes en curso y en una (1) primera instancia y decide
                    reagendar con el mismo Tercero, esta no tendrá costo ni penalización a
                    usted. Si usted como usuario cancela un servicio ya agendado y pagado
                    a el Tercero en el mes en curso y en una (1) primera instancia y decide
                    reagendar con otro Tercero, esta tendrá un costo de penalización a
                    usted de un 15% sobre el costo total del servicio. Si usted como usuario
                    cancela un servicio ya agendado y pagado a el Tercero en una (2)
                    segunda instancia y decide reagendar con el mismo Tercero u otro en el
                    mes en curso, esta tendrá un costo de penalización a usted de un 15%
                    sobre el costo total del servicio. Si usted como usuario cancela un
                    servicio ya agendado y pagado a el Tercero en una (3) tercera instancia
                    en el mes en curso y decide reagendar con el mismo Tercero u otro,
                    esta tendrá un costo de penalización a usted de un 25% sobre el costo
                    total del servicio, además, recibirá una mala calificación y evaluación
                    mostrada en su perfil de usuario descrita como: usuario con una alta
                    tasa de cancelación de servicios. Si usted como usuario cancela un
                    servicio ya agendado y pagado a el Tercero en una cuarta instancia en el
                    mes en curso esta tendrá un costo de penalización a usted de un 50%
                    sobre el costo total del servicio, además, recibirá una mala calificación y
                    evaluación mostrada en su perfil de usuario descrita como: usuario con
                    una alta tasa de cancelación de servicios y se procederá a suspender su
                    cuenta por un periodo de 15 días. En cualquiera de las instancias
                    anteriores usted como usuario, y posterior a la cancelación de un
                    servicio ya sea por usted o por un tercero puede solicitar la devolución
                    de su dinero o saldos correspondientes mediante cualquiera de las
                    herramientas que suministrara Figaruz para la gestión de esta.
                </p>

                <p className="my-2">
                    Si usted como Tercero cancela un servicio ya agendado y pagado por
                    nuestro usuario en el mes en curso y en una (1) primera instancia
                    tendrá un costo de penalización a usted de un 15% sobre el costo total
                    del servicio pactado, este monto será descontado de su pago semanal
                    por servicios prestados.
                </p>

                <p className="my-2">
                    Si usted como Tercero cancela un servicio ya agendado y pagado por
                    nuestro usuario en el mes en curso y en una (2) segunda instancia
                    tendrá un costo de penalización a usted de un 25% sobre el costo total
                    del servicio pactado, este monto será descontado de su pago semanal
                    por servicios prestados. Además, recibirá una mala calificación y
                    evaluación mostrada en su perfil profesional descrita como: profesional
                    con una alta tasa de cancelación de servicios.
                </p>

                <p className="my-2">
                    Si usted como Tercero cancela un servicio ya agendado y pagado por
                    nuestro usuario en el mes en curso y en una (3) tercera instancia tendrá
                    un costo de penalización a usted de un 50% sobre el costo total del
                    servicio pactado, este monto será descontado de su pago semanal por
                    servicios prestados. Además, recibirá una mala calificación y evaluación
                    mostrada en su perfil profesional descrita como: profesional con una
                    alta tasa de cancelación de servicios y se procederá a suspender su
                    cuenta por un periodo de 15 días.
                </p>

                <p className="my-3 font-bold">16. De los Datos</p>

                <p className="my-2">
                    Figaruz recibe, recopila y almacena cualquier información que ingrese
                    en nuestro sitio web o nos proporciones de otra manera. Además,
                    recogemos la dirección de protocolo de Internet (IP) utilizada para
                    conectar tu computadora a Internet; datos de inicio de sesión, dirección
                    de correo electrónico, contraseña, información de la computadora y la
                    conexión y el historial de compras. Podemos usar herramientas de
                    software para medir y recopilar información de la sesión, incluidos los
                    tiempos de respuesta de la página, la duración de las visitas a
                    determinadas páginas, la información de interacción de la página y los
                    métodos utilizados para navegar fuera de la página. También
                    recopilamos información de identificación personal (incluidos nombres,
                    correo electrónico, contraseña, comunicaciones), detalles de pago
                    (incluida la información de la tarjeta de crédito), comentarios,
                    sugerencias, reseñas de productos, recomendaciones y perfil personal.
                </p>

                <p className="my-2">
                    Cuando realizas una transacción en nuestra aplicación o página web,
                    como parte del proceso, recopilamos información personal que nos
                    proporcionas, como tu nombre, dirección física y dirección de correo
                    electrónico. Tu información personal será utilizada solo para los motivos
                    específicos indicados anteriormente.
                </p>

                <p className="my-2">Recopilamos dicha información personal y no personal para los
                    siguientes propósitos:
                </p>

                <ul className="list-disc pl-5 mb-4" >
                    <li>Para proporcionar y operar los servicios.</li>
                    <li>Para proporcionar a nuestros usuarios asistencia continua al
                        cliente y soporte técnico.</li>
                    <li>Para poder contactar a nuestros visitantes y usuarios con
                        avisos generales y personalizados relacionados con el servicio y
                        mensajes promocionales.</li>
                    <li>Para crear datos estadísticos agregados y otra
                        información no personal agregada o deducida, que nosotros o
                        nuestros socios comerciales podamos utilizar para proporcionar
                        y mejorar nuestros servicios respectivos.</li>
                    <li>Para cumplir con las leyes y regulaciones aplicables.</li>
                </ul>

                <p className="my-2">
                    Nuestra aplicación está alojada en los servidores Microsoft Azure,
                    Microsoft nos proporciona la plataforma en línea que nos permite
                    venderte nuestros productos y servicios. Tus datos pueden almacenarse
                    a través del almacenamiento de datos de
                    <a className="underline underline-offset-2 text-blue-700 ml-1"
                        href="https://www.microsoft.com"
                        target="_blank"
                        rel="noreferrer">
                        Micrososoft.com
                    </a>,
                    las bases de datos y las aplicaciones generales de
                    <a className="underline underline-offset-2 text-blue-700 ml-1"
                        href="https://www.microsoft.com"
                        target="_blank"
                        rel="noreferrer">
                        Micrososoft.com
                    </a>, Ellos almacenan tus datos en servidores seguros detrás de un firewall.
                    Todas las pasarelas de pago directo ofrecidas por Figaruz y utilizadas por nuestra
                    compañía se adhieren a los estándares establecidos por PCI-DSS
                    administrados por el PCI Security Standards Council, que es un esfuerzo
                    conjunto de marcas como Visa, MasterCard, American Express y
                    Discover. Los requisitos de PCI-DSS ayudan a garantizar el manejo
                    seguro de la información de la tarjeta de crédito por parte de nuestra
                    tienda y sus proveedores de servicios.
                </p>

                <p className="my-2">
                    Podemos comunicarnos contigo para notificarte sobre tu cuenta, para
                    solucionar problemas con tu cuenta, resolver una disputa, cobrar tarifas
                    o dinero adeudado, para sondear tus opiniones a través de encuestas o
                    cuestionarios, para enviar actualizaciones sobre nuestra empresa, o
                    cuando sea necesario para contactarte para hacer cumplir nuestro
                    Acuerdo de usuario, las leyes nacionales aplicables y cualquier acuerdo
                    que podamos tener contigo. A estos efectos, podemos comunicarnos
                    contigo por correo electrónico, teléfono, mensajes de texto y correo
                    postal. Si no deseas que procesemos tus datos, contáctanos a
                    <a className="underline underline-offset-2 text-blue-700 ml-1"
                        href="mailto:contacto@figaruz.com"
                        target="_blank"
                        rel="noreferrer">
                        contacto@figaruz.com
                    </a>.
                </p>

                <p className="my-2">
                    Nos reservamos el derecho de modificar esta política de privacidad en
                    cualquier momento, por lo tanto, revísala con frecuencia. Los cambios y
                    aclaraciones tendrán efecto inmediatamente después de su publicación
                    en nuestra aplicación móvil. Si realizamos cambios sustanciales a esta
                    política, te notificaremos que se ha actualizado, para que sepas qué
                    información recopilamos, cómo la usamos y en qué circunstancias, si
                    corresponde, usamos o divulgamos la información.
                </p>

                <p className="my-2">
                    Si deseas: acceder, corregir, modificar o eliminar cualquier información
                    personal que tengamos sobre ti, estás invitado a contactarnos en
                    <a className="underline underline-offset-2 text-blue-700 ml-1"
                        href="mailto:contacto@figaruz.com"
                        target="_blank"
                        rel="noreferrer">
                        contacto@figaruz.com
                    </a>.
                </p>

                <p className="my-2">
                    Figaruz es una marca registrada que se encuentra regulada por la Ley N°
                    17.336 sobre Propiedad Intelectual de 2/10/1970 y sus posteriores
                    modificaciones. Figaruz funciona con los servicios de Microsoft Azure.
                </p>

                <p className="my-2">
                    Figaruz cuenta con con el respaldo tecnológico y comercial de ATX
                    Business Solutions México. Todos los derechos reservados.
                </p>

                <p className="my-2">
                    Última actualización: 27 de Agosto de 2022
                </p>
            </div>
        </div>
    )
}

export default Terms
