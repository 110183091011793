import { useState, useEffect } from 'react';

const UseProfesiones = () => {

    const [profesiones, setProfesiones] = useState([])
    const baseURL = process.env.REACT_APP_API_BASE_URL; //URL base
    const endpoint = `${baseURL}/ms-loginusuario/profesiones`;

    const showData = async () => {
        const response = await fetch(endpoint)
        const data = await response.json()
        setProfesiones(data)
    };

    useEffect(() => {
        showData()
    }, []);

    return { profesiones };
}

export default UseProfesiones