import { Dialog } from 'primereact/dialog';
import { useState } from "react";
import Terms from "./Terms";

const Footer = () => {

  //Abre o cierra el popUp de términos y condiciones
  const [show, setShow] = useState(false)

  return (
    <footer className="bg-[#E0E0F0] flex flex-col gap-10 justify-end pb-10 lg:h-[543px] lg:-mt-56 ">

      <div className="flex flex-col md:flex-row mt-6 justify-center items-center md:px-5 lg:gap-[48px] lg:px-[100px] border-b-4 xl:mx-0 pb-6 border-white">
        <img className="w-1/2 sm:w-4/12 md:w-3/12 lg:w-[184px] lg:h-[50px]" src="logos_figaruz/logo_figaruz_footer.png" alt="logo_figaruz_footer" />
        <p className='F-p px-5 mt-8 xl:w-8/12 xl:text-[14px]'>
          Figaruz® funciona con los servicios de Microsoft Azure. Cuenta con el respaldo tecnológico y comercial
          de ATX Business Solutions México. Nuestro domicilio comercial se encuentra en Broad Street N°651 Suite 201,
          ciudad de Middletown Delaware condado de New Castle, Estados Unidos.
        </p>
      </div>

      <div className="flex flex-col lg:flex-row justify-center gap-10">

        <div className="flex flex-col gap-[22px]">
          <h1 className="F-H1 mx-3 text-center text-[14px] lg:text-[16px] xl:text-[20px]">Figaruz® y Figaruz® Society son una marca registrada. Todos los derechos reservados.</h1>
          <div className="flex justify-center gap-10">
            <div onClick={() => window.open('mailto:contacto@figaruz.com?subject=Contacto')} className="font-bold cursor-pointer">Contacto</div>
            <div onClick={() => setShow(true)} className="font-bold cursor-pointer">Términos y condiciones</div>

            <Dialog visible={show} style={{ width: '80vw' }} onHide={() => setShow(false)}>
              <Terms/>
            </Dialog>
          </div>
        </div>

        <div className="flex justify-center lg:justify-start gap-[20px]">
          <a href="https://www.facebook.com/Figaruz/" target="_blank" rel="noreferrer">
            <img className="w-[40px]" src="Landing_page/iconos/facebook_icon.svg" alt="logo_facebook" />
          </a>
          <a href="https://www.youtube.com/@figaruz" target="_blank" rel="noreferrer">
            <img className="w-[40px]" src="Landing_page/iconos/youtube_icon.svg" alt="logo_youtube" />
          </a>
          <a href="https://cl.linkedin.com/company/figaruz" target="_blank" rel="noreferrer">
            <img className="w-[40px]" src="Landing_page/iconos/linkedin_icon.svg" alt="logo_linkedin" />
          </a>
          <a href="https://www.instagram.com/figaruz/" target="_blank" rel="noreferrer">
            <img className="w-[40px]" src="Landing_page/iconos/instagram_icon.svg" alt="logo_instagram" />
          </a>
          <a href="https://twitter.com/Figaruz/" target="_blank" rel="noreferrer">
            <img className="w-[40px]" src="Landing_page/iconos/twitter_icon.svg" alt="logo_twitter" />
          </a>
          <a href="https://www.tiktok.com/@figaruz" target="_blank" rel="noreferrer">
            <img className="w-[40px]" src="Landing_page/iconos/tiktok_icon.svg" alt="logo_tiktok" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
