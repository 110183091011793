import { Link } from "react-router-dom"

//Validación +18
export const calcular_edad = (dateBirth) => {

    let hoy = new Date();
    var cumpleanios = new Date(dateBirth);
    var edad = hoy.getFullYear() - cumpleanios.getFullYear();
    let m = hoy.getMonth() - cumpleanios.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanios.getDate())) {
        edad--;
    }

    return edad;
}

/*Debido a que la etiqueta Link es ocupada por la librería
react-scroll, se crea este helper que ayuda a redireccionar a través
de react-router-dom*/
// export const linkLogin = () => {
//     return <Link to="/iniciar-sesion">Iniciar sesión</Link>
// }
