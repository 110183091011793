import React from 'react'

const CardCustomers = () => {

  const customersList = [
    { image: '/Landing_page/clientes/woman2.jpg', name: 'José Peréz', nameService: 'Tarot', date: '06-06-1969' },
    { image: '/Landing_page/clientes/woman2.jpg', name: 'Rosa Rojas', nameService: 'Tarot', date: '06-06-1969' },
    { image: '/Landing_page/clientes/woman2.jpg', name: 'Gonzalo Gonzales', nameService: 'Tarot', date: '06-06-1969' },
    { image: '/Landing_page/clientes/woman2.jpg', name: 'Rodrigo Rodriguez', nameService: 'Tarot', date: '06-06-1969' },
    { image: '/Landing_page/clientes/woman2.jpg', name: 'Alan Alamos', nameService: 'Tarot', date: '06-06-1969' }
  ]
  // crear constante que contenga la lista de todas las cards para poder recorrerlo con un for o un map grid grid-cols-5 gap-3

  return (
    <div className="flex justify-around">
      {customersList.map((customer, index) => (
        <div className="w-56 h-72 rounded-lg shadow-lg m-4" key={index}>
          <img className="rounded-t-lg" src={customer.image} alt='woman' />
          <div className="font-medium w-full flex flex-col items-center pt-4 space-y-1">
            <h1 className="text-[22px] text-[#0B2152] w-10/12">{customer.name}</h1>
            <h3 className="text-[16px] text-[#f27f0c] w-10/12">{customer.nameService}</h3>
            <h3 className="text-[16px] text-[#0B2152] w-10/12">{customer.date}</h3>
          </div>
        </div>
    ))}

    </div>
  )
}

export default CardCustomers