import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import Inicio from '../private/Inicio'
import Sidebar from '../components/Inicio/Sidebar'
import Sidebartwo from '../components/Inicio/Sidebar2'
import Agenda from "../private/Agenda"
import ButtonSm from '../components/Inicio/ButtonSm'
import Saldo from '../components/Saldos/Saldos'
import Servicios from '../private/Servicios'
import Perfil from '../private/Perfil'
import SidebarPerfil from '../components/Perfil/SidebarPerfil'
import ResultSearch from '../components/Busqueda/ResultSearch'
import { SearchBar } from '../components/Busqueda/SearchBar'
import SidebarAgenda from '../components/Agenda/SidebarAgenda'
import Clientes from '../private/Clientes'
import Ayuda from '../private/Ayuda'

const HomeRouter = () => {

    //Manejo de apertura y cierre de sidebars
    const [openSidebarLeft, setOpenSidebarLeft] = useState(true);
    const [openSidebarRight, setOpenSidebarRight] = useState(false);
    const [componente, setComponente] = useState('inicio');
    const [showCategories, setShowCategories] = useState(false)

    /*Evalua en que componente se encuentra el usuario
    para desplegar el sidebar correspondiente*/
    const evaluarComponente = (opcion) => {
  
        switch (opcion) {
            case 'inicio':
                return <Sidebartwo className='hidden lg:flex'
                    openSidebarRight={openSidebarRight}
                    setOpenSidebarRight={setOpenSidebarRight}
                    openSidebarLeft={openSidebarLeft}
                />
            case 'Agenda':
                return <SidebarAgenda />
            case 'Servicios':
            case 'Clientes':
            case 'Saldos':
                return <SidebarPerfil
                    openSidebarRight={openSidebarRight}
                    setOpenSidebarRight={setOpenSidebarRight}
                    openSidebarLeft={openSidebarLeft}
                />
            case '':
                return null;
            default:
                return null;
        }
    }

    return (
        <div className="w-screen h-screen flex flex-col lg:flex-row lg:justify-between">
            <aside className='h-screen'>
                <Sidebar
                    openSidebarLeft={openSidebarLeft}
                    setOpenSidebarLeft={setOpenSidebarLeft}
                    openSidebarRight={openSidebarRight}
                    setComponente={setComponente}
                    showCategories={showCategories}
                    setShowCategories={setShowCategories}
                />
            </aside>

            <div className='flex flex-col col-span-3 my-4 items-center justify-center lg:hidden'>
                <SearchBar />
            </div>

            <main className={`scrollbarInicio overflow-y-auto w-full h-screen `}> 
                <Routes>
                    <Route path="/inicio" exact element={
                        <div className={`flex h-screen justify-between`}>
                            <div className={`scrollbarInicio overflow-y-auto justify-self-center w-full ease-in-out duration-500 ${showCategories && 'overlaySidebar'}`}>
                                <Inicio />
                            </div>
                            <div className='justify-self-end'>
                                {evaluarComponente(componente)}
                            </div>
                        </div>
                    } />
                    <Route path="/agenda" exact element={
                        <div className='flex h-screen justify-between'>
                            <div className={`scrollbarInicio overflow-y-auto justify-self-center w-full ${showCategories && 'overlaySidebar'}`}>
                                <Agenda />
                            </div>
                            <div className='justify-self-end'>
                                {evaluarComponente(componente)}
                            </div>
                        </div>
                    } />
                    <Route path="/saldos" exact element={
                        <div className='flex h-screen justify-between'>
                            <div className={`scrollbarInicio overflow-y-auto justify-self-center w-full ${showCategories && 'overlaySidebar'}`} >
                                <Saldo />
                            </div>
                            <div className='justify-self-end'>
                                {evaluarComponente(componente)}
                            </div>
                        </div>
                    } />
                    <Route path="/servicios" exact element={
                        <div className='flex h-screen justify-between'>
                            <div className={`scrollbarInicio overflow-y-auto justify-self-center w-full ${showCategories && 'overlaySidebar'}`}>
                                <Servicios />
                            </div>
                            <div className='justify-self-end'>
                                {evaluarComponente(componente)}
                            </div>
                        </div>
                    } />
                    <Route path='/Perfil' exact element={
                        <div className={`scrollbarInicio overflow-y-auto justify-self-center w-full ${showCategories && 'overlaySidebar'}`}>
                            <Perfil />
                        </div>
                    } />
                        

                    <Route path="/search/:option" exact element={
                    <div className={`scrollbarInicio overflow-y-auto justify-self-center w-full ${showCategories && 'overlaySidebar'}`}>
                        <ResultSearch />   
                    </div>}/>

                    <Route path="/clientes" exact element={
                        <div className='flex h-screen justify-between'>
                            <div className={`scrollbarInicio overflow-y-auto justify-self-center w-full ${showCategories && 'overlaySidebar'}`}>
                                <Clientes />
                            </div>
                            <div className='justify-self-end'>
                                {evaluarComponente(componente)}
                            </div>
                        </div>
                    } />

                    <Route path='/ayuda' exact element={
                        <div className={`scrollbarInicio overflow-y-auto justify-self-center w-full ${showCategories && 'overlaySidebar'}`}>
                            <Ayuda/>
                        </div>
                    }/>
                </Routes>
            </main>

            <div className='sticky bottom-0 w-screen flex lg:hidden'>
                <ButtonSm />
            </div>
        </div>
    )
}

export default HomeRouter