import React from 'react'

import { PeopleFill, PersonFillAdd, StarFill } from 'react-bootstrap-icons'
//  CalendarWeek, List,


const SidebarAgenda = () => {

    return (
        <div className="lg:flex justify-center w-[340px] h-screen shadowSidebarTwo bg-white">

            <div className='w-65 lg:flex lg:flex-col lg:mt-6 duration-300'>

                <div className="flex flex-col items-center pb-10 lg:gap-x-4 lg:items-center mx-3">

                    <img src='/inicio/perfil.jpg' alt='foto perfil' className='rounded-full w-[120px]'></img>

                    <div className='flex flex-row justify-center mt-1 border-b pb-4 border-[#F27F0C] w-[290px]'>
                        <div className='flex flex-col items-center font-bold mx-2'>
                            <div className='flex'>
                                <PeopleFill size={24} />
                                45
                            </div>
                            <p>Clientes</p>
                        </div>
                        <div className='flex flex-col items-center font-bold mx-2'>
                            <div className='flex'>
                                <PersonFillAdd size={24} />
                                15
                            </div>
                            <p>Contactos</p>
                        </div>
                        <div className='flex flex-col items-center font-bold mx-2'>
                            <div className='flex gap-1'>
                                <StarFill size={24} />
                                4.5
                            </div>
                            <p>Rating</p>
                        </div>
                    </div>

                    <div className='flex flex-col h-full items-center justify-center mt-4 gap-[10px]'>

                        <div className='font-medium self-start text-[#173A73] text-[22px] leading-[28px]'>
                            Lunes 5 Octubre
                        </div>

                        <div className='bg-white horasSidebar flex items-center w-[290px] cursor-pointer'>
                            <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-[#F27F0C]'>
                                <div>08:00</div>
                                <div>Hrs</div>

                            </div>
                            <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#F27F0C]'>
                                <div className='text-[22px] leading-[28px] font-medium'>Hora disponible</div>
                                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Nombre servicio</div>
                            </div>
                        </div>

                        <div className='bg-white horasSidebar flex items-center w-[290px] cursor-pointer'>
                            <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-[#F27F0C]'>
                                <div>09:00</div>
                                <div>Hrs</div>

                            </div>
                            <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#F27F0C]'>
                                <div className='text-[22px] leading-[28px] font-medium'>Hora disponible</div>
                                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Nombre servicio</div>
                            </div>
                        </div>

                        <div className='bg-[#D0E5F8] flex items-center w-[290px] cursor-pointer'>
                            <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-white bg-[#173A73]'>
                                <div>10:00</div>
                                <div>Hrs</div>
                            </div>
                            <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#173A73]'>
                                <div className='text-[22px] leading-[28px] font-medium'>Hector Sagredo</div>
                                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Fonoaudiología Adulto</div>
                            </div>
                        </div>

                        <div className='bg-white horasSidebar flex items-center w-[290px] cursor-pointer'>
                            <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-[#F27F0C]'>
                                <div>11:00</div>
                                <div>Hrs</div>

                            </div>
                            <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#F27F0C]'>
                                <div className='text-[22px] leading-[28px] font-medium'>Hora disponible</div>
                                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Nombre servicio</div>
                            </div>
                        </div>

                        <div className='bg-[#D0E5F8] flex items-center w-[290px] cursor-pointer'>
                            <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-white bg-[#173A73]'>
                                <div>12:00</div>
                                <div>Hrs</div>
                            </div>
                            <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#173A73]'>
                                <div className='text-[22px] leading-[28px] font-medium'>Victor Medina</div>
                                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Fonoaudiología Adulto</div>
                            </div>
                        </div>

                        <div className='bg-white horasDeshabilitadas flex items-center w-[290px] cursor-pointer'>
                            <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-[#79747E]'>
                                <div>13:00</div>
                                <div>Hrs</div>
                            </div>
                            <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#79747E]'>
                                <div className='text-[22px] leading-[28px] font-medium'>Hora deshabilitada</div>
                                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Nombre Servicio</div>
                            </div>
                        </div>
                        <div className='bg-white horasDeshabilitadas flex items-center w-[290px] cursor-pointer'>
                            <div className='flex flex-col justify-center text-[16px] leading-[24px] tracking-[0.5px] h-full w-[60px] text-center text-[#79747E]'>
                                <div>14:00</div>
                                <div>Hrs</div>
                            </div>
                            <div className='flex flex-col w-[240px] items-center justify-center py-[10px] px-[20px] text-[#79747E]'>
                                <div className='text-[22px] leading-[28px] font-medium'>Hora deshabilitada</div>
                                <div className='text-[14px] leading-[20px] tracking-[0.5px]'>Nombre Servicio</div>
                            </div>
                        </div>


                        <div className='w-[290px] h-[50px] rounded-[6px] bg-[#173A73] flex items-center justify-center font-medium tracking-[0.15px] text-[16px] leading-[24px] cursor-pointer text-white '>
                            Ver Más
                        </div>


                    </div>

                </div>
            </div>
        </div>
    );
}

export default SidebarAgenda