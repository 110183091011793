import React, { useState, useEffect } from 'react';
//import UseAdvancedSearch from '../../hooks/UseAdvancedSearch';
import Avatar from 'react-avatar';
import '../../styles/busquedaStyle.css'

// import { SearchBar } from './SearchBar';
import { PersonFill, StarFill } from 'react-bootstrap-icons'
import CustomAgenda from './diarySearch/CustomAgenda';
import BarraBusqueda from './BarraBusqueda';

const SearchFilter = () => {


  const [profession, setProfession] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [username, setUsername] = useState('');
  const [option, setOption] = useState(0);

  // const [filter, setFilter] = useState([]); //Filtro de especialidades.

  const [data, setData] = useState([]);
  //const [response, setResponse] = UseAdvancedSearch(profession, specialty, username, option);

  // const [show, setShow] = useState(false)
  const [appointments, setAppointments] = useState([]);

  //console.log(data)
  //console.log(appointments)

  //Evalua la opción de búsqueda.
  useEffect(() => {
    function evaluateOption() {
      if (profession !== '' && specialty === '' && username === '') {
        setOption(1)
      }
      else if (profession !== '' && specialty !== '' && username === '') {
        setOption(2)
      }
      else if (profession !== '' && specialty !== '' && username !== '') {
        setOption(3)
      }
      else if (profession === '' && specialty === '' && username !== '') {
        setOption(4)
      }
      else if (profession !== '' && specialty === '' && username !== '') {
        setOption(5)
      }
      else if (profession === '' && specialty === '' && username !== '') {
        setOption(6)
      }
    }
    //Si borra el nombre, evalua la opción.
    if (username === '') {
      evaluateOption();
    }
    evaluateOption();
  })

  //Crea un array de solo los eventos de los usuarios para enviarlo a Work.js
  const setEvents = () => {
    const arr = [];
    for (let i = 0; i < data.length; i++) {
      arr.push(data[i].events)
    }
    setAppointments(arr)
  }

  //   const EvaluateShow = () => {
  //     if (response.length >= 1 && response.message !== 'No se han encontrado datos' && response.message !== 'Ocurrió un error') {
  //       setData(response)
  //       setShow(true)
  //     }
  //     if (response.length < 1 || response.message === 'No se han encontrado datos' || response.message === 'Ocurrió un error') {
  //       setShow(false)
  //     }
  //   }

  //   //Filtra las especialidades según profesión seleccionada.
  //   const filterProfessions = (e) => {
  //     const option = e.id_profession;
  //     const filter = specialties.filter(e => e.id_profession === option);
  //     setFilter(filter);
  //   }

  return (
    <div className='flex flex-col'>
      <div className='flex'>
        <div className='flex flex-col h-screen w-[320px] bg-[#FAFAFA] border-r-[3px] border-solid border-[#D2D0D0]'>

          <div className='flex flex-col gap-3 font-bold text-[24px] leading-[32px] pt-[33px] text-center'>
            Resultados de búsqueda
            <div className='border-b-[3px] border-solid border-[#ADB2B6] mx-[19px]'></div>
          </div>

          <h2 className='mt-[70px] pb-[9px] pl-[8px] font-bold text-[24px] leading-[32px]'>Filtrar por</h2>

          <div className='flex flex-col pl-[8px]'>
            <label className='font-medium text-[22px] leading-[28px] pb-[5px]'>Profesión</label>
            <select
              className='selects'
            ></select>
          </div>

          <div className='flex flex-col pl-[8px] mt-[24px]'>
            <label className='font-medium text-[22px] leading-[28px] pb-[5px]'>Especialidad</label>
            <select
              className='selects'
            ></select>
          </div>

          <div className='flex gap-5 mt-[115px] w-full pl-[8px]'>
            <button className='btnVaciar' type='button' onClick={() => { setProfession(''); setSpecialty(''); setUsername('') }} >Vaciar</button>
            <button className='btnFiltrar' type='submit' onClick={() => { setEvents() }} >Filtrar</button>
          </div>
        </div>

        <div className='flex flex-col pl-[50px]'>
        <div className='w-full py-6 flex items-center justify-center'>
          <BarraBusqueda/>
        </div>
          <div className='flex my-3 rounded-[10px] p-[20px] gap-4 border-[3px] border-solid border-[#F27F0C]'>
            <div className='flex flex-col w-[318px] mt-3'>
              <div className='flex items-center gap-3'>
                <Avatar size="100" facebook-id="invalidfacebookusername" src='/inicio/perfil.jpg' round={true} />
                <div className='text-[#173A73]'>
                  <div className='font-bold text-[28px]'>Catalina Milla</div>
                  <div className='text-[16px] tracking-[0.15px] font-medium'>Fonoaudióloga</div>
                  <div className='font-black text-[14px] leading-[16px] mt-[20px]'>Valor consulta $30.000</div>
                </div>
              </div>

              <div className='flex mt-8 justify-center gap-4 h-[42px] bg-[#F27F0C] rounded-[10px]'>
                <div className='flex items-center justify-center gap-1'>
                  <PersonFill className='fill-white' size={15} />
                  <div className='text-[14px] text-white leading-[20px] tracking-[0.1px]'>45 Asesorías</div>
                </div>

                <div className='flex h-full items-center justify-center gap-1'>
                  <StarFill className='fill-white' size={15} />
                  <div className='text-white text-[16px] font-bold pr-1'>4.5</div>
                  <div className='text-[14px] text-white'>30 Opiniones</div>
                </div>
              </div>
              <div className='text-center mt-[20px] text-[12px] leading-[16px] tracking-[0.4px] w-[317px]'>
                Realizo evaluaciones, diagnósticos y tratamiento en trastorno específico del lenguaje, voz y habla en niños adolescentes y adultos. <br />
                Atención a niños con trastorno específico del lenguaje,trastorno del espectro autista, apoyo diferencial en lecto-escritura. <br />
                Atención adultos o jóvenes con: Disfonía, Nódulos vocálicos Pólipos.
              </div>
            </div>
            <div className='agendaSM flex flex-col items-center mb-[10px] w-full h-full'>
              <CustomAgenda appointments={appointments[0]} />
              <button className="btnAgendaSM mt-[10px]">Agendar</button>
            </div>
          </div>
        </div>
      </div>


      {/* {show ?
        <div className='flex flex-col'>
          <h1 className='text-xl font-bold my-2 w-100'>Muestra de mapeo de array</h1>

          {data.length >= 1 && data.map((e, index) =>
            <div className='flex my-3 bg-white rounded-md' key={e.id}>
              <Avatar className='mr-10 ml-2 mt-2' size="100" facebook-id="invalidfacebookusername" src={"data:image/*;base64," + e.img} round={true} />
              <div className='flex flex-col my-3 bg-white py-2 h-20' key={e.id}>
                <div className='font-bold bg-white text-2xl'>{e.Nombre}</div>
                <div className='w-18 mt-1 bg-white'>{e.Profesiones}</div>
                <div className='flex border mt-2 rounded-md bg-indigo-600 justify-center'>
                  <div className='flex-row mx-2 text-white text-lg'> Opiniones: {e.opiniones}</div>
                  <div className='flex-row mx-2 text-white text-lg'>Estrellas: {e.star}</div>
                </div>
                <div className='border rounded-md bg-slate-200 mt-4 pb-40 px-2 pt-2'>Descripción: {e.description} <div>indice {index}</div></div>

              </div>
              <div className='shadow-md rounded-md ml-4 my-4'>
                <Work appointments={appointments[index]}/>
              </div>
            </div>
          )}
        </div>

        :
        <div className='text-xl my-4'>imagen o texto que señale que no hay resultados para esta búsqueda.</div>
      } */}
    </div>
  )
}

export default SearchFilter