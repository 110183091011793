import React from 'react'
import { Parallax } from 'react-parallax'

const AboutSection3 = () => {
  return (
    <>
      <Parallax bgImage="Landing_page/bg_imagenes/bg_section3.jpg" bgImageAlt="section3" strength={200} className="bg-[#173A73]  hidden lg:block" >
        <div className='flex flex-col items-center justify-center mt-20 xl:h-[75vh] xl:justify-evenly xl:mt-10'>
          <div className='flex flex-col items-center'>
            <h1 className='AS3-H1 text-[39px] sm:text-[45px]'>Énfasis en la inclusión</h1>
            <h2 className='AS3-H2 sm:px-9 font-semibold text-center lg:w-3/4'>Creemos que todos contamos con las mismas capacidades y debemos tener las mismas
              oportunidades. Por esto en figaruz  nos comprometemos con la inclusión.
            </h2>
          </div>
          <div className='w-9/12 flex flex-col items-center lg:justify-center my-12 gap-5 md:w-full md:flex-row md:px-1 xl:px-20 xl:gap-20 xl:mb-4 xl:mt-28'>
            <div className='AS3-cards w-[70vw] flex flex-col items-center p-6 gap-4 sm:w-3/2 md:h-[330px] lg:h-[250px] md:w-[440px] lg:w-[350px]'>
              <img src='Landing_page/iconos/icono_oido.svg' alt='icono_oido' className='sm:w-16 h-auto' />
              <h3 className='AS3-H3 text-[20px] md:text-[18px] lg:text-[20px]'>Utilizaremos tecnología de detección de movimiento, traduciendo automáticamente el lenguaje de señas a voz.</h3>
            </div>
            <div className='AS3-cards w-[70vw] flex flex-col items-center p-6 gap-4 sm:w-3/2 md:h-[330px] lg:h-[250px] md:w-[440px] lg:w-[350px]'>
              <img src='Landing_page/iconos/icono_subtitulo.svg' alt='icono_subtitulo' className='sm:w-16 h-auto' />
              <h3 className='AS3-H3 text-[20px] md:text-[18px] lg:text-[20px]'>Todas tus conversaciones con subtítulos.</h3>
            </div>
            <div className='AS3-cards w-[70vw] flex flex-col items-center p-6 gap-4 sm:w-3/2 md:h-[330px] lg:h-[250px] md:w-[440px] lg:w-[350px]'>
              <img src='Landing_page/iconos/icono_ojo.svg' alt='icono_ojo' className='sm:w-16 h-auto' />
              <h3 className='AS3-H3 text-[20px] md:text-[18px] lg:text-[20px]'>Web optimizada para el uso de sintetizadores de voz que "lee y explica" lo que se visualiza en la pantalla del ordenador.</h3>
            </div>
          </div>
        </div>
      </Parallax>
      <Parallax bgImageAlt="section3" strength={200} className="bg-[#173A73] block sm:hidden" >
        <div className='flex flex-col items-center justify-center mt-20 xl:h-[75vh] xl:justify-evenly xl:mt-10'>
          <div className='flex flex-col items-center'>
            <h1 className='AS3-H1 text-[39px] sm:text-[45px]'>Énfasis en la inclusión</h1>
            <h2 className='AS3-H2 sm:px-9 font-semibold text-center lg:w-3/4'>Creemos que todos contamos con las mismas capacidades y debemos tener las mismas
              oportunidades. Por esto en figaruz  nos comprometemos con la inclusión.
            </h2>
          </div>
          <div className='w-9/12 flex flex-col items-center lg:justify-center my-12 gap-5 md:w-full md:flex-row md:px-1 xl:px-20 xl:gap-20 xl:mb-4 xl:mt-28'>
            <div className='AS3-cards w-[70vw] flex flex-col items-center p-6 gap-4 sm:w-3/2 md:h-[330px] lg:h-[250px] md:w-[440px] lg:w-[350px]'>
              <img src='Landing_page/iconos/icono_oido.svg' alt='icono_oido' className='sm:w-16 h-auto' />
              <h3 className='AS3-H3 text-[20px] md:text-[18px] lg:text-[20px]'>Utilizaremos tecnología de detección de movimiento, traduciendo automáticamente el lenguaje de señas a voz.</h3>
            </div>
            <div className='AS3-cards w-[70vw] flex flex-col items-center p-6 gap-4 sm:w-3/2 md:h-[330px] lg:h-[250px] md:w-[440px] lg:w-[350px]'>
              <img src='Landing_page/iconos/icono_subtitulo.svg' alt='icono_subtitulo' className='sm:w-16 h-auto' />
              <h3 className='AS3-H3 text-[20px] md:text-[18px] lg:text-[20px]'>Todas tus conversaciones con subtítulos.</h3>
            </div>
            <div className='AS3-cards w-[70vw] flex flex-col items-center p-6 gap-4 sm:w-3/2 md:h-[330px] lg:h-[250px] md:w-[440px] lg:w-[350px]'>
              <img src='Landing_page/iconos/icono_ojo.svg' alt='icono_ojo' className='sm:w-16 h-auto' />
              <h3 className='AS3-H3 text-[20px] md:text-[18px] lg:text-[20px]'>Web optimizada para el uso de sintetizadores de voz que "lee y explica" lo que se visualiza en la pantalla del ordenador.</h3>
            </div>
          </div>
        </div>
      </Parallax>
      <Parallax bgImage="Landing_page/bg_imagenes/bg_section3_lg.jpg" bgImageAlt="section3" strength={200} className="bg-[#173A73] hidden sm:block lg:hidden" >
        <div className='flex flex-col items-center justify-center mt-20 xl:h-[75vh] xl:justify-evenly xl:mt-10'>
          <div className='flex flex-col items-center'>
            <h1 className='AS3-H1 text-[39px] sm:text-[45px]'>Énfasis en la inclusión</h1>
            <h2 className='AS3-H2 sm:px-9 font-semibold text-center lg:w-3/4'>Creemos que todos contamos con las mismas capacidades y debemos tener las mismas
              oportunidades. Por esto en figaruz  nos comprometemos con la inclusión.
            </h2>
          </div>
          <div className='w-9/12 flex flex-col items-center lg:justify-center my-12 gap-5 md:w-full md:flex-row md:px-1 xl:px-20 xl:gap-20 xl:mb-4 xl:mt-28'>
            <div className='AS3-cards w-[70vw] flex flex-col items-center p-6 gap-4 sm:w-3/2 md:h-[330px] lg:h-[250px] md:w-[440px] lg:w-[350px]'>
              <img src='Landing_page/iconos/icono_oido.svg' alt='icono_oido' className='sm:w-16 h-auto' />
              <h3 className='AS3-H3 text-[20px] md:text-[18px] lg:text-[20px]'>Utilizaremos tecnología de detección de movimiento, traduciendo automáticamente el lenguaje de señas a voz.</h3>
            </div>
            <div className='AS3-cards w-[70vw] flex flex-col items-center p-6 gap-4 sm:w-3/2 md:h-[330px] lg:h-[250px] md:w-[440px] lg:w-[350px]'>
              <img src='Landing_page/iconos/icono_subtitulo.svg' alt='icono_subtitulo' className='sm:w-16 h-auto' />
              <h3 className='AS3-H3 text-[20px] md:text-[18px] lg:text-[20px]'>Todas tus conversaciones con subtítulos.</h3>
            </div>
            <div className='AS3-cards w-[70vw] flex flex-col items-center p-6 gap-4 sm:w-3/2 md:h-[330px] lg:h-[250px] md:w-[440px] lg:w-[350px]'>
              <img src='Landing_page/iconos/icono_ojo.svg' alt='icono_ojo' className='sm:w-16 h-auto' />
              <h3 className='AS3-H3 text-[20px] md:text-[18px] lg:text-[20px]'>Web optimizada para el uso de sintetizadores de voz que "lee y explica" lo que se visualiza en la pantalla del ordenador.</h3>
            </div>
          </div>
        </div>
      </Parallax>
    </>


  )
}

export default AboutSection3