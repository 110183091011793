import React from 'react'

const DataTable = () => {

  const servicesListTable = [
    { date: '09/11/2022', name: 'Psicología Infantil', totalCustomer: '36', newCustomers: '12', valoration: '4.0' },
    { date: '28/03/2022', name: 'Psicología Infantil', totalCustomer: '3', newCustomers: '13', valoration: '4.2' },
    { date: '28/11/2006', name: 'Psicología Infantil', totalCustomer: '19', newCustomers: '30', valoration: '2.0' },
    { date: '23/11/2022', name: 'Psicología Infantil', totalCustomer: '5', newCustomers: '7', valoration: '5.0' },
    { date: '01/10/2001', name: 'Psicología Infantil', totalCustomer: '7', newCustomers: '2', valoration: '4.0' }
  ]

  return (
    <div>
      <div className="m-4 bg-[#CAC4D0] pb-3 px-2 rounded-lg ">
        <table className="w-full ">
          <thead className=" ">
            <tr className="">
              <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Fecha</th>
              <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Nombre Servicio</th>
              <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Total Clientes</th>
              <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Nuevos Clientes</th>
              <th className="p-3 text-[16px] text-[#0b2152] font-semibold tracking-wide text-left">Valoración</th>
            </tr>
          </thead>

          <tbody className="">
            {servicesListTable.map((services, index) => (
              <tr className="border-y-8 border-[#CAC4D0] bg-white" key={index}>
                <td className="p-3 text-[16px] font-semibold text-[#0B2152] rounded-l-xl">{services.date}</td>
                <td className="p-3 text-[16px] font-semibold text-[#0B2152] ">{services.name}</td>
                <td className="p-3 text-[16px] font-semibold text-[#0B2152] ">{services.totalCustomer}</td>
                <td className="p-3 text-[16px] font-semibold text-[#0B2152] ">{services.newCustomers}</td>
                <td className="p-3 text-[16px] font-semibold text-[#0B2152] rounded-r-xl">{services.valoration}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DataTable