import { CalendarWeek, Coin, House, PersonVcard } from "react-bootstrap-icons"
import { Link } from "react-router-dom"

const ButtonSm = () => {
    return(
        <div>
            <div className='grid grid-cols-4 w-screen flex-row bg-[#173A73] lg:hidden pt-4 pb-2 px-10'>
                    <div className='flex flex-col items-center justify-center text-[#FFFFFF]'>
                        <Link to="/inicio">
                            <House size={32}/>
                        </Link>
                        <span className='items-center duration-100 px-2 text-[16px] font-medium'>
                            <Link to="/inicio">Home</Link>
                        </span>
                    </div>
                    <div className='flex flex-col items-center justify-center text-[#FFFFFF]'>
                        <Link to="/servicios">
                            <PersonVcard size={32}/>
                        </Link>
                        <span className='text-center duration-100 px-2 text-[16px] font-medium'>
                            <Link to="/servicios">Servicios</Link>
                        </span>
                    </div>
                    <div className='flex flex-col items-center justify-center text-[#FFFFFF]'>
                        <Link to="/saldos">
                            <Coin size={32}/>
                        </Link>
                        <span className='text-center duration-100 px-2 text-[16px] font-medium'>
                            <Link to="/saldos">Saldos</Link>
                        </span>
                    </div>
                    <div className='flex flex-col items-center justify-center text-[#FFFFFF]'>
                    <Link to="/agenda">
                        <CalendarWeek size={32}/>
                    </Link>

                        <span className='text-center duration-100 px-2 text-[16px] font-medium'>
                            <Link to="/agenda">Agenda</Link>
                        </span>
                    </div>
                </div> 
        </div>
    )
}
export default ButtonSm