import React from 'react'
import Customersummary from '../components/Clientes/CustomersSumary/CustomersSummary'
import Customers from '../components/Clientes/Customers/Customers'
import SearchBar from '../components/Busqueda/SearchBar'

const Clientes = () => {
    return (
        <div className="flex justify-center">
            <div className="w-[75vw]">
                <Customersummary />
                <div className="flex justify-end mt-12">
                    <SearchBar />
                </div>
                <Customers />
            </div>
        </div>
    )
}

export default Clientes