
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const Movimientos = () => {
    const products = [
        { "id": "1001", "Fecha": "06/12/2022", "Descripcion": "Cliente 1", "Cliente": "Cliente Nombre 1", "Abono": "$10.000", "Saldo": "$100.000" },
        { "id": "1002", "Fecha": "06/12/2022", "Descripcion": "Cliente 2", "Cliente": "Cliente Nombre 2", "Abono": "$10.000", "Saldo": "$100.000" },
        { "id": "1003", "Fecha": "06/12/2022", "Descripcion": "Cliente 3", "Cliente": "Cliente Nombre 3", "Abono": "$10.000", "Saldo": "$100.000" },
    ]

    return (
        <div>
            {/* <div className='border mt-4 py-4 pl-4 rounded-2xl bg-[#D0E5F8] text-[22px] font-semibold'>
                Ordenar por: Fecha
            </div> */}
            <div className="flex justify-center" >
                <div className="w-[75vw]">
                    <div className='mt-10 mx-5 mb-5'>
                        <DataTable value={products} style={{ radius: '40px' }} responsiveLayout="scroll" removableSort className='tabla'>
                            <Column field="Fecha" header="Fecha" sortable ></Column>
                            <Column field="Descripcion" header="Descripcion" sortable></Column>
                            <Column field="Cliente" header="Cliente" sortable></Column>
                            <Column field="Abono" header="Abono" sortable></Column>
                            <Column field="Saldo" header="Saldo" sortable></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Movimientos