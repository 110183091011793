import Swal from 'sweetalert2';

//Response register, iniciar sesión.
export const mostrarAlertaSuccess = (respuesta) => {
  Swal.fire({
    icon: 'success',
    title: 'Usuario registrado correctamente',
    //Texto cuando la app este abierta al público
    //text: respuesta,
    text: "Felicitaciones, te has registrado correctamente en nuestra Beta. Pronto te contactaremos.",
    confirmButtonText: 'Aceptar',
    confirmButtonColor: '#173A73'
  })
}
export const mostrarAlertaError = (respuesta) => {
  Swal.fire({
    icon: 'error',
    title: 'Ocurrió un problema',
    text: respuesta,
    confirmButtonText: 'Aceptar',
    confirmButtonColor: '#173A73'
  })
}
//Fin response register, iniciar sesión.

//Response resetPassword
export const mostrarAlertaRP = (respuesta) => {
  Swal.fire({
    icon: 'success',
    title: 'Operación exitosa',
    text: respuesta,
    confirmButtonText: 'Aceptar',
    confirmButtonColor: '#173A73'
  })

}

