import React, { useState } from 'react'
import CardServices from './CardServices';
import DataTable from './DataTable';
import Filter from '../../Utiles/Filter';
import Pagination from '../../Utiles/Pagination';

const TopServices = () => {
    const [Card, setCard] = useState(false);

    return (
        <div>
            <div className="flex justify-center" >
                <div className="w-[75vw]">
                    <Filter title="Tus Servicios" setView={(value) => setCard(value)} filterList={['Fecha','Cantidad de citas']} activeView={true}/>
                    {Card ? <DataTable /> : < CardServices />}
                    <Pagination />
                </div>
            </div>
        </div>
    )
}

export default TopServices