import React from 'react'
import TopServices from '../components/Servicios/TopServices/TopServices'
import SummaryServices from '../components/Servicios/SummaryServices/SummaryServices'

const Servicios = () => {
    return (
        <div>
            <SummaryServices />
            <TopServices />
        </div>
    )
}

export default Servicios