import React, { useState, useEffect } from 'react';
import { mostrarAlertaError, mostrarAlertaSuccess } from '../helpers/FetchResponse';
import { calcular_edad } from '../helpers/Helpers';
import UsePaises from '../hooks/UsePaises';
import UseProfesiones from '../hooks/UseProfesiones';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { useRut } from "react-rut-formatter";
import { format } from 'date-fns'
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
//import { Dialog } from 'primereact/dialog';
import { isValidPhoneNumber } from 'libphonenumber-js'

registerLocale('es', es)

const Register = () => {
    //URL base
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    //Hooks información desde API
    const { Paises } = UsePaises();
    const { profesiones } = UseProfesiones();

    const { rut, updateRut, isValid } = useRut();
    //const [pais, setPais] = useState('');
    const [profesion, setProfesion] = useState('');
    const [especialidad, setEspecialidad] = useState('');
    const [date_birth, setDate_birth] = useState(null);
    // eslint-disable-next-line
    const [codePhone, setCodePhone] = useState(56); //Se da código de país chileno predeterminado.
    const [idTraspaso, setIdTraspaso] = useState('');

    //const [arrPaises, setArrPaises] = useState([]);
    const [arrProfesiones, setArrProfesiones] = useState([]);

    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    //información filtrada
    const [profFiltered, setProfFiltered] = useState([]);
    const [speFiltered, setSpeFiltered] = useState([]);

    //Test expresiones regulares
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexOnlynum = /^[0-9\b]+$/;
    let regexTel = /^(0?9)(\s?)[98765432]\d{7}$/;
    let emailRegex = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    /*Obtiene los paises y los setea en un array local 
    para evitar caída de página.*/
    // useEffect(() => {
    //     paises.success ? setArrPaises(paises.data.paises) : setArrPaises([])
    // }, [paises])

    /*Obtiene las profesiones y las setea en un array local 
    para evitar caída de página.*/
    useEffect(() => {
        profesiones.success ? setArrProfesiones(profesiones.data.profesiones) : setArrProfesiones([])
    }, [profesiones])

    //Validaciones formulario
    const validate = (values) => {

        values.fecha_nacimiento = date_birth;
        values.profesion = profesion;
        values.especialidad = especialidad;
        values.rut = rut.raw;
        //values.pais = pais;

        let edad = calcular_edad(values.fecha_nacimiento);

        const errors = {}

        if (!values.nombre) {
            errors.nombre = 'El nombre es requerido'
        } else if (!regexName.test(values.nombre)) {
            errors.nombre = 'El campo nombre solo acepta letras y espacios en blanco'
        }
        if (!values.apellido) {
            errors.apellido = 'El apellido es requerido'
        } else if (!regexName.test(values.apellido)) {
            errors.apellido = 'El campo nombre solo acepta letras y espacios en blanco'
        }
        if (!values.email) {
            errors.email = 'El correo electrónico es requerido'
        } else if (!emailRegex.test(values.email)) {
            errors.email = 'El correo electrónico ingresado es inválido'
        }
        if (!values.contrasenia) {
            errors.contrasenia = 'La contraseña es requerida'
        } else if (values.contrasenia.length < 8) {
            errors.contrasenia = 'La contraseña debe contener a lo menos 8 caracteres'
        }
        if (!values.telefono) {
            errors.telefono = 'El télefono es requerido'
        } else if (!regexOnlynum.test(values.telefono)) {
            errors.telefono = 'Solo debe ingresar números'
        } else if (!regexTel.test(values.telefono)) {
            errors.telefono = 'número de teléfono no válido'
        } else if (!isValidPhoneNumber(values.telefono, 'CL')) {
            errors.telefono = 'número de teléfono no válido'
        } else if (values.telefono.length < 9) {
            errors.telefono = 'número de teléfono no válido'
        }
        // if (!values.pais) {
        //     errors.pais = 'El país es requerido'
        // }
        if (!values.profesion) {
            errors.profesion = 'La profesión es requerida'
        }
        if (!values.especialidad) {
            errors.especialidad = 'La especialidad es requerida'
        }
        if (values.terminos !== true) {
            errors.terminos = 'Debe aceptar los términos y condiciones de Figaruz'
        }
        if (values.consentimiento !== true) {
            errors.consentimiento = 'Debe dar su consentimiento y aceptar recibir información sobre los servicios y novedades de Figaruz'
        }

        if (!values.rut) {
            errors.rut = 'El RUT es requerido'
        } else if (values.rut.length < 9 || values.rut.length > 10) {
            errors.rut = 'El RUT no es válido'
        } else if (!isValid) {
            errors.rut = 'El RUT no es válido'
        }

        if (!values.fecha_nacimiento) {
            errors.fecha_nacimiento = 'La fecha de nacimientro es requerida'
        }
        if (edad < 18) {
            errors.fecha_nacimiento = 'Debe ser mayor de 18 años'
        }

        return errors
    }

    const formRegister = useFormik({

        initialValues: {
            //pais: '',
            nombre: '',
            apellido: '',
            rut: '',
            telefono: '',
            fecha_nacimiento: '',
            email: '',
            contrasenia: '',
            profesion: '',
            especialidad: '',
            terminos: false,
            consentimiento: false,
        },
        validate,
        onSubmit: values => {

            obtenerIdTraspaso(); // Obtengo id traspaso
            setLoading(true); // Inicio botón cargando

            //Obtengo los datos del país seleccionado.
            // values.pais = paises.filter(e => {
            //     return e.nombre_pais === pais;
            // })

            fetch(`${baseURL}/ms-loginusuario/registrar`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    id_pais: 4, //Por defecto se asigna ID país de Chile por el momento.
                    nombre_usuario: values.nombre,
                    apellido_usuario: values.apellido,
                    rut: values.rut,
                    telefono: values.telefono,
                    fecha_nacimiento: format(values.fecha_nacimiento, "yyyy-MM-dd"),
                    contrasenia: values.contrasenia,
                    correo_electronico: values.email,
                    id_traspaso: idTraspaso,
                }),
            }).then(x => {
                async function mensaje(x) {
                    try {
                        const result = await x.json();

                        if (result.success) {
                            mostrarAlertaSuccess(result.data.message);
                            formRegister.resetForm();
                            //setPais('');
                            setEspecialidad('');
                            setProfesion('');
                            setEspecialidad('')
                            setIdTraspaso('');
                            setDate_birth('');
                            //setCodePhone('');
                            setLoading(false);
                            updateRut('');
                            setSpeFiltered([]);
                            setLoading(false);
                        } else {
                            mostrarAlertaError(result.data.message)
                        };
                    } catch (error) {
                        console.log(error);
                        setLoading(false);
                    }
                }
                mensaje(x);
                setLoading(false);
            })
        }
    });

    //Captura el codigo de país para teléfono.
    // const evaluateCodePhone = (pais) => {
    //     let codePhone = arrPaises.filter(e => {
    //         return e.nombre_pais === pais;
    //     })
    //     setCodePhone(codePhone[0].codigo_telefono);
    // }

    //Filtra y crea el array de profesiones.
    useEffect(() => {
        const filtradoProfesiones = () => {
            const arrayProfesiones = [];

            for (let i = 0; i < arrProfesiones.length; i++) {
                arrayProfesiones.push(arrProfesiones[i].Profesiones.profesion)
            }

            const filtro = arrayProfesiones.filter((e, index) => {
                return arrayProfesiones.indexOf(e) === index;
            })
            setProfFiltered(filtro)
        }
        filtradoProfesiones()
    }, [arrProfesiones])

    //Filtra las especialidades según la profesión seleccionada.
    const filtradoEspecialidades = (e) => {
        const profesion = e;

        const filtroEspecialidades = arrProfesiones.filter(e => {
            return e.Profesiones.profesion === profesion
        })
        setProfesion(profesion)
        setSpeFiltered(filtroEspecialidades)
    }

    //Función obtengo idTraspaso según profesion y especialidad seleccionada.
    const obtenerIdTraspaso = () => {
        const filtro = arrProfesiones.filter(e => {
            return e.Profesiones.profesion === profesion &&
                e.Especialidades.especialidad === especialidad
        })
        setIdTraspaso(filtro[0].id_traspaso)
    }

    return (
        <div className='flex flex-col lg:flex-row lg:justify-center lg:mx-8 lg:my-10 xl:mx-20' id='registro'>
            <div className='R-card flex flex-col lg:flex-row'>
                <div className='flex flex-col p-5 lg:mt-10 lg:px-8 xl:w-[700px] xl:mt-8'>
                    <h1 className='R-H1 text-center lg:text-left'>Es momento de aumentar ingresos con tus conocimientos.</h1>

                    <h2 className='R-H2 mx-8 text-center lg:text-left lg:mx-0 xl:pr-12 xl:font-semibold'>Brinda asesorías, atiende a tus pacientes, enseña a tus alumnos,
                        agenda horas y chatea con tus clientes.
                    </h2>

                    <div className='flex gap-6 px-10 lg:gap-[48px] items-center lg:px-0 xl:mt-10'>
                        <img className='R-iconos w-16' src="Landing_page/iconos/icono_registro.svg" alt='icono_registro' />
                        <h3 className='R-H3 xl:font-semibold xl:text-[20px] xl:w-9/12'>
                            Regístrate en nuestra beta que dará inicio en enero 2023 y forma
                            parte de nuestro selecto grupo de profesionales.
                        </h3>
                    </div>

                    <div className='flex gap-6 px-10 lg:gap-[48px] items-center lg:px-0 mt-[44px]'>
                        <img className='R-iconos w-16' src='Landing_page/iconos/icono_acceso.svg' alt="icono_acceso" />
                        <h3 className='R-H3 xl:font-semibold xl:pr-20 xl:text-[20px]'>
                            Obtén un acceso exclusivo a nuestras herramientas y brinda una atención
                            profesional a tus clientes con tan solo unos clicks.
                        </h3>
                    </div>
                </div>

                <div className='bg-[#FAFAFA] self-center w-fit flex flex-col items-center justify-center rounded-[30px] px-3 py-8 mb-12 lg:mb-0 lg:rounded-l-none lg:rounded-br-[20px] lg:rounded-tr-[118px] sm:p-10'>
                    <form className='flex flex-col items-center justify-center' onSubmit={formRegister.handleSubmit}>
                        <div className='flex flex-col items-center gap-[10px] px-3 sm:px-0'>
                            <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                <input maxLength={70} className='R-inputs h-[40px] w-full' placeholder='Nombre' name='nombre' value={formRegister.values.nombre} onChange={formRegister.handleChange} />
                                {formRegister.touched.nombre && formRegister.errors.nombre ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.nombre}</div> : null}
                            </div>

                            <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                <input maxLength={70} className='R-inputs h-[40px] w-full' placeholder='Apellido' name='apellido' value={formRegister.values.apellido} onChange={formRegister.handleChange} />
                                {formRegister.touched.apellido && formRegister.errors.apellido ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.apellido}</div> : null}
                            </div>

                            <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                <input maxLength={70} className='R-inputs h-[40px] w-full' placeholder='Correo electrónico' name='email' value={formRegister.values.email} onChange={formRegister.handleChange} keyfilter='email' />
                                {formRegister.touched.email && formRegister.errors.email ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.email}</div> : null}
                            </div>

                            <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                <DatePicker
                                    className='w-[300px] lg:w-[320px] xl:w-[360px] inputDP placeholder-[#173A73] placeholder-opacity-80'
                                    locale="es"
                                    placeholderText='Fecha de nacimiento'
                                    selected={date_birth}
                                    onChange={(date) => setDate_birth(date)}
                                    showYearDropdown
                                    dateFormatCalendar="MMMM"
                                    yearDropdownItemNumber={90}
                                    scrollableYearDropdown
                                    name='fecha_nacimiento'
                                />
                                {formRegister.touched.fecha_nacimiento && formRegister.errors.fecha_nacimiento ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.fecha_nacimiento}</div> : null}
                            </div>

                            <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                <input maxLength={12} minLength={11} className='R-inputs h-[40px] w-full' placeholder='RUT' aria-describedby="rut-help" name='rut' value={rut.formatted} onChange={e => updateRut(e.target.value)} keyfilter='alphanum' />
                                {formRegister.touched.rut && formRegister.errors.rut ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.rut}</div> : null}
                            </div>

                            {/* <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                    <select
                                        className='R-countrySelect w-[300px] lg:w-[320px] xl:w-full bg-gray-50 ring-1 ring-gray-300'
                                        onChange={e => { setPais(e.target.value); evaluateCodePhone(e.target.value) }}
                                        value={pais}
                                        name='pais'>
                                        <option value="" className='text-[#173A73] opacity-80' disabled selected hidden>País</option>
                                        {arrPaises.length >= 1 && arrPaises.map(e => {
                                            return <option value={e.nombre_pais} clasName='R-option' key={e.id_pais}>{e.nombre_pais}</option>
                                        })}
                                    </select>
                                    {formRegister.touched.pais && formRegister.errors.pais ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.pais}</div> : null}
                                </div> */}

                            <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                <div className='flex justify-start gap-1 w-full'>
                                    <div>
                                        <input className="w-[50px] h-[40px] border border-[#173A73] rounded-[6px] text-center text-[#173A73] opacity-70" value={codePhone.length < 1 ? '' : '+' + codePhone} disabled />
                                    </div>
                                    <input type="tel" className='R-input-tel w-[250px] sm:w-[450px] h-[40px] placeholder-[#173A73] placeholder-opacity-80' maxLength={9} minLength={9} name='telefono' value={formRegister.values.telefono} onChange={formRegister.handleChange} placeholder='Teléfono' />
                                </div>
                                {formRegister.touched.telefono && formRegister.errors.telefono ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.telefono}</div> : null}
                            </div>

                            <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                <select
                                    className='R-countrySelect w-[300px] sm:w-full bg-gray-50 ring-1 ring-gray-300'
                                    onChange={e => filtradoEspecialidades(e.target.value)}
                                    value={profesion}
                                    name='profesion'
                                >
                                    <option hidden selected className='text-[#173A73] opacity-80'>Profesión</option>
                                    {arrProfesiones.length >= 1 && profFiltered.map((e, index) => {
                                        return <option value={e} key={index}>{e}</option>
                                    })}
                                </select>
                                {formRegister.touched.profesion && formRegister.errors.profesion ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.profesion}</div> : null}
                            </div>

                            <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                <select
                                    className='R-countrySelect w-[300px] sm:w-full bg-gray-50 ring-1 ring-gray-300'
                                    onChange={e => setEspecialidad(e.target.value)}
                                    disabled={speFiltered.length > 0 ? false : true}
                                    name='especialidad'
                                    value={especialidad}
                                >
                                    <option selected hidden className='text-[#173A73] opacity-80'>Especialidad</option>
                                    {speFiltered.map(e => {
                                        return <option value={e.Especialidades.especialidad} key={e.Especialidades.id_especialidad}>{e.Especialidades.especialidad}</option>
                                    })}

                                </select>
                                {formRegister.touched.especialidad && formRegister.errors.especialidad ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.especialidad}</div> : null}
                            </div>

                            <div className='w-[300px] lg:w-[360px] xl:w-[400px] lg:px-5'>
                                <input
                                    value={formRegister.values.contrasenia}
                                    onChange={formRegister.handleChange}
                                    name='contrasenia'
                                    className='R-inputs h-[40px] w-full'
                                    type='password'
                                    placeholder='Contraseña'
                                    maxLength={16}
                                    minLength={8}
                                />
                                {formRegister.touched.contrasenia && formRegister.errors.contrasenia ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.contrasenia}</div> : null}
                            </div>

                            <div className="flex w-[300px] xl:w-[400px] sm:w-full flex-col gap-2 my-3 self-start lg:px-5">

                                <div className="flex gap-3">
                                    <Checkbox name='terminos' inputId="terminos" checked={formRegister.values.terminos} onChange={formRegister.handleChange} />
                                    <label htmlFor="terminos" className='text-[12px] text-[#173A73] font-light leading-[14px]'>
                                        Acepto los términos y condiciones {/*<Link className=' hover:underline underline-offset-1' onClick={() => setShow(true)}>términos y condiciones</Link>*/} del
                                        servicio de Figaruz. <br /> Declaro haber leído la política de privacidad.*
                                    </label>
                                </div>
                                {formRegister.touched.terminos && formRegister.errors.terminos ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.terminos}</div> : null}

                                <div className="flex gap-3">
                                    <Checkbox name='consentimiento' inputId="consentimiento" checked={formRegister.values.consentimiento} onChange={formRegister.handleChange} />
                                    <label htmlFor="consentimiento" className='text-[12px] text-[#173A73] font-light leading-[14px]'>
                                        Doy mi consentimiento y acepto recibir información sobre los <br /> servicios y novedades de Figaruz.*
                                    </label>
                                </div>
                                {formRegister.touched.consentimiento && formRegister.errors.consentimiento ? <div className=" text-red-600 text-sm font-semibold">{formRegister.errors.consentimiento}</div> : null}

                            </div>
                        </div >

                        <div className='w-[300px] xl:w-[400px] sm:w-full'>
                            <Button onClick={() => obtenerIdTraspaso()} type='submit' loading={loading} label='Registrarme' className='R-BRegister w-full transition duration-300 ease-out hover:ease-in' />

                            {/*Componentes de mantenimiento*/}
                            {/* <Button type="button" onClick={() => setShow(true)} label='Registrarme' className='R-BRegister w-full transition duration-300 ease-out hover:ease-in' />

                                <Dialog header='Mantenimiento' visible={show} className='w-10/12 md:w-7/12 lg:w-5/12 xl:w-4/12' onHide={() => setShow(false)}>
                                    <p className='text-xl'>
                                        Estimado usuario, por motivos de mantenimiento el registro estará habilitado dentro de las próximas horas. Por su comprensión,
                                        muchas gracias.
                                    </p>
                                </Dialog> */}
                        </div>
                    </form >
                </div >
            </div >
        </div >
    )
}

export default Register