import React from 'react';
import SearchFilter from './SearchFilter';

// import { useParams } from 'react-router-dom';

const ResultSearch = () => {

  // const {option} = useParams();

  return (
    <>
    <SearchFilter/>
    </>
  )
}

export default ResultSearch