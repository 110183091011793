import React from 'react'
import AcordeonAyuda from '../components/Ayuda/AcordeonAyuda'
import FormAyuda from '../components/Ayuda/FormAyuda'

const Ayuda = () => {
  return (
    <div className='flex flex-col'>
      <h1 className='font-bold text-[36px] leading-[44px] mt-[100px] mb-[73px] text-center'>Preguntas frecuentes</h1>
      <div className='flex'>
        <div className='w-2/3'>
          <AcordeonAyuda />
        </div>
        <div className='w-1/3'>
          <FormAyuda />
        </div>
      </div>
    </div>
  )
}

export default Ayuda