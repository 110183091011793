//import React, { useRef, useEffect } from "react";
import '../styles/index.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import "../styles/landingPage.css"
import Navbar from "./Navbar"
import About from "./About/About";
import Home from "./Home";
import Register from "./Register"
import Footer from "./Footer";
import ScrollButton from "./ButtonScroll";

//import { Messages } from 'primereact/messages';

const Main = () => {

  // const mensajeMantenimiento = useRef(null);

  // useEffect(() => {
  //   mensajeMantenimiento.current.show( { severity: 'info', detail: 'Estimado usuario, por motivos de mantenimiento el registro estará habilitado dentro de las próximas horas. Por su comprensión, muchas gracias.', sticky: true });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="w-full">
      {/* <Messages ref={mensajeMantenimiento} className="z-50 fixed w-full"/> */}
      <Navbar className="mt-8"/>
      <Home />
      <About />
      <ScrollButton />
      <Register />
      <Footer /> 
    </div>
  );
};
export default Main;