import React from 'react'

import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'
import { format, addDays } from 'date-fns'
import { es } from 'date-fns/locale';

const CustomAgenda = () => {

    //TODO : Realizar pruebas para ver si este componente podrá utilizarse como agenda en búsqueda
    //Formatea la fecha a español
    let esp = { locale: es };
    const actualDate = new Date();

    return (
        <div className='customAgenda flex justify-between w-[406px] h-[337px] bg-white'>

            <ChevronLeft
                className=' stroke-[#F27F0C] rounded-[20px] fill-[#F27F0C] bg-[#CAC4D0] p-1'
                type="button"
                size={22}
            />

            <div className='flex flex-col w-full px-3 text-center'>
                <div className='flex items-center justify-between'>
                    <div className='flex flex-col w-[58px]'>
                        <div className='diasHeader'>Hoy</div>
                        <div className='text-[12px] text-[#0B2152] capitalize'>{format(actualDate, "dd ' ' MMM", esp)}</div>
                    </div>

                    <div className='flex flex-col w-[58px]'>
                        <div className='diasHeader'>Mañana</div>
                        <div className='text-[12px] text-[#0B2152] capitalize'>{format(addDays(actualDate, 1), "dd ' ' MMM", esp)}</div>
                    </div>

                    <div className='flex flex-col w-[58px]'>
                        <div className='diasHeader'>{format(addDays(actualDate, 2), "EEE", esp)}</div>
                        <div className='text-[12px] text-[#0B2152] capitalize'>{format(addDays(actualDate, 2), "dd ' ' MMM", esp)}</div>
                    </div>

                    <div className='flex flex-col w-[58px]'>
                        <div className='diasHeader'>{format(addDays(actualDate, 3), "EEE", esp)}</div>
                        <div className='text-[12px] text-[#0B2152] capitalize'>{format(addDays(actualDate, 3), "dd ' ' MMM", esp)}</div>
                    </div>
                </div>

                <div className='flex items-center justify-between overflow-scroll scrollbarAgenda pt-48'>
                    <div className='flex flex-col gap-3 mt-[300px]'>
                        <div className='horaNoDisponible'>07:00</div>
                        <div className='horaNoDisponible'>08:00</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaDisponible'>10:00</div>
                        <div className='horaNoDisponible'>11:00</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                    </div>

                    <div className='flex flex-col gap-3 mt-[300px]'>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                    </div>

                    <div className='flex flex-col gap-3 mt-[300px]'>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                    </div>

                    <div className='flex flex-col gap-3 mt-[300px]'>
                        <div className='horaNoDisponible'>07:00</div>
                        <div className='horaNoDisponible'>08:00</div>
                        <div className='horaNoDisponible'>09:00</div>
                        <div className='horaNoDisponible'>10:00</div>
                        <div className='horaNoDisponible'>11:00</div>
                        <div className='horaNoDisponible'>12:00</div>
                        <div className='horaNoDisponible'>13:00</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                        <div className='horaVacia'>-</div>
                    </div>
                </div>

            </div>

            <ChevronRight
                className='stroke-[#F27F0C] rounded-[20px] fill-[#F27F0C] bg-[#CAC4D0] p-1'
                type="button"
                size={22}
            />
        </div>
    )
}

export default CustomAgenda