import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useState } from 'react';
import CardBank from './CardBank';
 
const CardSaldos = () => {
    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [position, setPosition] = useState('center');

    const dialogFuncMap = {
        'displayResponsive': setDisplayResponsive
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }
    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Transferir" icon="pi pi-check" onClick={() => onHide(name)} autoFocus className="p-button-text-save" />
            </div>
        );
    }
    return(
        <div className='flex flex-col'> 
            <div className='flex flex-row ml-10'>
                <div>
                    <div className='flex flex-col border w-[500px] h-52 rounded-lg mt-10 mr-12 text-white bg-[#173A73]'>
                        <h1 className='text-[30px] font-sans Roboto ml-4 mt-4 font-bold'>
                            Saldo Mensual
                        </h1>
                        <h2 className='ml-4 mt-4'>
                            $000.000.000
                        </h2>
                        <div className='flex justify-end mr-4 mt-14'>
                            <img src="/logos_figaruz/logo-header.png" alt="logo_figaruz_header"
                            className='w-20'/>
                        </div>
                    </div>
                    <Button label="Transferir Saldo" icon="pi pi-upload" onClick={() => onClick('displayResponsive')}  style={{background: "#F27F0C", marginTop: "16px", textColor: "#FFFFFF", width: "500px", borderColor: '#F27F0C'}} className='buttonsaldos1 justify-center' />
                    <Dialog header="Transferir" visible={displayResponsive} onHide={() => onHide('displayResponsive')} breakpoints={{'960px': '75vw'}} style={{width: '50vh'}} footer={renderFooter('displayResponsive')}>
                        <CardBank/>
                    </Dialog>
                </div>
                
                <div className='ml-4'>
                    <div className='flex flex-col border w-[500px] h-24 rounded-lg mt-10 text-black bg-[#6E9DD5]'>
                        <h1 className='text-[20px] font-sans Roboto ml-4 mt-4 font-bold'>
                            Saldo a liberar
                        </h1>
                        <h2 className='ml-4 mt-2'>
                            $000.000
                        </h2>
                    </div>
                    <div className='flex flex-col border w-[500px] h-24 rounded-lg mt-4 text-black bg-[#FFFFFF]'>
                        <h1 className='text-[20px] font-sans Roboto ml-4 mt-4 font-bold'>
                            Saldo Historico
                        </h1>
                        <h2 className='ml-4 mt-2'>
                            $000.000
                        </h2>
                    </div>
                    <Button label="Descargar Planilla" icon="pi pi-download" style={{background: "#FDDB9D", marginTop: "16px", color:'#AE4906' , width: "500px", borderColor: '#FDDB9D'}} className='buttonsaldos justify-center'/>
                </div>
            </div>
            
        </div>
    )
}
export default CardSaldos